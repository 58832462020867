﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { queryKeys } from "../queryKeys";
import { AxiosError } from "axios";
import { axiosInstance } from "../../utils";

export function useQuestionnaireReset() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (args: { candidateId: string; cId: string | undefined; eId: string | undefined }) =>
            axios.post(`api/clients/${args.cId}/entities/${args.eId}/candidates/${args.candidateId}/questionnaire/reset`, {}).then(response => response.data),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.candidates.byCandidateId(variables.candidateId).queryKey);
            await queryClient.invalidateQueries(queryKeys.candidates.timelineByCandidateId(variables.candidateId).queryKey);
            // this could invalidate more precisely, but not worth the effort right now
            await queryClient.invalidateQueries(queryKeys.candidates._def);

            toast({
                title: "Questionnaire Reset",
                description: "The questionnaire has been successfully reset.",
                status: "success",
                isClosable: true,
                duration: 7000,
            });
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Questionnaire Not Reset",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
