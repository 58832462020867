﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { queryKeys } from "../queryKeys";
import { AxiosError } from "axios";
import { EntityCandidate, EntityCandidateArgs } from "../models/EntityCandidate";
import { axiosInstance } from "../../utils";

export function useCandidateSave() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (candidateArgs: EntityCandidateArgs) => {
            if (!candidateArgs.candidate.id) {
                return axios
                    .post<EntityCandidate>(`api/clients/${candidateArgs.clientId}/entities/${candidateArgs.entityId}/candidates`, candidateArgs.candidate)
                    .then(response => response.data);
            } else {
                return axios
                    .put<EntityCandidate>(
                        `api/clients/${candidateArgs.clientId}/entities/${candidateArgs.entityId}/candidates/${candidateArgs.candidate.id}`,
                        candidateArgs.candidate
                    )
                    .then(response => response.data);
            }
        },
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.candidates.byEntity._def);
            await queryClient.invalidateQueries(queryKeys.candidates.timelineByCandidateId(data.id).queryKey);
            queryClient.setQueryData(queryKeys.candidates.byCandidateId(data.id).queryKey, data);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Unable to save candidate details",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
