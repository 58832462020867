import { Icon } from "@chakra-ui/react";
import { IconCheck, IconFile, IconChecklist, IconX } from "@tabler/icons-react";


export type RequestArgs = {
    clientId: string | undefined;
    entityId: string | undefined;
    candidateId: string;
    old?: RequestArgsBody;
    new: RequestArgsBody;
};

export type RequestArgsBody = {
    id?: string;
    name: string;
    description?: string;
    status: RequestStatus;
};

export type Request = {
    id: string;
    name: string;
    description: string;
    preferred: string;
    others?: string;
    status: RequestStatus;
    createdOn : string;
    createdBy : UserProfile;
    documents? : Document[];
    comments? : Comment[];
};

export type Document = {
    id: string;
    name : string;
    extension : string;
    length : number;
    createdOn : string;
    createdBy : UserProfile;
    comments : Comment[];
};

export type Comment = {
    body: string;
    createdOn: string;
    createdBy: UserProfile;
};

export type UserProfile = {
    id : string;
    name : string;
    avatar : string;
    userType: UserType;
    presence: UserPresence;
};

export enum UserType {
    internal = "internal",
    external = "external",
    candidate = "candidate"
}

export enum UserPresence {
    unknown = "unknown",
    offline = "offline",
    available = "available"
}

export enum RequestStatus { 
    Open = "Open",
    UnderReview = "UnderReview",
    Rejected = "Rejected",
    Accepted = "Accepted"
}

export function displayRequestStatus(status: RequestStatus): string {
    switch (status) {
        case RequestStatus.Open:
            return "Open";
        case RequestStatus.UnderReview:
            return "Under Review";
        case RequestStatus.Rejected:
            return "Rejected";
        case RequestStatus.Accepted:
            return "Accepted";
    }
}

export const statuses: RequestStatus[] = [
    RequestStatus.Open,
    RequestStatus.UnderReview,
    RequestStatus.Rejected,
    RequestStatus.Accepted
]


const mockUser: UserProfile = {
    id: "1",
    name: "John Doe",
    avatar: "who knows",
    userType: UserType.internal,
    presence: UserPresence.available
}

const mockComment: Comment = {
    body: "some comment",
    createdOn: "2023-08-29T03:29:10.778Z",
    createdBy: mockUser,
}

 const mockDocument: Document = {
     id: "1",
     name: "some-file",
     extension: "pdf",
     length: 1234,
     createdOn: "2023-08-29T03:29:10.778Z",
     createdBy: mockUser,
     comments: [
         mockComment
     ]
 }
 const mockDocument2: Document = {
     id: "2",
     name: "some-other-file",
     extension: "pdf",
     length: 1234,
     createdOn: "2023-08-29T03:29:10.778Z",
     createdBy: mockUser,
     comments: [
         mockComment
     ]
 }

export const mockRequest: Request = {
    id: "1",
    name: "TANF Certificate",
    description: "",
    preferred: "",
    others: "",
    status: RequestStatus.Open,
    documents: [
        mockDocument, mockDocument2
    ],
    comments: [mockComment],
    createdBy: mockUser,
    createdOn: "2023-08-29T03:29:10.778Z",
}

export const mockRequests: Request[] = [
    {
        ...mockRequest,
        id: "1",
        name: "Summer Youth",
        preferred: "Birth certificate or drivers license",
        others: "- School I.D. Card1\n- Work Permit1\n- Federal/State/Local Gov’t I.D.",
        status: RequestStatus.Open,
        documents: []
    },
    {
        ...mockRequest,
        id: "2",
        name: "Veteran",
        preferred: "DD-214 or discharge papers",
        others: "- Reserve Unit Contacts\n" +
            "- Letter of Separation or other agency documents issued only by the Department of Veterans Affairs (DVA) on DVA Letterhead certifying the Veteran has a service-connected disability and signed by the individual who verified this information. ",
        status: RequestStatus.Open,
        documents: []
    },
    {
        ...mockRequest,
        id: "3",
        name: "TANF/SNAP",
        preferred: "TANF/SNAP (Food Stamp) Benefit History or Case Number Identifier",
        others: "- Signed statement from Authorized Individual with a specific description of the months benefits that were received",
        status: RequestStatus.UnderReview,
    },
    {
        ...mockRequest,
        id: "4",
        name: "Referred to an employer by a Vocational Rehabilitation Agency ",
        preferred: "Vocational Rehabilitation Agency Contact",
        others: "- Signed letter of separation or related document from authorized Individual on DVA letter head or agency stamp with specific description of months benefits were received.\n" +
            "- For SWAs: To determine Ticket Holder (TH) eligibility, Fax page 1 of Form 8850 to MAXIMUS at: 703-683-1051 to verify if applicant: 1) is a TH, and 2) has an Individual Work Plan from an Employment Network. ",
        status: RequestStatus.UnderReview,
    },
    {
        ...mockRequest,
        id: "5",
        name: "Felon",
        preferred: "Parole Officer’s Name or Statement",
        others: "- Correction Institution Records \n" +
            "- Court Records Extracts ",
        status: RequestStatus.Rejected,
    },
    {
        ...mockRequest,
        id: "6",
        name: "Empowerment Zone",
        preferred: "Driver license",
        status: RequestStatus.Accepted,
    },
    {
        ...mockRequest,
        id: "7",
        name: "SSI (Supplemental Security Income)",
        preferred: "SSI Record or Authorization",
        others: "- SSI Contact\n" +
            "- Evidence of SSI Benefits ",
        status: RequestStatus.Accepted,
    },
    {
        ...mockRequest,
        id: "8",
        name: "Unemployed",
        preferred: "Unemployment Insurance (UI) Claims Records",
        others: "- UI Claims Records",
        status: RequestStatus.Accepted,
    },
];


export function getStatusIcon(status: RequestStatus) {
    switch (status) {
        case RequestStatus.Open:
            return <Icon as={IconFile} color={"orange.500"} />;
        case RequestStatus.UnderReview:
            return <Icon as={IconChecklist} color={"orange.500"} />;
        case RequestStatus.Rejected:
            return <Icon as={IconX} color={"red.500"} />;
        case RequestStatus.Accepted:
            return <Icon as={IconCheck} color={"green.500"} />;
    }
}

export function uploaded(status: RequestStatus) {
    return status !== RequestStatus.Open;
}

