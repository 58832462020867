﻿import { queryKeys } from "../queryKeys";
import { useQuery } from "@tanstack/react-query";
import { EntityCandidate } from "../models/EntityCandidate";
import { axiosInstance } from "../../utils";

export function useClientEntityCandidates(clientId: string, entityId: string | undefined, recurse: boolean, activeOnly: boolean) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.candidates.byEntity(clientId, entityId, recurse, activeOnly),
        queryFn: () =>
            axios
                .get<EntityCandidate[]>(`api/Clients/${clientId}/Entities/${entityId ?? "all"}/candidates?recurse=${recurse}&activeOnly=${activeOnly}`)
                .then(response => response.data),
    });
}
