﻿import {
    AbsoluteCenter,
    Box,
    Button,
    ButtonGroup,
    ButtonProps,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Select,
    Spacer,
    Spinner,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { EntityResponse, useClientEntity, useClientEntityDelete, useClientEntityUpdate, useStates } from "../../../../api";
import { DeleteConfirmationDialog, DeleteConfirmationDialogProps } from "../../../../components/DeleteConfirmationDialog";
import { useForm } from "react-hook-form";
import { createPatch } from "rfc6902";
import { useEntityTreeContext } from "../EntityTreeContext";
import { AxiosError } from "axios";
import { Address, AddressVerificationDialog } from "../../../../common";
import { useAreaPermissions } from "../../../../hooks";
import { EntitiesArea } from "../../../../auth";

export type EntityEditorDrawerProps = {
    clientId: string;
    entityId: string;
} & Pick<DrawerProps, "isOpen" | "onClose">;
export const EditEntityDrawer: FunctionComponent<EntityEditorDrawerProps> = props => {
    const { clientId, entityId, ...drawerProps } = props;
    const entity = useClientEntity(clientId, entityId, { enabled: drawerProps.isOpen });

    return (
        <Drawer {...drawerProps} placement="right" size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <Text>Edit Entity - {entity.data?.name}</Text>
                </DrawerHeader>
                <DrawerBody>
                    {entity.data ? <EntityEditorForm clientId={clientId} entity={entity.data} onClose={drawerProps.onClose} /> : <Spinner />}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

const EntityEditorForm: FunctionComponent<{
    clientId: string;
    entity: EntityResponse;
    onClose: () => void;
}> = props => {
    const { clientId, entity, onClose } = props,
        formValues = useMemo(
            () => ({
                name: entity.name,
                externalId: entity.externalId,
                ein: entity.ein,
                phone: entity.phone,
                customField01: entity.customField01,
                customField02: entity.customField02,
                customField03: entity.customField03,
                customField04: entity.customField04,
                customField05: entity.customField05,
                note: entity.note,
                street1: entity.street1,
                street2: entity.street2,
                city: entity.city,
                state: entity.state,
                zipCode: entity.zipCode,
                county: entity.county,
            }),
            [entity]
        );

    const areaPermissions = useAreaPermissions(EntitiesArea, clientId);

    const {
        // control,
        setValue,
        reset,
        formState: { isDirty },
        register,
        handleSubmit,
        getValues,
    } = useForm();

    useEffect(() => {
        Object.entries(formValues).forEach(([key, value]) => {
            setValue(key, value);
        });
    }, [formValues, setValue]);

    const states = useStates(),
        stateOptions = (states.data ?? []).map(state => (
            <option key={state.code} value={state.code}>
                {state.name}
            </option>
        ));

    const [verifiedAddressOptions, setVerifiedAddressOptions] = useState<Address[]>([]);
    const [forceAddress, setForceAddress] = useState(false);
    const clientEntityUpdate = useClientEntityUpdate();
    const { isOpen: isAddressVerificationDialogOpen, onOpen: openAddressVerificationDialog, onClose: closeAddressVerificationDialog } = useDisclosure();

    const { onRefetch } = useEntityTreeContext();
    const entityDelete = useClientEntityDelete();
    const hasNoDescendants = entity ? !hasAnyDescendants(entity) : false;

    const deleteConfirmation = useDisclosure();

    const deleteConfirmationDialog: DeleteConfirmationDialogProps = {
        ...deleteConfirmation,
        title: `Delete ${entity.name}`,
        onDelete: () => {
            entityDelete
                .mutateAsync({ clientId, entityId: entity.id })
                .then(() => {
                    onRefetch();
                    deleteConfirmation.onClose();
                    onClose();
                })
                .catch(deleteConfirmation.onClose);
        },
        isDisabled: false,
    };

    // @ts-ignore
    const save = async data => {
        const patch = createPatch(formValues, data);
        const request = {
            clientId: props.clientId,
            entityId: entity.id,
            patch: patch,
            forceAddress: forceAddress,
        };
        try {
            const response = await clientEntityUpdate.mutateAsync(request);
            reset({
                name: response.name,
                externalId: response.externalId,
            });
        } catch (e) {
            const axiosError = e as AxiosError;
            if (axiosError?.response?.status === 422) {
                setVerifiedAddressOptions(axiosError.response?.data as Address[]);
                openAddressVerificationDialog();
            }
        }
    };

    const cancelButton: ButtonProps = {
            onClick: () => {
                reset();
                onClose();
            },
            children: "Cancel",
        },
        saveButton: ButtonProps = {
            type: "submit",
            colorScheme: "blue",
            isDisabled: !isDirty,
            isLoading: clientEntityUpdate.isLoading,
            children: "Save",
        };

    return (
        <>
            <form onSubmit={handleSubmit(save)}>
                <Flex gap="4" direction="column">
                    <Flex gap="2" direction="column">
                        <FormControl isRequired>
                            <FormLabel>Name</FormLabel>
                            <Input placeholder="Entity name" {...register("name")} />
                        </FormControl>

                        <FormControl>
                            <FormLabel>External ID</FormLabel>
                            <Input placeholder="Optional external id" {...register("externalId")} />
                        </FormControl>
                    </Flex>
                    <Divider />
                    <Flex gap="2" direction="column">
                        <FormControl>
                            <FormLabel>EIN</FormLabel>
                            <Input placeholder="" {...register("ein")} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone</FormLabel>
                            <Input placeholder="" {...register("phone")} />
                        </FormControl>
                    </Flex>
                    <Flex gap="2" direction="column">
                        <Box position="relative" padding="4">
                            <Divider />
                            <AbsoluteCenter bg="white" px="4">
                                Address
                            </AbsoluteCenter>
                        </Box>
                        <HStack align="end">
                            <FormControl>
                                <FormLabel>Street 1</FormLabel>
                                <Input placeholder="" {...register("street1")} />
                            </FormControl>
                        </HStack>
                        <FormControl>
                            <FormLabel>Street 2</FormLabel>
                            <Input placeholder="" {...register("street2")} />
                        </FormControl>
                        <HStack>
                            <FormControl>
                                <FormLabel>City</FormLabel>
                                <Input placeholder="" {...register("city")} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>State</FormLabel>
                                <Select placeholder="Select state" {...register("state")}>
                                    {stateOptions}
                                </Select>
                            </FormControl>
                        </HStack>
                        <HStack>
                            <FormControl>
                                <FormLabel>ZIP</FormLabel>
                                <Input placeholder="" {...register("zipCode")} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>County</FormLabel>
                                <Input placeholder="" {...register("county")} />
                            </FormControl>
                        </HStack>
                    </Flex>
                    <Flex gap="2" direction="column">
                        <Box position="relative" padding="4">
                            <Divider />
                            <AbsoluteCenter bg="white" px="4">
                                Custom Fields
                            </AbsoluteCenter>
                        </Box>
                        <FormControl>
                            <FormLabel>Custom Field 1</FormLabel>
                            <Input placeholder="" {...register("customField01")} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Custom Field 2</FormLabel>
                            <Input placeholder="" {...register("customField02")} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Custom Field 3</FormLabel>
                            <Input placeholder="" {...register("customField04")} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Custom Field 4</FormLabel>
                            <Input placeholder="" {...register("customField04")} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Custom Field 5</FormLabel>
                            <Input placeholder="" {...register("customField05")} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Note</FormLabel>
                            <Input placeholder="" {...register("note")} />
                        </FormControl>
                    </Flex>
                    <Flex justify="end">
                        {areaPermissions.has(EntitiesArea.delete) && hasNoDescendants && (
                            <Button variant="ghost" onClick={deleteConfirmation.onOpen} colorScheme={"red"}>
                                Delete
                            </Button>
                        )}

                        <Spacer />

                        <ButtonGroup>
                            <Button {...cancelButton} />
                            {areaPermissions.has(EntitiesArea.update) && <Button {...saveButton} />}
                        </ButtonGroup>
                    </Flex>
                </Flex>
            </form>

            <DeleteConfirmationDialog {...deleteConfirmationDialog}>
                <p>
                    This will permanently delete <strong>{entity.name}</strong>. This cannot be undone.
                </p>
                <br />
                <p>
                    Are you <em>sure</em>?
                </p>
            </DeleteConfirmationDialog>

            <AddressVerificationDialog
                isOpen={isAddressVerificationDialogOpen}
                onClose={closeAddressVerificationDialog}
                addresses={verifiedAddressOptions}
                onSelected={selectedAddress => {
                    closeAddressVerificationDialog();
                    save({ ...getValues(), ...selectedAddress });
                }}
                onSkip={() => {
                    setForceAddress(true);
                    closeAddressVerificationDialog();
                    save(getValues());
                }}
            />
        </>
    );
};

function hasAnyDescendants(entity: EntityResponse): boolean {
    if (entity.candidateCount > 0) {
        return true;
    }

    if (entity.children.length > 0) {
        return true;
    }

    return entity.children.map(c => hasAnyDescendants(c)).reduce((p, n) => p || n, false);
}
