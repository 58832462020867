﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { EntityCreateRequest, EntityResponse } from "../models";
import { queryKeys } from "../queryKeys";
import { AxiosError } from "axios";
import { axiosInstance } from "../../utils";

export function useClientEntityCreate() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: { clientId: string; data: EntityCreateRequest }) =>
            axios.post<EntityResponse>(`api/Clients/${request.clientId}/entities`, request.data).then(response => response.data),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.clients.entities(variables.clientId).queryKey);
            await queryClient.setQueryData(queryKeys.clients.entityById(variables.clientId, data.id).queryKey, data);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
