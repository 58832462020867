﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { EntityResponse } from "../models";
import { queryKeys } from "../queryKeys";
import { AxiosError } from "axios";
import { Operation } from "rfc6902";
import { axiosInstance } from "../../utils";

export function useClientEntityUpdate() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: { clientId: string; entityId: string; patch: Operation[]; forceAddress?: boolean }) =>
            axios
                .patch<EntityResponse>(
                    `api/Clients/${request.clientId}/entities/${request.entityId}`,
                    request.forceAddress
                        ? {
                              ...request.patch,
                              forceAddress: request.forceAddress,
                          }
                        : request.patch
                )
                .then(response => response.data),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.clients.entities(variables.clientId).queryKey);
            queryClient.setQueryData(queryKeys.clients.entityById(variables.clientId, variables.entityId).queryKey, data);
        },
        onError: error => {
            const axiosError = error as AxiosError;

            if (axiosError?.response?.status !== 422) {
                toast({
                    title: "Changes not saved",
                    description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                    status: "error",
                    isClosable: true,
                    duration: null,
                });
            }
        },
    });
}
