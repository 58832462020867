﻿import { FunctionComponent } from "react";
import { Card, CardBody, CardFooter, CardHeader, Divider, Flex, Stack, StackDivider, Text } from "@chakra-ui/react";
import { CandidateComment } from "../../api/models/CandidateComment";

export const CandidateCommentCards: FunctionComponent<CandidateComment> = ({ ...CandidateComment }) => {
    return (
        <>
            <Card>
                <CardHeader>
                    <Flex justifyContent="space-between">
                        <Text variant={"label"}>Created By: {CandidateComment.createdByName}</Text>
                        <Text variant={"label"}>Created On: {CandidateComment.createdOn}</Text>
                    </Flex>
                </CardHeader>
                <Divider />
                <CardBody>
                    <Stack divider={<StackDivider />} spacing="4">
                        <Text fontSize="md">{CandidateComment.body}</Text>
                    </Stack>
                </CardBody>

                <CardFooter></CardFooter>
            </Card>
        </>
    );
};
