﻿import { FunctionComponent } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Treasure from "../../assets/images/treasure.svg";

export const UnauthorizedPage: FunctionComponent = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Flex justify="center">
                <Image src={Treasure} boxSize="250px" mb={16} />
            </Flex>
            <Text fontSize="18px" mt={3} mb={2} textTransform={"uppercase"}>
                Unauthorized
            </Text>
            <Text color={"gray.500"} mb={6}>
                You do not have permissions to view this content.
            </Text>
        </Box>
    );
};
