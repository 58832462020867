﻿import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { axiosInstance } from "../../utils";

export function useCandidateUrl(clientId: string, entityId: string) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.questionnaire.url(clientId, entityId),
        queryFn: () => axios.get<{ url: string }>(`api/clients/${clientId}/entities/${entityId}/candidateurl`).then(response => response.data),
    });
}
