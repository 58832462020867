﻿import { FunctionComponent } from "react";
import { EntityResponse, useClientEntities } from "../../../../api";
import { useSearchParams } from "react-router-dom";
import { HStack, Spinner } from "@chakra-ui/react";
import { EntitySelector, EntitySelectorProps } from "./EntitySelector";
import { findEntityById } from "./findEntityById";
import { IconChevronRight } from "@tabler/icons-react";

export interface EntitySelectionProps {
    clientId: string;
}

export const EntitySelection: FunctionComponent<EntitySelectionProps> = props => {
    const { clientId } = props;
    const entitiesQuery = useClientEntities(clientId);

    const [searchParams, setSearchParams] = useSearchParams(),
        entityId = searchParams.get("eId") ?? undefined;

    const onEntitySelect = (itemId: string | undefined) => {
        setSearchParams(prev => {
            if (itemId) {
                prev.set("eId", itemId);
            } else {
                prev.delete("eId");
            }

            return prev;
        });
    };

    if (entitiesQuery.data == undefined) {
        return <Spinner />;
    }

    const selectors = getEntitySelectors(entitiesQuery.data, undefined, {
        selectedEntityId: entityId,
        onSelect: onEntitySelect,
    });

    return (
        <HStack divider={<IconChevronRight />} alignItems={"center"} gap={".5rem"}>
            {selectors.map((selector, generation) => (
                <EntitySelector key={generation} {...selector} />
            ))}
        </HStack>
    );
};

type EntitySelectorPassthruProps = Pick<EntitySelectorProps, "selectedEntityId" | "onSelect">;

/*
    generates a flat array of Entity selectors
 */
function getEntitySelectors(items: EntityResponse[], parentId: string | undefined, passThru: EntitySelectorPassthruProps): EntitySelectorProps[] {
    const { selectedEntityId } = passThru;
    const [, chain] = findEntityById(items, selectedEntityId);

    if (items.length === 0) {
        return [];
    }

    const selectedEntity = items.find(item => chain.find(id => id === item.id));

    return [
        {
            ...passThru,
            items,
            parentId: parentId,
        },
        ...(selectedEntity ? getEntitySelectors(selectedEntity.children, selectedEntity.id, passThru) : []),
    ];
}
