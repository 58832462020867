﻿import { FunctionComponent, useEffect } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Box, Card, CardBody, Divider, Flex, HStack, Spinner, StackDivider, Stat, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/react";
import { useAdminStatClientCount, useAdminStatUserCount } from "../../api";
import { UseQueryResult } from "@tanstack/react-query";
import { WorkInProgress } from "../../components/WorkInProgress";
import { SetPageTitle } from "../../components/SetPageTitle";

export const AdminDashboardPage: FunctionComponent = () => {
    const appInsights = useAppInsightsContext();

    useEffect(() => {
        appInsights.trackPageView({
            uri: "/admin",
        });
    }, []);

    const clientActiveCount = useAdminStatClientCount(),
        userActiveCount = useAdminStatUserCount();

    return (
        <Box sx={{ height: "100%" }}>
            <SetPageTitle title={"System Settings"} />
            <Flex justify="start" align="center" height="100%" gap={4} direction="column">
                <Divider />
                <HStack divider={<StackDivider borderColor="gray.200" />} gap={8}>
                    <AdminStatCard label="Clients" text="active count" query={clientActiveCount} />
                    <AdminStatCard label="Users" text="active count" query={userActiveCount} />
                </HStack>
                <Divider />
                <WorkInProgress style="ideation" />
            </Flex>
        </Box>
    );
};

const AdminStatCard: FunctionComponent<{ label: string; text: string; query: UseQueryResult<number> }> = ({ label, text, query }) => {
    const { isLoading, data } = query;

    return (
        <Card>
            <CardBody>
                <Stat>
                    <StatLabel>{label}</StatLabel>
                    {isLoading ? <Spinner /> : <StatNumber>{data}</StatNumber>}
                    <StatHelpText>{text}</StatHelpText>
                </Stat>
            </CardBody>
        </Card>
    );
};
