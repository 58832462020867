﻿import { FunctionComponent } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import Void from "../../assets/images/void.svg";

export const NotFound: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <Box textAlign="center" py={10} px={6}>
            <Flex justify="center">
                <Image src={Void} boxSize="250px" mb={16} />
            </Flex>

            <Text fontSize="18px" mt={3} mb={2} textTransform={"uppercase"}>
                Page Not Found
            </Text>
            <Text color={"gray.500"} mb={6}>
                The page you're looking for does not seem to exist
            </Text>

            <Button
                colorScheme="blue"
                bgGradient="linear(to-r, blue.400, blue.500, blue.600)"
                color="white"
                variant="solid"
                onClick={() => navigate("/clients")}
            >
                Go to Client Selection
            </Button>
        </Box>
    );
};
