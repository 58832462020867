﻿import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import { Operation } from "rfc6902";
import { axiosInstance } from "../utils";

export interface IAccountContext {
    id: string;
    name: string;
    dateFormat: string;
    timeFormat: string;
}

export function useAccount() {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.account.me,
        queryFn: () => axios.get<IAccountContext>(`api/Account`).then(response => response.data),
        staleTime: 30000,
    });
}

export function useAccountUpdate() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    return useMutation({
        mutationFn: (patchDocument: Operation[]) => axios.patch<IAccountContext>(`api/Account`, patchDocument).then(response => response.data),
        onSuccess: data => {
            queryClient.setQueryData(queryKeys.account.me.queryKey, data);
        },
    });
}
