﻿import { FunctionComponent } from "react";
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useCreateTenant } from "../../api";

export type TenantCreatorDialogProps = Pick<ModalProps, "isOpen" | "onClose">;
export const TenantCreatorDialog: FunctionComponent<TenantCreatorDialogProps> = props => {
    const { isOpen, onClose } = props;

    const form = useForm({
        defaultValues: {
            name: "",
            description: "",
            externalId: "",
            customField01: "",
            customField02: "",
            customField03: "",
            customField04: "",
            customField05: "",
        },
    });

    const onCancel = () => {
        form.reset();
        onClose();
    };

    const createTenant = useCreateTenant();

    return (
        <Modal isOpen={isOpen} onClose={onCancel} size="xl">
            <ModalOverlay />
            <ModalContent>
                <form
                    onSubmit={form.handleSubmit(data => {
                        createTenant.mutateAsync(data).then(response => {
                            onCancel();
                        });
                    })}
                >
                    <ModalHeader>Create new client</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex gap="4" direction="column">
                            <FormControl isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input placeholder="Client name" {...form.register("name")} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Input placeholder="Optional description" {...form.register("description")} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>External ID</FormLabel>
                                <Input placeholder="Optional external id" {...form.register("externalId")} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Custom Fields</FormLabel>
                                <Flex gap="2" direction="column">
                                    <Input placeholder="Optional custom field 1" {...form.register("customField01")} />
                                    <Input placeholder="Optional custom field 2" {...form.register("customField02")} />
                                    <Input placeholder="Optional custom field 3" {...form.register("customField03")} />
                                    <Input placeholder="Optional custom field 4" {...form.register("customField04")} />
                                    <Input placeholder="Optional custom field 5" {...form.register("customField05")} />
                                </Flex>
                            </FormControl>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onCancel} colorScheme="blue">
                            Cancel
                        </Button>
                        <Button variant="solid" colorScheme="blue" type="submit">
                            Create
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};
