﻿import { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
    AbsoluteCenter,
    Box,
    Button,
    ButtonGroup,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    IconButtonProps,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { EntityTreeContextState } from "../EntityTreeContext";
import { Controller, useForm } from "react-hook-form";
import { IconAlertTriangle, IconArrowAutofitDown, IconBuilding, IconFolder, IconMapPinCheck } from "@tabler/icons-react";
import { EntityCreateRequest, EntityType, useStates, ValidationErrors, ValidationProblemDetails } from "../../../../api";
import { useClientEntityCreate } from "../../../../api/hooks/useClientEntityCreate";
import { AxiosError } from "axios";

export type EntityAddDialogProps = Pick<ModalProps, "isOpen" | "onClose"> & Pick<EntityTreeContextState, "selectedEntity"> & { clientId: string };
export const EntityAddDialog: FunctionComponent<EntityAddDialogProps> = props => {
    const { isOpen, onClose, selectedEntity, clientId } = props;

    const [errors, setErrors] = useState<ValidationErrors<EntityCreateRequest>>();

    const states = useStates(),
        stateOptions = (states.data ?? []).map(state => (
            <option key={state.code} value={state.code}>
                {state.name}
            </option>
        ));

    const entityCreate = useClientEntityCreate();

    const { control, reset, register, handleSubmit, setValue, setError } = useForm<EntityCreateRequest>({
        defaultValues: {
            entityType: "Directory" as EntityType,
            name: "",
            externalId: "",
            ein: "",
            phone: "",
            customField01: "",
            customField02: "",
            customField03: "",
            customField04: "",
            customField05: "",
            note: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zipCode: "",
            county: "",
        },
    });

    useEffect(() => {
        reset();
        entityCreate.reset();
    }, [isOpen]);

    const copyAddressOnClick = useMemo(
        () => () => {
            if (selectedEntity) {
                const formValues = {
                    street1: selectedEntity.street1,
                    street2: selectedEntity.street2,
                    city: selectedEntity.city,
                    state: selectedEntity.state,
                    zipCode: selectedEntity.zipCode,
                    county: selectedEntity.county,
                };

                Object.entries(formValues).forEach(([key, value]) => {
                    // @ts-ignore
                    setValue(key, value);
                });
            }
        },
        [selectedEntity, setValue]
    );

    const copyAddressButton: IconButtonProps = {
            "aria-label": "copy address",
            isDisabled: props.selectedEntity === undefined,
            icon: <Icon as={IconArrowAutofitDown} />,
            onClick: () => copyAddressOnClick(),
        },
        verifyAddressButton: IconButtonProps = {
            "aria-label": "verify address",
            icon: <Icon as={IconMapPinCheck} />,
            isDisabled: true,
        };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <form
                    onSubmit={handleSubmit((data, form) => {
                        entityCreate
                            .mutateAsync({ clientId, data: { ...data, parentId: selectedEntity?.id } })
                            .then(response => {
                                onClose();
                            })
                            .catch(err => {
                                const axiosErr = err as AxiosError;
                                if (axiosErr.response && axiosErr.response.data) {
                                    const problemDetails = axiosErr.response.data as ValidationProblemDetails<EntityCreateRequest>;

                                    setErrors(problemDetails.errors);
                                    for (const prop in problemDetails.errors) {
                                        const propName = prop as keyof EntityCreateRequest;
                                        for (const error of problemDetails.errors[propName]) {
                                            setError(propName, { type: "custom", message: error });
                                        }
                                    }
                                }
                            });
                    })}
                >
                    <ModalHeader>Add Entity</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex gap="4" direction="column">
                            <Flex gap="2" direction="column">
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl as="fieldset" {...register("entityType")}>
                                            <FormLabel as="legend">Type</FormLabel>
                                            {/*@ts-ignore*/}
                                            <RadioGroup value={field.value} onChange={field.onChange}>
                                                <Stack spacing={5} direction="row">
                                                    {EntityTypeRadioOptions}
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                    name={"entityType"}
                                />
                                <FormControl isRequired isInvalid={hasErrors(errors, "name")}>
                                    <FormLabel>Name</FormLabel>
                                    <Input placeholder="Entity name" {...register("name")} />
                                    {errors && errors["name"] && <FormErrorMessage>{errors["name"]}</FormErrorMessage>}
                                </FormControl>

                                <FormControl>
                                    <FormLabel>External ID</FormLabel>
                                    <Input placeholder="Optional external id" {...register("externalId")} />
                                </FormControl>
                            </Flex>
                            <Divider />
                            <Flex gap="2" direction="column">
                                <FormControl>
                                    <FormLabel>EIN</FormLabel>
                                    <Input placeholder="" {...register("ein")} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Phone</FormLabel>
                                    <Input placeholder="" {...register("phone")} />
                                </FormControl>
                            </Flex>
                            <Flex gap="2" direction="column">
                                <Box position="relative" padding="4">
                                    <Divider />
                                    <AbsoluteCenter bg="white" px="4">
                                        Address
                                    </AbsoluteCenter>
                                </Box>
                                <HStack align="end">
                                    <FormControl>
                                        <FormLabel>Street 1</FormLabel>
                                        <Input placeholder="" {...register("street1")} />
                                    </FormControl>
                                    <ButtonGroup size="md">
                                        <Tooltip label="copy address from parent">
                                            <IconButton {...copyAddressButton} />
                                        </Tooltip>
                                        <Tooltip label="verify address">
                                            <IconButton {...verifyAddressButton} />
                                        </Tooltip>
                                    </ButtonGroup>
                                </HStack>
                                <FormControl>
                                    <FormLabel>Street 2</FormLabel>
                                    <Input placeholder="" {...register("street2")} />
                                </FormControl>
                                <HStack>
                                    <FormControl>
                                        <FormLabel>City</FormLabel>
                                        <Input placeholder="" {...register("city")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>State</FormLabel>
                                        <Select placeholder="Select state" {...register("state")}>
                                            {stateOptions}
                                        </Select>
                                    </FormControl>
                                </HStack>
                                <HStack>
                                    <FormControl>
                                        <FormLabel>ZIP</FormLabel>
                                        <Input placeholder="" {...register("zipCode")} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>County</FormLabel>
                                        <Input placeholder="" {...register("county")} />
                                    </FormControl>
                                </HStack>
                            </Flex>

                            <Flex gap="2" direction="column">
                                <Box position="relative" padding="4">
                                    <Divider />
                                    <AbsoluteCenter bg="white" px="4">
                                        Custom Fields
                                    </AbsoluteCenter>
                                </Box>
                                <FormControl>
                                    <FormLabel>Custom Field 1</FormLabel>
                                    <Input placeholder="" {...register("customField01")} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Custom Field 2</FormLabel>
                                    <Input placeholder="" {...register("customField02")} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Custom Field 3</FormLabel>
                                    <Input placeholder="" {...register("customField03")} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Custom Field 4</FormLabel>
                                    <Input placeholder="" {...register("customField04")} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Custom Field 5</FormLabel>
                                    <Input placeholder="" {...register("customField05")} />
                                </FormControl>
                            </Flex>
                            {entityCreate.isError && (
                                <Text color="red">
                                    <Icon as={IconAlertTriangle} />
                                    Error creating entity
                                </Text>
                            )}
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose} colorScheme="blue">
                            Cancel
                        </Button>
                        <Button variant="solid" colorScheme="blue" type="submit">
                            Create
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

function hasErrors(errors: ValidationErrors<EntityCreateRequest> | undefined, fieldName: keyof EntityCreateRequest): boolean {
    if (errors === undefined) {
        return false;
    }

    return !!errors[fieldName];
}

const EntityTypeRadioOptions = [
    <Radio key="Directory" value="Directory">
        <Tooltip label="Folders cannot have candidates assigned to them">
            <HStack>
                <Icon as={IconFolder} />
                <Text>Folder</Text>
            </HStack>
        </Tooltip>
    </Radio>,
    <Radio key="Leaf" value="Leaf">
        <Tooltip label="Locations cannot have folders beneath them">
            <HStack>
                <Icon as={IconBuilding} />
                <Text>Location</Text>
            </HStack>
        </Tooltip>
    </Radio>,
];
