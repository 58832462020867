﻿import { FunctionComponent, PropsWithChildren } from "react";
import { useAccount } from "../../api";
import { UserContext } from "./UserContext";
import { defaultState } from "./defaultState";

export const UserContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const accountQuery = useAccount();

    const contextValue = accountQuery.data ?? defaultState;

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
