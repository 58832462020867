﻿import { queryKeys } from "./queryKeys";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { Operation } from "rfc6902";
import { CreateTenantRequest, CreateTenantUserModel, TenantResponse, UserTenantResponse } from "./models";
import { axiosInstance } from "../utils";

export function useTenants() {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.tenants.all,
        queryFn: () => axios.get<TenantResponse[]>(`api/Tenants?includeDisabled=true`).then(response => response.data),
        meta: {
            errorMessage: "failed to retrieve tenants",
        },
    });
}

export function useDeletedTenants(options?: UseQueryOptions<TenantResponse[]>) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.tenants.deleted,
        queryFn: () => axios.get<TenantResponse[]>(`api/Tenants/deleted`).then(response => response.data),
        meta: {
            errorMessage: "failed to retrieve deleted tenants",
        },
        ...options,
    });
}

export function useTenant(tenantId: string | undefined) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.tenants.byId(tenantId),
        queryFn: () => axios.get<TenantResponse>(`api/Tenants/${tenantId}`).then(response => response.data),
        meta: {
            errorMessage: "failed to retrieve tenant",
        },
        enabled: !!tenantId,
    });
}

export function useUpdateTenant() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: { tenantId: string; patch: Operation[] }) =>
            axios.patch<TenantResponse>(`api/Tenants/${request.tenantId}`, request.patch).then(response => response.data),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.tenants.all);
            await queryClient.invalidateQueries(queryKeys.tenants.deleted);
            await queryClient.invalidateQueries(queryKeys.tenants.byId(variables.tenantId));
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}

export function useDeleteTenant() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (tenantId: string) => axios.delete(`api/Tenants/${tenantId}`),
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.tenants.all);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}

export function useRestoreTenant() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (tenantId: string) => axios.post<TenantResponse>(`api/Tenants/deleted/${tenantId}/restore`).then(response => response.data),
        onSuccess: async data => {
            await queryClient.invalidateQueries(queryKeys.tenants.all);
            queryClient.setQueryData(queryKeys.tenants.byId(data.id).queryKey, data);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}

export function useCreateTenant() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: CreateTenantRequest) => axios.post<TenantResponse>(`api/Tenants`, request).then(response => response.data),
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.tenants.all);

            toast({
                title: "Client created",
                description: `Created new client`,
                status: "success",
                isClosable: true,
            });
        },
        onError: (error, variables, context) => {
            console.log("onError", error, "variables", variables);

            const axiosError = error as AxiosError;

            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}

export function useAdminStatClientCount() {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.adminStats.clientsActiveCount,
        queryFn: () => axios.get<number>(`api/AdminStats/clients/active`).then(response => response.data),
    });
}

export function useAdminStatUserCount() {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.adminStats.usersActiveCount,
        queryFn: () => axios.get<number>(`api/AdminStats/users/active`).then(response => response.data),
    });
}

export function useDeleteUserTenantRole() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (role: UserTenantResponse) => axios.delete(`api/Tenants/${role.tenantId}/UserMappings/${role.id}`),
        onSuccess: async (data, role, context) => {
            await queryClient.invalidateQueries(queryKeys.users.byId(role.userId)._ctx.tenants.queryKey);
            toast({
                title: "Role deleted",
                description: `Access removed for user`,
                status: "success",
                isClosable: true,
            });
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}

export function useCreateUserTenantRole() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: ({ tenantId, ...request }: CreateTenantUserModel) =>
            axios.post<UserTenantResponse>(`api/Tenants/${tenantId}/UserMappings`, request).then(response => response.data),
        onSuccess: async (data, role, context) => {
            await queryClient.invalidateQueries(queryKeys.users.byId(role.userId)._ctx.tenants.queryKey);
            toast({
                title: "Access Created",
                description: `User role granted`,
                status: "success",
                isClosable: true,
            });
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}

export function useUserAdSync() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: () => axios.post(`api/Users/SyncAd`),
        onSuccess: async () => {
            await queryClient.invalidateQueries(queryKeys.users.all.queryKey);
            toast({
                title: "Completed",
                description: `User information sync'd from AD`,
                status: "success",
                isClosable: true,
            });
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
