﻿import { FC } from "react";
import { Button, ButtonProps, Flex } from "@chakra-ui/react";
import { useMatch, useNavigate } from "react-router-dom";
import { IconLogout } from "@tabler/icons-react";
import { useHasAccess } from "../../hooks/useHasAccess";
import { ApplicationRole } from "../../api";

export const NavigationFooter: FC = () => {
    const navigate = useNavigate();
    const profileMatch = useMatch("/profile");

    const { hasRole } = useHasAccess();
    const isAdmin = hasRole(ApplicationRole.admin);
    const adminMatch = useMatch("/admin/*");

    const styles: ButtonProps = {
        variant: "ghost",
        width: "100%",
        colorScheme: "whiteAlpha",
        color: "whiteAlpha.900",
        justifyContent: "space-between",
        paddingX: 8,
    };

    return (
        <Flex direction="column">
            {isAdmin && adminMatch && (
                <Button {...styles} onClick={() => navigate("/clients")} rightIcon={<IconLogout />} isActive={profileMatch !== null}>
                    Exit System Settings
                </Button>
            )}
        </Flex>
    );
};
