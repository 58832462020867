﻿import { ICellRendererParams } from "@ag-grid-community/core";
import { ApplicationRole, TenantRole, UserResponse } from "../../../api";
import { RoleNames } from "../../../hooks/useHasAccess";
import { Flex, Icon, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { IconBrandVisualStudio, IconCrown, IconEyeglass, IconPencil, IconShield } from "@tabler/icons-react";

export const AppRoleCellRenderer = (props: ICellRendererParams<UserResponse, ApplicationRole[]>) => {
    const roles = (props.value ?? []).map(r => ({ role: r, name: RoleNames[r], icon: RoleTagIcons[r] })),
        roleTags = roles.map(role => (
            <Tag variant="outline" key={role.role}>
                {role.icon}
                <TagLabel>{role.name}</TagLabel>
            </Tag>
        ));

    return (
        <Flex height="100%" align="center" gap={1}>
            {roleTags}
        </Flex>
    );
};

export const RoleTagIcons: Record<ApplicationRole, JSX.Element> = {
    [ApplicationRole.admin]: <TagLeftIcon as={IconShield} />,
    [ApplicationRole.contributor]: <TagLeftIcon as={IconPencil} />,
    [ApplicationRole.reader]: <TagLeftIcon as={IconEyeglass} />,
    [ApplicationRole.developer]: <TagLeftIcon as={IconBrandVisualStudio} />,
};

export const RoleIcons: Record<ApplicationRole, JSX.Element> = {
    [ApplicationRole.admin]: <Icon as={IconShield} />,
    [ApplicationRole.contributor]: <Icon as={IconPencil} />,
    [ApplicationRole.reader]: <Icon as={IconEyeglass} />,
    [ApplicationRole.developer]: <Icon as={IconBrandVisualStudio} />,
};

export const TenantRoleIcons: Record<TenantRole, JSX.Element> = {
    [TenantRole.Owner]: <Icon as={IconCrown} />,
    [TenantRole.Contributor]: <Icon as={IconPencil} />,
    [TenantRole.Reader]: <Icon as={IconEyeglass} />,
};
