import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

(async function () {
    if (import.meta.env.VITE_USE_MSW_CUSTOM_MOCKS === "true") {
        // @ts-ignore
        const setup = await import("./api/msw/setupForBrowser.ts");
        const { worker } = setup;
        await worker.start();
    }

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
})();
