﻿export type EntityCandidateArgs = {
    clientId: string | undefined;
    entityId: string | undefined;
    candidate: EntityCandidate;
};

export interface EntityCandidate {
    id: string;
    entityId: string;
    entityName: string;
    status: CandidateStatus;
    externalId: string | undefined;
    firstName: string | undefined;
    middleName: string | undefined;
    lastName: string | undefined;
    suffix: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    socialSecurityNumber: string | undefined;
    dateOfBirth: string | undefined;
    street1: string | undefined;
    street2: string | undefined;
    city: string | undefined;
    county: string | undefined;
    state: string | undefined;
    zipCode: string | undefined;
    qualifications: string[] | undefined;
    selectedQualification: string | undefined | null;
    designatedZone: string | undefined;
    designatedZoneType: DesignatedZoneType | undefined;
    commentCount: number;
    employmentStatus: EmploymentStatus;
    hireDate: string;
    terminationDate: string;
    startDate: string;
    offerDate: string;
    hourlyRate: number;
    hoursWorked: number;
    questionnaireCompletedOn: string;
    form8850Id?: string;
    daysRemaining: number;
    targetDate: string;
    preventNotificationsUntil: string | null;
    openRequestCount: number;
}

export type CandidateActivity = {
    id: string;
    oldStatus: CandidateStatus | null | undefined;
    newStatus: CandidateStatus;
    createdOn: string;
    createdBy:
        | {
              id: string;
              displayName: string;
              email: string;
              createdOn: string;
              isSelfRegistered: boolean;
              appRoles: string[] | null;
          }
        | null
        | undefined;
};

export interface EntityCandidateQualification {
    id: string;
    comment: string | undefined;
    createdOn: string;
    createdById: string;
    deletedOn: string | undefined;
    deletedById: string | undefined;
    designatedZoneName: string | undefined;
    designatedZoneType: string | undefined;
    isManuallyAdded: boolean;
    value: CandidateQualification;
}

export enum EmploymentStatus {
    Unknown = "0",
    Hired = "Hired",
    NotHired = "NotHired",
}

export function displayEmploymentStatus(status: EmploymentStatus): string {
    switch (status) {
        case EmploymentStatus.Unknown:
            return "Unknown";
        case EmploymentStatus.Hired:
            return "Hired";
        case EmploymentStatus.NotHired:
            return "Not Hired";
    }
}

export enum CandidateQualification {
    DECR = "DECR",
    EXFN = "EXFN",
    TANF = "TANF",
    LTFA = "LTFA",
    LTUE = "LTUE",
    SNAP = "SNAP",
    SSIN = "SSIN",
    SYEM = "SYEM",
    VETA = "VETA",
    VETB = "VETB",
    VETC = "VETC",
    VETD = "VETD",
    VORR = "VORR",
    SWAC = "SWAC",
}

export function displayQualification(qualification: CandidateQualification): string {
    switch (qualification) {
        case CandidateQualification.DECR:
            return "Designated Community Resident";
        case CandidateQualification.EXFN:
            return "Ex-Felon";
        case CandidateQualification.TANF:
            return "IV-A Recipient";
        case CandidateQualification.LTFA:
            return "Long-term family assistance recipient";
        case CandidateQualification.LTUE:
            return "Long-term unemployment recipient";
        case CandidateQualification.SNAP:
            return "SNAP Benefits (food stamps)";
        case CandidateQualification.SSIN:
            return "SSI Recipient";
        case CandidateQualification.SYEM:
            return "Summer Youth Employee";
        case CandidateQualification.VETA:
            return "Veteran";
        case CandidateQualification.VETB:
            return "Veteran (Unemployed 6+ months)";
        case CandidateQualification.VETC:
            return "Veteran (disability)";
        case CandidateQualification.VETD:
            return "Veteran (disability, unemployed 6+ months)";
        case CandidateQualification.VORR:
            return "Vocational Rehabilitation Referral";
        case CandidateQualification.SWAC:
            return "SWA Certification";
    }
}

export enum DesignatedZoneType {
    RuralRenewalCommunity = "RuralRenewalCommunity",
    EmpowermentZone = "EmpowermentZone",
}

export function displayZone(designation: DesignatedZoneType): string {
    switch (designation) {
        case DesignatedZoneType.RuralRenewalCommunity:
            return "Rural Renewal Community";
        case DesignatedZoneType.EmpowermentZone:
            return "Empowerment Zone";
    }
}

export enum CandidateStatus {
    // this won't get used for the QR code path. This is here to
    NotScreened = "NotScreened",

    // this is the state that gets assigned when they click the "Let's get Started" link.
    Screening = "Screening",

    // Completing the Survey results in one of these outcomes
    OptedOut = "OptedOut",
    Eligible = "Eligible",
    NotEligible = "NotEligible",

    // This is a final state. If they go here, they are inactive. This status would be assigned when processing a data feed or by manually changing the status.
    NotHired = "NotHired",

    // set this status when candidate is notified that there are pending requests (via email and/or text)
    PendingDocuments = "PendingDocuments",
    UnableToSubstantiate = "UnableToSubstantiate",
    ReadyForCertification = "ReadyForCertification",
    PendingCertification = "PendingCertification",
    Certified = "Certified",
    Claimed = "Claimed",
    Denied = "Denied",
    InAppeal = "InAppeal",
    AppealDenied = "AppealDenied",

    Expired = "Expired",
}
