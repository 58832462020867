﻿import { useClient, usePermissionsByRole } from "../api";
import { useMemo } from "react";
import {
    AppPermissions,
    AreaPermissions,
    SecurityArea,
    PermissionsByAreaType,
    Permission,
    roleCanSeeArea,
    roleHasPermission,
    roleHasAnyPermission,
} from "../auth";

const emptyPermissions = {};

export function useAppPermissions(clientId: string | undefined): AppPermissions {
    const clientQuery = useClient(clientId);
    const permissionsQuery = usePermissionsByRole();
    const permissionsByRole = permissionsQuery.data || emptyPermissions;

    return useMemo(() => {
        const permissions: AppPermissions = {
            loading: clientQuery.isLoading || permissionsQuery.isLoading,
            isInternal: false,
            showArea: (area: SecurityArea) => roleCanSeeArea(permissionsByRole, area, clientQuery.data?.roleId),
            has: (permission: Permission | null) => false,
            hasAny: (...permissions: Permission[]) => false,
        };

        if (clientQuery.data?.id) {
            permissions.isInternal = clientQuery.data.isInternal;
            permissions.has = (permission: Permission | null) => roleHasPermission(permissionsByRole, permission, clientQuery.data?.roleId);
            permissions.hasAny = (...permissions: Permission[]) => roleHasAnyPermission(permissionsByRole, permissions, clientQuery.data?.roleId);
        }
        return permissions;
    }, [permissionsByRole, permissionsQuery.isLoading, clientQuery.data?.roleId, clientQuery.data?.isInternal, clientQuery.isLoading]);
}

export function useAreaPermissions<A extends SecurityArea>(area: A, clientId: string | undefined): AreaPermissions<A> {
    const clientQuery = useClient(clientId);
    const permissionsQuery = usePermissionsByRole();
    const permissionsByRole = permissionsQuery.data || emptyPermissions;

    return useMemo(() => {
        const permissions: AreaPermissions<A> = {
            loading: clientQuery.isLoading || permissionsQuery.isLoading,
            isInternal: false,
            showArea: roleCanSeeArea(permissionsByRole, area, clientQuery.data?.roleId),
            has: (permission: PermissionsByAreaType<A> | null) => false,
            hasAny: (...permissions: PermissionsByAreaType<A>[]) => false,
        };

        if (clientQuery.data?.id) {
            permissions.isInternal = clientQuery.data.isInternal;
            permissions.has = (permission: PermissionsByAreaType<A> | null) => roleHasPermission(permissionsByRole, permission, clientQuery.data?.roleId);
            permissions.hasAny = (...permissions: PermissionsByAreaType<A>[]) => roleHasAnyPermission(permissionsByRole, permissions, clientQuery.data?.roleId);
        }
        return permissions;
    }, [clientQuery.isLoading, clientQuery.data?.roleId, clientQuery.data?.isInternal, permissionsQuery.isLoading, permissionsByRole, area]);
}
