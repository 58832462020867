﻿import { queryKeys } from "../queryKeys";
import { useQuery } from "@tanstack/react-query";
import { CandidatesArea, DashboardArea, EntitiesArea } from "../../auth";

export type PermissionsByRoleResponse = {
    [key: string]: string[] | undefined;
};

export function usePermissionsByRole() {
    return useQuery({
        ...queryKeys.permissions.byRole,
        queryFn: async () => {
            const response = {
                "am:tax:wotc:roles:owner": [
                    DashboardArea.read,
                    EntitiesArea.create,
                    EntitiesArea.read,
                    EntitiesArea.update,
                    EntitiesArea.delete,
                    CandidatesArea.read_basic,
                    CandidatesArea.read_full,
                    CandidatesArea.edit,
                    CandidatesArea.request_mgmt,
                    CandidatesArea.questionnaire,
                ],
                "am:tax:wotc:roles:contributor": [
                    DashboardArea.read,
                    EntitiesArea.create,
                    EntitiesArea.read,
                    EntitiesArea.update,
                    EntitiesArea.delete,
                    CandidatesArea.read_basic,
                    CandidatesArea.read_full,
                    CandidatesArea.edit,
                    CandidatesArea.request_mgmt,
                    CandidatesArea.questionnaire,
                ],
                "am:tax:wotc:roles:reader": [DashboardArea.read, EntitiesArea.read, CandidatesArea.read_basic, CandidatesArea.read_full],
                "am:tax:wotc:roles:limited": [DashboardArea.read, EntitiesArea.read, CandidatesArea.read_basic],
            };
            const permissionsByRole: Record<string, Set<string>> = {};
            Object.entries(response).forEach(([roleId, permissions]) => {
                permissionsByRole[roleId] = new Set(permissions || []);
            });
            return permissionsByRole;
        },
    });
}
