﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { queryKeys } from "../queryKeys";
import { AxiosError } from "axios";
import { axiosInstance } from "../../utils";

export function useRequestDelete() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: { candidateId: string; requestId: string }) =>
            axios.delete(`api/candidates/${request.candidateId}/requests/${request.requestId}`),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.requests.byCandidateId(variables.candidateId).queryKey);
            queryClient.removeQueries(queryKeys.requests.byCandidateAndRequest(variables.candidateId, variables.requestId).queryKey);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Unable to delete document request",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
