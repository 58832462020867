﻿import { FunctionComponent } from "react";
import { Heading, Flex, Box, HeadingProps, Spacer } from "@chakra-ui/react";

export const HeadingWithLine: FunctionComponent<
    HeadingProps & {
        lineWeight?: string;
        lineColor?: string;
        gap?: string;
        farItem?: JSX.Element;
    }
> = ({ children, lineWeight, lineColor, gap, farItem, ...props }) => (
    <Flex direction="row" justifyContent="stretch" alignItems="center" gap={gap || "2rem"} marginBottom="4">
        <Heading {...props}>{children}</Heading>
        {farItem && <Box flexGrow={1}>{farItem}</Box>}
        <Box flexGrow={4} backgroundColor={lineColor || "brandPrimary.darkBlue"} height={lineWeight || "2px"} />
    </Flex>
);

export const HeadingWithUnderline: FunctionComponent<
    HeadingProps & {
        lineWeight?: string;
        lineColor?: string;
        gap?: string;
        farItem?: JSX.Element;
    }
> = ({ children, lineWeight, lineColor, gap, farItem, ...props }) => (
    <Flex direction="column" gap={gap || "0.5rem"} marginBottom="4">
        <Flex direction="row" gap={gap || "2rem"} align="stretch" grow={1} paddingRight={2}>
            <Heading {...props}>{children}</Heading>
            <Spacer />
            {farItem}
        </Flex>
        <Box flexGrow={4} backgroundColor={lineColor || "brandPrimary.darkBlue"} height={lineWeight || "2px"} />
    </Flex>
);
