﻿import { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Card, CardBody, Heading, HStack, useToast, VStack, Icon } from "@chakra-ui/react";
import { IconCopy } from "@tabler/icons-react";
import { useCandidateUrl } from "../../../api/hooks/useCandidateUrl";
import { useCandidateQr } from "../../../api";

export const QrWidget: FunctionComponent<{ clientId: string; entityId: string }> = ({ clientId, entityId }) => {
    const toast = useToast();
    const candidateUrlQuery = useCandidateUrl(clientId, entityId);
    const candidateQrQuery = useCandidateQr(clientId, entityId);
    const [qrUrl, setQrUrl] = useState<string>();
    const surveyUrl = candidateUrlQuery.data;

    useEffect(() => {
        if (candidateQrQuery.data) {
            const blob = new Blob([candidateQrQuery.data]);
            setQrUrl(URL.createObjectURL(blob));
        }
    }, [candidateQrQuery.data]);

    const copy = () => {
        const value = surveyUrl?.url || "";
        navigator.clipboard.writeText(value);
        toast({
            title: "Copied to clipboard",
            description: `Copied "${value}"`,
            status: "success",
            duration: 3000,
            isClosable: true,
        });
    };

    const openInNewTab = () => {
        const value = surveyUrl?.url || "";
        window.open(value, "_blank")?.focus();
    };

    return (
        <VStack spacing={"1rem"}>
            <Heading variant={"info"}>Candidate Questionnaire</Heading>
            <Card variant={"withShadow"}>
                <CardBody px={"2rem"}>
                    <HStack spacing={"1rem"} maxWidth={"550"}>
                        <VStack flexBasis={"50%"} color={"blackAlpha.800"} fontSize={"lg"} alignItems={"center"} spacing={"2rem"}>
                            <Box
                                flexShrink={1}
                                maxWidth={250}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                                textOverflow={"ellipsis"}
                                onClick={openInNewTab}
                                cursor={"pointer"}
                            >
                                {surveyUrl?.url}
                            </Box>
                            <Button onClick={copy} rightIcon={<Icon as={IconCopy} />} fontWeight={"normal"}>
                                Copy URL
                            </Button>
                        </VStack>
                        <Box flexBasis={"50%"} minWidth={150}>
                            <img src={qrUrl} alt={surveyUrl?.url} />
                        </Box>
                    </HStack>
                </CardBody>
            </Card>
        </VStack>
    );
};
