﻿import { FC, ReactElement } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { NavigationFooter } from "./NavigationFooter";
import { LeftNav as LeftNavCore, NavGroup } from "@am-tax/fe-core";
import { useMatch } from "react-router-dom";
import { IconAffiliate, IconDashboard, IconUsers, IconUsersGroup } from "@tabler/icons-react";
import { NavItem, NavItemProps } from "./NavItem";
import { CandidatesArea, DashboardArea, EntitiesArea } from "../../auth";
import { useAppPermissions } from "../../hooks";
import { useHasAccess } from "../../hooks/useHasAccess";
import { ApplicationRole } from "../../api";

export const LeftNav: FC = () => {
    const { hasRole } = useHasAccess();
    const isAdmin = hasRole(ApplicationRole.admin);

    const adminPageMatch = useMatch("/admin/*");
    const selectionPageMatch = useMatch("/clients/*");
    const clientPageMatch = useMatch("/clients/:cId/*");
    const colorScheme = adminPageMatch ? "gray" : "blue";

    const cId = clientPageMatch?.params?.cId;
    const appPermissions = useAppPermissions(cId);

    let items: Array<NavItemProps | NavGroup | ReactElement> = [];

    if (clientPageMatch) {
        items = [
            <NavItem
                show={appPermissions.showArea(DashboardArea)}
                {...{ text: "Dashboard", to: `/clients/${cId}`, leftIcon: <IconDashboard size="1rem" />, end: true, colorScheme }}
            />,
            <NavItem
                show={appPermissions.showArea(CandidatesArea)}
                {...{ text: "Candidates", to: `/clients/${cId}/candidates`, leftIcon: <IconUsersGroup size="1rem" />, colorScheme }}
            />,
            <NavItem
                show={appPermissions.showArea(EntitiesArea)}
                {...{ text: "Entities & QR Codes", to: `/clients/${cId}/entities`, leftIcon: <IconAffiliate size="1rem" />, colorScheme }}
            />,
        ];
    }

    if (isAdmin && adminPageMatch) {
        items = [
            <NavItem
                show
                {...{
                    text: "Dashboard",
                    to: `/admin`,
                    leftIcon: <IconDashboard size="1rem" />,
                    end: true,
                    colorScheme,
                }}
            />,
            <NavItem
                show
                {...{
                    text: "Clients",
                    to: `/admin/clients`,
                    leftIcon: <IconAffiliate size="1rem" />,
                    colorScheme,
                }}
            />,
            <NavItem
                show
                {...{
                    text: "Users",
                    to: `/admin/users`,
                    leftIcon: <IconUsers size="1rem" />,
                    colorScheme,
                }}
            />,
        ];
    }

    return (
        <LeftNavCore
            logo={{ type: "us" }}
            appTitle={"Work Opportunity Tax Credit"}
            appTitleProps={{ letterSpacing: 0 }}
            bgGradient={`linear(to-b, ${colorScheme}.900, ${colorScheme}.700)`}
            items={items}
            itemsHeader={
                selectionPageMatch &&
                !clientPageMatch && (
                    <Flex height="100%" direction="column">
                        <Text as="em" color="whiteAlpha.800">
                            Select a client from the right
                        </Text>
                    </Flex>
                )
            }
            itemsFooter={<NavigationFooter />}
        />
    );
};
