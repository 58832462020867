﻿import { FunctionComponent } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { EntitySelection } from "./components/EntitySelection";
import { CandidateGrid } from "./components/CandidateGrid";
import { SetPageTitle } from "../../components/SetPageTitle";
import { Box } from "@chakra-ui/react";

export const ClientCandidatesPage: FunctionComponent = () => {
    const { cId } = useParams();

    const [searchParams] = useSearchParams();
    const entityId = searchParams.get("eId") ?? undefined;

    return (
        <>
            <SetPageTitle title={`Candidates`} />

            {cId && (
                <CandidateGrid
                    clientId={cId}
                    entityId={entityId}
                    before={
                        <Box mb={"2rem"}>
                            <EntitySelection clientId={cId} />
                        </Box>
                    }
                />
            )}
        </>
    );
};
