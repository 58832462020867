﻿import { queryKeys } from "../queryKeys";
import { useQuery } from "@tanstack/react-query";
import { Request } from "../../common";
import { axiosInstance } from "../../utils";

export function useRequests(clientId: string | undefined, entityId: string | undefined, candidateId: string | undefined) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.requests.byCandidateId(candidateId),
        enabled: !!(clientId && entityId && candidateId),
        queryFn: async () => {
            const response = await axios.get<Request[]>(`api/clients/${clientId}/entities/${entityId}/candidates/${candidateId}/requests`);
            return response.data;
        },
    });
}
