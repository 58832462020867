﻿import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { axiosInstance } from "../../utils";

export function useQuestionnaireValues(clientId: string | undefined, entityId: string | undefined, candidateId: string | undefined) {
    const axios = axiosInstance();

    return useQuery(queryKeys.questionnaire.values(clientId, entityId, candidateId).queryKey, () => {
        if (candidateId && entityId && clientId) {
            return axios
                .get<Record<string, string>>(`api/clients/${clientId}/entities/${entityId}/candidates/${candidateId}/questionnaire`)
                .then(response => response.data);
        } else {
            return Promise.resolve(null);
        }
    });
}

export function useQuestionnaireValuesQueryFn() {
    const axios = axiosInstance();
    const queryClient = useQueryClient();

    return async function (clientId: string | undefined, entityId: string | undefined, candidateId: string | undefined): Promise<Record<string, string>> {
        return queryClient.fetchQuery(queryKeys.questionnaire.values(clientId, entityId, candidateId).queryKey, async () => {
            if (candidateId && entityId && clientId) {
                const response = await axios.get<Record<string, string>>(
                    `api/clients/${clientId}/entities/${entityId}/candidates/${candidateId}/questionnaire`
                );
                return response.data;
            } else {
                return Promise.resolve(null);
            }
        });
    };
}
