﻿import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { StateResponse } from "../models";
import { axiosInstance } from "../../utils";

export const useStates = () => {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.states.all,
        queryFn: () => axios.get<StateResponse[]>(`api/States`).then(response => response.data),
        staleTime: Infinity,
        cacheTime: Infinity,
        meta: {
            errorMessage: "failed to retrieve states",
        },
    });
};
