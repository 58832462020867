﻿import { useEffect, useMemo, useState } from "react";
import { HasId, IUseAgGridInfiniteReturn, UseAgGridInfiniteOptions } from "./useAgGridInfinite.types";
import { FilterChangedEvent, GridApi, GridOptions, GridReadyEvent, ModuleRegistry, SelectionChangedEvent } from "@ag-grid-community/core";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { useDatasource } from "./useDatasource";

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([InfiniteRowModelModule]);

export function useAgGridInfinite<TData extends HasId>(options: UseAgGridInfiniteOptions<TData>): IUseAgGridInfiniteReturn<TData> {
    const { apiUrl } = options;

    const { isLoading, totalCount, datasource } = useDatasource<TData>(apiUrl),
        [gridApi, setGridApi] = useState<GridApi<TData>>(),
        [selected, setSelected] = useState<TData[]>([]);

    // grid auto-sizing
    const [minRowHeight, setMinRowHeight] = useState(25),
        [currentRowHeight, setCurrentRowHeight] = useState<number>(minRowHeight),
        updateTableSize = useMemo(
            () => (params: { api: GridApi }) => {
                // get the height of the grid body - this excludes the height of the headers
                const bodyViewport = document.querySelector(".ag-body-viewport");
                if (!bodyViewport) {
                    return;
                }

                const gridHeight = bodyViewport.clientHeight;
                // get the rendered rows
                const renderedRowCount = params.api.getDisplayedRowCount();

                // if the rendered rows * min height is greater than available height, just just set the height
                // to the min and let the scrollbar do its thing
                if (renderedRowCount * minRowHeight >= gridHeight) {
                    if (currentRowHeight !== minRowHeight) {
                        setCurrentRowHeight(minRowHeight);
                        params.api.resetRowHeights();
                    }
                } else {
                    // set the height of the row to the grid height / number of rows available
                    setCurrentRowHeight(Math.floor(gridHeight / renderedRowCount));
                    params.api.resetRowHeights();
                }

                params.api.sizeColumnsToFit();
            },
            [minRowHeight, currentRowHeight]
        );

    // define utility functions
    const reloadData = useMemo(
        () => () => {
            if (gridApi) {
                gridApi.setDatasource(datasource);
            }
        },
        [gridApi, datasource]
    );

    const resetSelection = useMemo(
        () => (api: GridApi) => {
            api.deselectAll();
        },
        []
    );

    const resetFilter = useMemo(
        () => (api: GridApi) => {
            api.setFilterModel({});
        },
        []
    );

    const resetGrid = useMemo(
        () => () => {
            if (gridApi) {
                resetSelection(gridApi);
                resetFilter(gridApi);
                reloadData();
            }
        },
        [gridApi, reloadData]
    );

    useEffect(() => {
        resetGrid();
    }, [apiUrl]);

    // define grid events
    const onGridReady = useMemo(
            () =>
                ({ api }: GridReadyEvent) => {
                    setMinRowHeight(api.getSizesForCurrentTheme().rowHeight);
                    api.sizeColumnsToFit();

                    api.setDatasource(datasource);
                    setGridApi(api);
                },
            [datasource]
        ),
        onFirstDataRendered = updateTableSize,
        onGridSizeChanged = updateTableSize,
        onFilterChanged = ({ api }: FilterChangedEvent) => resetSelection(api),
        onSelectionChanged = useMemo(
            () =>
                ({ api }: SelectionChangedEvent) => {
                    setSelected(api.getSelectedRows());
                },
            []
        );

    // define grid options
    const gridOptions = useMemo<GridOptions<TData>>(
        () => ({
            animateRows: true,
            getRowId: ({ data }) => data.id,
            rowModelType: "infinite",
            rowSelection: options.rowSelection,
            defaultColDef: options.defaultColDef,
            columnDefs: options.columnDefs,
            onGridReady,
            onFirstDataRendered,
            onGridSizeChanged,
            onFilterChanged,
            onSelectionChanged,
        }),
        [onGridReady]
    );

    return {
        dataSource: {
            isLoading,
            totalCount,
            reloadData,
        },
        state: {
            selected,
            selectedCount: selected.length,
        },
        gridOptions,
        gridApi,
    };
}
