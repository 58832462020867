import { FunctionComponent, RefObject, useRef } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Flex,
    Text,
} from "@chakra-ui/react";
import { Address } from "../../api/models";

export interface AddressVerificationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSkip: () => void;
    addresses: Address[];
    onSelected: (selectedAddress: Address) => void;
    title?: string;
    prompt?: string;
    skipButtonText?: string;
    reenterButtonText?: string;
}

export const AddressVerificationDialog: FunctionComponent<AddressVerificationDialogProps> = ({ isOpen, onClose, onSkip, addresses, onSelected, title, prompt, skipButtonText, reenterButtonText }) => {
    const cancelRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

    return (
        <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader>{title || "Verify Address"}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    <Text mb={"1rem"}>{prompt || "The address you provided was not found. Please select an address below, or reenter the address."}</Text>
                    <Flex direction={"column"} gap={"1rem"}>
                        {addresses.map((address, index) => (
                            <Button
                                key={index}
                                onClick={() => onSelected(address)}
                                variant={"ghost"}
                                borderWidth={1}
                                fontWeight={"normal"}
                                color={"blackAlpha.800"}
                                borderColor={"cyan.100"}
                                _hover={{ bg: "cyan.50" }}
                            >
                                {address.street1} {address.street2} {address.city}, {address.state} {address.zipCode}
                            </Button>
                        ))}
                    </Flex>
                </AlertDialogBody>
                <AlertDialogFooter display={"flex"} justifyContent={"space-between"}>
                    <Button onClick={onSkip}>{skipButtonText || "Skip"}</Button>
                    <Button variant={"primary"} onClick={onClose}>{reenterButtonText || "Reenter Address"}</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
