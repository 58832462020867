﻿import { FC } from "react";
import { Badge, Card, CardBody, Divider, Flex, Heading, Spacer, VStack } from "@chakra-ui/react";
import { Shimmer } from "@am-tax/fe-core";
import { DashboardResponse } from "../../api/models/DashboardResponse";

type CreditsWidgetProps = {
    isLoading: boolean;
    credits: DashboardResponse["credits"] | undefined;
};

const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

export const CreditsWidget: FC<CreditsWidgetProps> = ({ isLoading, credits }) => {
    return (
        <Card width={"100%"} height={"100%"} variant={"withShadow"}>
            <CardBody px={"2rem"}>
                <VStack spacing={".75rem"} divider={<Divider />}>
                    <Flex flexDirection="column" width={"100%"} alignItems={"center"} gap={"1rem"}>
                        <Heading as={"h2"} mb={0} color={"blackAlpha.800"} fontWeight={"bold"} fontSize={"lg"} height={"48px"} alignItems={"center"}>
                            Credits
                        </Heading>
                        <Flex as="ul" flexDirection={"column"} width={"100%"} gap={"1rem"}>
                            <Flex as={"li"} alignItems={"center"} gap={".5rem"}>
                                <label>Prior Year Credit</label>
                                <Spacer />
                                <Shimmer isLoading={isLoading}>
                                    <Badge colorScheme={"green"} fontSize={"xl"} px={"1rem"}>
                                        {currencyFormatter.format(credits?.priorYearCredit || 0)}
                                    </Badge>
                                </Shimmer>
                            </Flex>
                            <Flex as={"li"} alignItems={"center"} gap={".5rem"}>
                                <label>Avg Credit per Eligible Hire</label>
                                <Spacer />
                                <Shimmer isLoading={isLoading}>
                                    <Badge fontSize={"xl"} px={"1rem"}>
                                        {currencyFormatter.format(credits?.avgCreditPerHire || 0)}
                                    </Badge>
                                </Shimmer>
                            </Flex>
                        </Flex>
                    </Flex>
                </VStack>
            </CardBody>
        </Card>
    );
};
