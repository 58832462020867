import { InputType, Question, QuestionnaireValues, SyncableValue } from "../api/models";
import { Operation } from "rfc6902/diff";
import { ProblemDetails } from "../api/models";
import { useDebouncedFunction } from "@am-tax/fe-core";
import { UseMutationResult } from "@tanstack/react-query";


export function useSaveQuestionnaireValue(
    questionnaireValues: QuestionnaireValues,
    setQuestionnaireValues: (values: QuestionnaireValues) => void,
    saveQuestionnaireValuesQuery: UseMutationResult<Record<string, string>, unknown, Operation[]>
) {
    async function doSave(question: Question, newValue: string) {
        // update the state to show that we are syncing
        const pendingValues = { ...questionnaireValues };
        pendingValues[question.id] = {
            value: newValue,
            isSyncing: true,
            isSaved: false,
            isModified: true,
        };
        setQuestionnaireValues(pendingValues);

        const replaceOperation: Operation = { value: newValue, path: question.id, op: "replace" };
        await saveQuestionnaireValuesQuery.mutateAsync([replaceOperation]);

        // update the state to show that we are no longer syncing
        const newValues = { ...questionnaireValues };
        newValues[question.id] = {
            value: newValue,
            isSyncing: false,
            isSaved: true,
            isModified: true,
        };
        setQuestionnaireValues(newValues);
    }

    const saveDebounced = useDebouncedFunction(doSave, 500);

    const saveValue = async (question: Question, oldValue: SyncableValue, newValue: string) => {
        if (question.input.type === InputType.text || question.input.type === InputType.date) {
            // update the state with the pending value and show as NOT yet syncing
            const pendingValues = { ...questionnaireValues };
            pendingValues[question.id] = {
                value: newValue,
                isSyncing: false,
                isSaved: false,
                isModified: true,
            };
            setQuestionnaireValues(pendingValues);
        }

        // issue the update
        try {
            if (question.input.type === InputType.text || question.input.type === InputType.date) {
                await saveDebounced(question, newValue);
            } else {
                await doSave(question, newValue);
            }
        } catch (e) {
            // @ts-ignore
            const error = (e?.response?.data || e) as ProblemDetails;
            // if there was an error, revert the state to the old value
            const oldValues = { ...questionnaireValues };
            oldValues[question.id] = { ...oldValue, errorMessage: error.title };
            setQuestionnaireValues(oldValues);
        }
    };
    return saveValue;
}
