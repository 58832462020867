﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { queryKeys } from "../queryKeys";
import { AxiosError } from "axios";
import { axiosInstance } from "../../utils";

export function useClientEntityDelete() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: { clientId: string; entityId: string }) => axios.delete(`api/Clients/${request.clientId}/entities/${request.entityId}`),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.clients.entities(variables.clientId).queryKey);
            await queryClient.setQueryData(queryKeys.clients.entityById(variables.clientId, variables.entityId).queryKey, undefined);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Changes not saved",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
