﻿import { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Flex, ButtonProps } from "@chakra-ui/react";

export type NavItemProps = {
    show?: boolean;
    text: string;
    colorScheme: string;
} & Pick<ButtonProps, "leftIcon"> &
    NavLinkProps;

export const NavItem: FC<NavItemProps> = props => {
    const { show, text, colorScheme, ...rest } = props,
        { leftIcon, ...navItemProps } = rest;

    return show ? (
        <NavLink
            {...navItemProps}
            style={{
                width: "100%",
                color: `${colorScheme}.800`,
            }}
        >
            {({ isActive, isPending }) => (
                <Flex
                    alignItems="center"
                    gap=".5rem"
                    position="relative"
                    sx={{
                        paddingX: "1rem",
                        paddingY: ".5rem",
                        borderRadius: 0,
                        justifyContent: "flex-start",
                        color: "white",
                        width: "100%",
                        fontSize: "1rem",
                        _hover: {
                            backgroundColor: "whiteAlpha.400",
                            transitionDuration: "0.35s",
                        },
                        ...(isActive
                            ? {
                                  bg: `${colorScheme}.400`,
                                  _hover: {
                                      bg: `${colorScheme}.400`,
                                      cursor: "default",
                                  },
                                  _after: {
                                      content: "''",
                                      position: "absolute",
                                      bg: `${colorScheme}.400`,
                                      top: 0,
                                      right: "-10px",
                                      bottom: 0,
                                      width: "10.3px",
                                      clipPath: "polygon(0% 0%, 100% 50%, 0% 100%)",
                                  },
                              }
                            : {}),
                    }}
                >
                    {leftIcon}
                    {text}
                </Flex>
            )}
        </NavLink>
    ) : null;
};
