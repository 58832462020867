import { FC } from "react";
import { Heading, StackProps, VStack } from "@chakra-ui/react";
import { Cell, Label, Pie, PieChart } from "recharts";
import { Shimmer } from "@am-tax/fe-core";

export const PercentPie: FC<
    {
        title: string;
        color?: string;
        targetName?: string;
        otherName?: string;
        isLoading: boolean;
        target: number | undefined;
        total: number | undefined;
    } & StackProps
> = ({ title, color, targetName, otherName, isLoading, target, total, ...stackProps }) => {
    const other = total && target ? total - target : 0;

    return (
        <VStack {...stackProps}>
            <Heading variant={"info"} fontSize={"md"}>
                {title}
            </Heading>
            <Shimmer isLoading={isLoading} boxSize={130} borderRadius={"full"}>
                <PieChart width={130} height={130}>
                    <Pie
                        data={[
                            { name: targetName || "", value: target },
                            {
                                name: otherName || "",
                                value: other || (target ? 0 : 1),
                            },
                        ]}
                        cx={60}
                        cy={60}
                        innerRadius={40}
                        outerRadius={60}
                        dataKey="value"
                    >
                        <Cell fill={color || "var(--chakra-colors-purple-400)"} />
                        <Cell fill={"var(--chakra-colors-gray-50)"} />
                        {total && target && (
                            <Label value={`${total ? Math.round((100 * target) / total) : 0}%`} position="center" style={{ fontSize: "1.5rem" }} />
                        )}
                    </Pie>
                </PieChart>
            </Shimmer>
        </VStack>
    );
};
