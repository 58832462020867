﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { queryKeys } from "../queryKeys";
import { FileContainer } from "@am-tax/fe-core";
import { AxiosError } from "axios";
import { axiosInstance } from "../../utils";

export interface UploadFileRequestArgs {
    clientId: string | undefined;
    entityId: string | undefined;
    candidateId: string;
    requestId: string;
    fileContainer: FileContainer<unknown>;
}

export function useRequestUploadFile() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: UploadFileRequestArgs) => {
            const formData = new FormData();
            //sending the first progress event immediately even though we haven't technically started the upload let's the users know that we are working on uploading this file.
            const initialEvent = new ProgressEvent("upload", { lengthComputable: false });
            formData.append("file", request.fileContainer.file);
            request.fileContainer.callbacks.progress(initialEvent);

            return axios
                .post(
                    `api/clients/${request.clientId}/entities/${request.entityId}/candidates/${request.candidateId}/requests/${request.requestId}/upload`,
                    formData,
                    {
                        onUploadProgress: progressEvent => {
                            request.fileContainer.callbacks.progress({
                                lengthComputable: !!progressEvent.total,
                                loaded: progressEvent.loaded,
                                total: progressEvent.total || 0,
                            });
                        },
                    }
                )
                .then(response => {
                    return response.data;
                });
        },
        onSuccess: async (data, variables) => {
            variables.fileContainer.callbacks.success();
            await queryClient.invalidateQueries(queryKeys.requests.byCandidateId(variables.candidateId).queryKey);
            await queryClient.invalidateQueries(queryKeys.candidates.timelineByCandidateId(variables.candidateId).queryKey);
            await queryClient.invalidateQueries(queryKeys.requests.byCandidateAndRequest(variables.candidateId, variables.requestId).queryKey, data);
        },
        onError: (error, variables) => {
            variables.fileContainer.callbacks.error(null);

            const axiosError = error as AxiosError;
            toast({
                title: "Unable to save document request",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
