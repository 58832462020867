import { createQueryKeyStore } from "@lukemorales/query-key-factory";

export const queryKeys = createQueryKeyStore({
    account: {
        me: null,
    },
    dashboard: {
        byClientId: (clientId: string) => [clientId],
    },
    adminStats: {
        clientsActiveCount: null,
        usersActiveCount: null,
    },
    clients: {
        all: null,
        byId: (clientId: string) => [clientId],
        entities: (clientId: string) => [clientId],
        entityById: (clientId: string, entityId: string) => [clientId, entityId],
        activity: (clientId: string) => [clientId],
    },
    states: {
        all: null,
    },
    candidates: {
        byCandidateId: candidateId => [candidateId],
        timelineByCandidateId: candidateId => [candidateId],
        byEntity: (clientId: string, entityId: string | undefined, recurse: boolean, activeOnly: boolean) => [
            clientId,
            entityId ?? "all",
            recurse ? "recursive" : undefined,
            activeOnly ? "active" : "all",
        ],
    },
    candidateComments: {
        byCandidateId: candidateId => [candidateId],
    },
    candidateQualification: {
        byCandidateId: candidateId => [candidateId],
    },
    requests: {
        byCandidateId: candidateId => [candidateId],
        byCandidateAndRequest: (candidateId, requestId) => [candidateId, requestId],
    },
    permissions: {
        byRole: null,
    },
    candidateStatuses: {
        all: null,
    },
    tenants: {
        all: null,
        deleted: null,
        byId: (tenantId: string | undefined) => [tenantId ?? ""],
    },
    questionnaire: {
        definition: null,
        values: (clientId: string | undefined, entityId: string | undefined, candidateId: string | undefined) => [
            clientId ?? "",
            entityId ?? "",
            candidateId ?? "",
        ],
        url: (clientId: string, entityId: string) => [clientId, entityId],
        qr: (clientId: string, entityId: string) => [clientId, entityId],
    },
    users: {
        all: null,
        byId: (userId: string) => ({
            queryKey: [userId],
            contextQueries: {
                tenants: {
                    queryKey: null,
                },
            },
        }),
    },
});
