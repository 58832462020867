﻿import { axiosInstance } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";
import { CandidateComment, CandidateCommentProps } from "../models/CandidateComment";

export function useCandidateCommentCreate() {
    const axios = axiosInstance();
    const toast = useToast();

    return useMutation({
        mutationFn: (request: CandidateCommentProps) => {
            const data = {
                comment: request.commentBody,
            };
            return axios
                .post<CandidateComment>(`api/Clients/${request.tenantId}/entities/${request.entityId}/candidates/${request.candidateId}/comments`, {
                    ...data,
                })
                .then(response => response.data);
        },
        onSuccess: async (data, variables) => {
            toast({
                title: "Saved",
                description: "Comment saved successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Unable to save comment",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
