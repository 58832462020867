﻿import { createContext, useContext } from "react";
import { EntityResponse } from "../../../../api";

export type EntityTreeContextState = {
    entities: EntityResponse[];
    selectedEntity: EntityResponse | undefined;
    pathToSelected: EntityResponse[];
    isEditable: boolean;
    isLoading: boolean;
    hoveringEntityId: string | undefined;
    // eslint-disable-next-line no-unused-vars
    onMouseEnter: (entity: string) => void;
    onMouseLeave: () => void;
    // eslint-disable-next-line no-unused-vars
    setSelectedEntityId: (entityId: string) => void;
    // eslint-disable-next-line no-unused-vars
    onAdd: (parent?: string) => void;
    onAddClose: () => void;
    isAdding: boolean;
    // eslint-disable-next-line no-unused-vars
    onEdit: (entity: string) => void;
    onEditClose: () => void;
    isEditing: boolean;
    isExpandAll: boolean;
    onExpandAllToggle: () => void;
    onRefetch: () => Promise<void>;
};

const defaultState: EntityTreeContextState = {
    entities: [],
    selectedEntity: undefined,
    pathToSelected: [],
    isEditable: false,
    isLoading: false,
    hoveringEntityId: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMouseEnter: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMouseLeave: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedEntityId: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAdd: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAddClose: () => {},
    isAdding: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onEdit: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onEditClose: () => {},
    isEditing: false,
    isExpandAll: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onExpandAllToggle: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onRefetch: () => new Promise(() => {}),
};

export const EntityTreeContext = createContext<EntityTreeContextState>(defaultState);

export const useEntityTreeContext = () => useContext(EntityTreeContext);
