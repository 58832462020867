﻿import { FunctionComponent } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

import wip from "../../assets/images/work_in_progress.svg";
import bug from "../../assets/images/fixing_bugs.svg";
import ideation from "../../assets/images/ideation.svg";

export const WorkInProgress: FunctionComponent<{ style?: "ideation" | "bug" | "wip" }> = ({ style }) => {
    let imageSrc: string;

    switch (style) {
        case "ideation":
            imageSrc = ideation;
            break;
        case "bug":
            imageSrc = bug;
            break;
        case "wip":
        default:
            imageSrc = wip;
    }

    return (
        <Box textAlign="center" py={10} px={6}>
            <Flex justify="center">
                <Image src={imageSrc} boxSize="450px" mb={16} />
            </Flex>

            <Text fontSize="18px" mt={3} mb={2} textTransform={"uppercase"}>
                Work in Progress
            </Text>
            <Text color={"gray.500"} mb={6}>
                The page you're looking at is still being built
            </Text>
        </Box>
    );
};
