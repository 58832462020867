﻿import { FunctionComponent, ReactNode } from "react";
import { useMatch } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import { UnauthorizedPage } from "../../pages/UnauthorizedPage";
import { Permission, SecurityArea } from "../../auth";
import { useAppPermissions } from "../../hooks/usePermissions";
import { BeatLoader } from "react-spinners";
import { Outlet } from "react-router-dom";

export interface RouteGuardProps {
    securityArea?: SecurityArea;
    hasAny?: Permission[];
    render?: ReactNode;
    renderOutlet?: boolean;
}

const LoadingContent: FunctionComponent = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <BeatLoader color={"var(--chakra-colors-blue-700)"} size={10} />
            <Text fontSize="lg" color="blue.400">
                Route Guard...
            </Text>
        </Box>
    );
};

export const RouteGuard: FunctionComponent<RouteGuardProps> = ({ securityArea, hasAny, render, renderOutlet }) => {
    const clientPageMatch = useMatch("/clients/:cId/*");
    const cId = clientPageMatch?.params?.cId;
    const appPermissions = useAppPermissions(cId);

    if (import.meta.env.VITE_OFFLINE_MODE === "true") {
        return <>{render}</>;
    }

    if (appPermissions.loading) {
        return <LoadingContent />;
    } else if (hasAny && appPermissions.hasAny(...hasAny)) {
        return renderOutlet ? <Outlet /> : <>{render}</>;
    } else if (securityArea && appPermissions.showArea(securityArea)) {
        return renderOutlet ? <Outlet /> : <>{render}</>;
    } else {
        return <UnauthorizedPage />;
    }
};
