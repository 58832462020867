﻿import { FunctionComponent, useState } from "react";
import {
    Box,
    BoxProps,
    Button,
    Center,
    CenterProps,
    HStack,
    Icon,
    IconProps,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    StackProps,
    Text,
    TextProps,
    Tooltip,
    useBoolean,
    VStack,
} from "@chakra-ui/react";
import { ClientResponse, useClients, useMutateClientFavorite } from "../../api";
import { HeadingWithUnderline } from "../../components/HeadingWithLine";
import { IconStar, IconStarFilled, IconStarOff } from "@tabler/icons-react";
import fuzzysort from "fuzzysort";
import { Navigate, useNavigate } from "react-router-dom";
import { SetPageTitle } from "../../components/SetPageTitle";

export const ClientSelectionPage: FunctionComponent<{ fixed?: boolean }> = props => {
    const [filterText, setFilterText] = useState<string>(""),
        onClear = () => {
            setFilterText("");
        };

    const clientsQuery = useClients(),
        clientsData = clientsQuery.data ?? [];

    if (clientsQuery.isLoading || !clientsQuery.data) {
        return <Spinner />;
    }

    if (clientsQuery.data && clientsQuery.data.length === 1) {
        return <Navigate to={`${clientsQuery.data[0].id}`} />;
    }

    if (clientsQuery.data && clientsQuery.data.length === 0) {
        return <span> No clients for you</span>;
    }

    const favoriteClients = clientsData.filter(client => client.isFavorite),
        hasFavorites = favoriteClients.length > 0;

    const filteredClients =
        filterText.length === 0 ? clientsData : fuzzysort.go<ClientResponse>(filterText, clientsData, { keys: ["name", "description"] }).map(t => t.obj);
    const clientFilter = (
        <Box as={InputGroup} size="md" width="20rem">
            <Input
                placeholder="filter items"
                variant="outline"
                pr="4.5rem"
                value={filterText}
                onChange={ev => {
                    setFilterText(ev.currentTarget.value ?? "");
                }}
            />
            <InputRightElement width="4.5rem">
                <Button size="sm" onClick={onClear}>
                    Clear
                </Button>
            </InputRightElement>
        </Box>
    );

    return (
        <>
            <SetPageTitle title={"Select Client"} />
            <VStack align="stretch" gap="4">
                {favoriteClients.length > 0 && (
                    <VStack align="stretch">
                        <HeadingWithUnderline size="md">Favorites</HeadingWithUnderline>
                        {favoriteClients.map((client, ix) => (
                            <ClientListItem key={client.id} client={client} ix={ix} />
                        ))}
                    </VStack>
                )}
                <VStack align="stretch">
                    <HeadingWithUnderline farItem={clientFilter} size="md">
                        {hasFavorites ? "All" : "Clients"}
                    </HeadingWithUnderline>
                    {filteredClients.map((client, ix) => (
                        <ClientListItem key={client.id} client={client} ix={ix} />
                    ))}
                </VStack>
            </VStack>
        </>
    );
};

const ClientListItem: FunctionComponent<{ client: ClientResponse; ix: number }> = props => {
    const { client, ix } = props;
    const navigate = useNavigate();

    const [isMouseIn, setMouseIn] = useBoolean(false);

    const root: StackProps = {
            onMouseEnter: setMouseIn.on,
            onMouseLeave: setMouseIn.off,
            backgroundColor: isMouseIn ? "blue.50" : ix % 2 === 0 ? undefined : "blue.25",
            cursor: "pointer",
            px: 2,
            py: 1,
            onClick: () => navigate(`${client.id}`),
        },
        fav: CenterProps = {
            p: 2,
        },
        clientName: TextProps = {
            fontSize: "md",
            fontWeight: isMouseIn ? "bolder" : undefined,
        },
        clientDesc: TextProps = {
            as: "em",
            fontSize: "sm",
        };

    return (
        <HStack {...root}>
            <Center {...fav}>
                <FavoriteButton client={client} />
            </Center>
            <VStack align="start">
                <Text {...clientName}>{client.name}</Text>
                <Text {...clientDesc}>{client.description ?? "No description"}</Text>
            </VStack>
        </HStack>
    );
};

const FavoriteButton: FunctionComponent<{ client: ClientResponse }> = props => {
    const { client } = props;
    const [isMouseIn, setMouseIn] = useBoolean(false);

    const clientFavorite = useMutateClientFavorite();

    const icon = client.isFavorite
        ? // is favorite
          isMouseIn
            ? // is favorite, is hovering (action is to remove favorite)
              IconStarOff
            : // is favorite, not hovering (showing status)
              IconStarFilled
        : // is not favorite
        isMouseIn
        ? // not favorite, is hovering (action is to add as favorite)
          IconStarFilled
        : // not favorite, not hovering (showing status)
          IconStar;

    const buttonAction = client.isFavorite ? "un-favorite" : "favorite";

    const root: BoxProps = {
            onMouseEnter: setMouseIn.on,
            onMouseLeave: setMouseIn.off,
            borderRadius: "md",
            onClick: event => {
                event.stopPropagation();
                clientFavorite.mutate({ clientId: client.id, action: client.isFavorite ? "remove" : "add" });
            },
        },
        favoriteIcon: IconProps = {
            fontSize: "xl",
            color: isMouseIn ? "blue.500" : "blue.100",
        };
    return (
        <Box {...root}>
            <Tooltip label={`Click to ${buttonAction} this client`}>
                <Icon {...favoriteIcon} as={icon} />
            </Tooltip>
        </Box>
    );
};
