﻿import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import { axiosInstance } from "../utils";

export interface ClientResponse {
    id: string;
    name: string;
    description: string | undefined;
    externalId: string | undefined;
    isFavorite: boolean;
    isInternal: boolean;
    roleId: string;
}

export function useClients() {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.clients.all,
        queryFn: () => axios.get<ClientResponse[]>(`api/Clients`).then(response => response.data),
    });
}

export function useClient(clientId: string | undefined) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.clients.byId(clientId ?? ""),
        enabled: !!clientId,
        queryFn: () => axios.get<ClientResponse>(`api/Clients/${clientId}`).then(response => response.data),
        staleTime: 30000,
    });
}

export function useMutateClientFavorite() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();
    return useMutation({
        mutationFn: (request: { clientId: string; action: "add" | "remove" }) =>
            request.action === "add" ? axios.put(`api/Clients/${request.clientId}/favorite`) : axios.delete(`api/Clients/${request.clientId}/favorite`),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.clients.all.queryKey);
            await queryClient.invalidateQueries(queryKeys.clients.byId(variables.clientId).queryKey);
        },
    });
}
