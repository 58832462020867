import { FC } from "react";
import { useMsal } from "@azure/msal-react";
import { Button, ButtonProps } from "@chakra-ui/react";
import { InteractionStatus } from "@azure/msal-browser";
import { useBoolean } from "../../hooks";
import { BeatLoader } from "react-spinners";
import { useEnvironmentContext } from "@am-tax/fe-core";

export type SignInButtonProps = {
    onError?: (message: string) => void;
    text?: string;
} & Omit<ButtonProps, "onClick" | "isLoading" | "spinner" | "onError">;

export const SignInButton: FC<SignInButtonProps> = ({ onError, text, ...buttonProps }) => {
    const { instance, inProgress } = useMsal();
    const { authScopes } = useEnvironmentContext(),
        loginRequest = { scopes: authScopes };

    const [isErrored, { setTrue: setError, setFalse: clearError }] = useBoolean(false);

    const handleLogin = () => {
        instance
            .loginRedirect(loginRequest)
            .then(clearError)
            .catch(e => {
                setError();
                if (onError) {
                    onError(e.errorMessage);
                }

                console.log("handleLoginError", { ...e }, e.errorMessage);
            });
    };

    const signInButton: ButtonProps = {
        ...buttonProps,
        colorScheme: !isErrored ? buttonProps.colorScheme ?? "blue" : "red",
        isLoading: inProgress !== InteractionStatus.None,
        spinner: <BeatLoader color="white" size={8} />,
    };

    return (
        <Button {...signInButton} onClick={handleLogin}>
            {text ?? "Sign in"}
        </Button>
    );
};
