﻿import { FunctionComponent } from "react";
import { SetPageTitle } from "../../components/SetPageTitle";
import { Box, Card, CardBody, Flex, Heading, HStack, VStack } from "@chakra-ui/react";
import { CreditsWidget } from "./CreditsWidget";
import { ApplicationStatusWidget } from "./ApplicationStatusWidget";
import { PercentPie } from "./PercentPie";
import { useDashboard } from "../../api/hooks";
import { useParams } from "react-router-dom";

export const ClientDashboardPage: FunctionComponent = () => {
    const { cId } = useParams();
    const dashboardQuery = useDashboard(cId);
    const dashboardLoading = dashboardQuery.isLoading;

    const dashboard = dashboardQuery.data;
    const screens = dashboard?.screens;
    const submissions = dashboard?.submissions;
    const credits = dashboard?.credits;

    return (
        <>
            <SetPageTitle title={"Dashboard"} />
            <Flex flexDirection={"column"} flexGrow={1} gap={"3rem"} width={"100%"}>
                <Card variant={"withShadow"}>
                    <CardBody>
                        <VStack>
                            <Heading as={"h2"} color={"blackAlpha.800"} fontWeight={"bold"} fontSize={"lg"} alignItems={"center"}>
                                Success Rates (YTD)
                            </Heading>
                            <HStack width={"full"} justifyContent={"space-around"}>
                                <PercentPie
                                    isLoading={dashboardLoading}
                                    color={"var(--chakra-colors-blue-800)"}
                                    target={screens?.screened}
                                    total={screens?.total}
                                    title={"Screened"}
                                />
                                <PercentPie
                                    isLoading={dashboardLoading}
                                    color={"var(--chakra-colors-cyan-500)"}
                                    target={screens?.eligible}
                                    total={screens?.total}
                                    title={"Eligible"}
                                />
                                <PercentPie
                                    isLoading={dashboardLoading}
                                    color={"var(--chakra-colors-green-500)"}
                                    target={screens?.hired}
                                    total={screens?.total}
                                    title={"Eligible Hired"}
                                />
                            </HStack>
                        </VStack>
                    </CardBody>
                </Card>
                <Flex gap={"3rem"}>
                    <Box flexGrow={1}>
                        <ApplicationStatusWidget isLoading={dashboardLoading} submissions={submissions} />
                    </Box>
                    <Box flexGrow={1}>
                        <CreditsWidget isLoading={dashboardLoading} credits={credits} />
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};
