﻿import { FunctionComponent } from "react";
import { Question, RenderInput, showCheckmark, showQuestion, showXmark, useSaveQuestionnaireValue } from "../../common";
import { Box, FormControl, FormErrorMessage, Td, Text, Tr } from "@chakra-ui/react";
import { questionnaireValuesAtom } from "../../atoms";
import { useAtom } from "jotai";
import { useQuestionnaireSaveValues } from "../../api";
import { useParams } from "react-router-dom";
import "./questionnaire.css";

export const SectionContent: FunctionComponent<{ questions: Question[]; editMode: boolean }> = ({ questions, editMode }) => {
    const [questionnaireValues, setQuestionnaireValues] = useAtom(questionnaireValuesAtom);
    const { cId, eId, candidateId } = useParams();

    const saveQuestionnaireValuesQuery = useQuestionnaireSaveValues(cId, eId, candidateId);

    const saveValue = useSaveQuestionnaireValue(questionnaireValues, setQuestionnaireValues, saveQuestionnaireValuesQuery);

    return (
        <>
            {questions.map(question => {
                const syncableValue = questionnaireValues[question.id];
                const value = syncableValue?.value;
                const show = showQuestion(question, questionnaireValues);
                return (
                    <Tr key={question.id} _hover={{ background: "blackAlpha.50" }}>
                        <Td width={"1.5rem"}></Td>
                        <Td>
                            {!!question.label && (
                                <Text variant={"label"} color={show ? "" : "gray.200"}>
                                    {question.label}
                                </Text>
                            )}
                            <Text color={show ? "" : "gray.200"}>{question.prompt}</Text>
                            {editMode && show && (
                                <Box mt={"1rem"}>
                                    <FormControl isInvalid={!!syncableValue?.errorMessage}>
                                        <RenderInput
                                            question={question}
                                            value={syncableValue}
                                            defaultOptionPlaceholder={"Choose an option"}
                                            onChange={(newValue: string) => saveValue(question, syncableValue, newValue)}
                                        />
                                        <FormErrorMessage>{syncableValue?.errorMessage}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Td>
                        <Td textAlign={"end"} className={showCheckmark(syncableValue) ? "check-mark" : showXmark(syncableValue) ? "x-mark" : ""}>
                            {show && !value ? (
                                <Text color={"red.500"}>unanswered</Text>
                            ) : show && value ? (
                                <Text fontWeight={"bold"}>{syncableValue.value}</Text>
                            ) : (
                                <Text color={"gray.200"}>N/A</Text>
                            )}
                        </Td>
                    </Tr>
                );
            })}
        </>
    );
};
