﻿import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import { TenantRole, UserResponse, UserTenantResponse } from "./models";
import { axiosInstance } from "../utils";

export function useUsers() {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.users.all,
        queryFn: () => axios.get<UserResponse[]>(`api/Users`).then(response => response.data),
    });
}

export function useUser(userId: string) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.users.byId(userId),
        queryFn: () => axios.get<UserResponse>(`api/Users/${userId}`).then(response => response.data),
    });
}

export function useUserTenants(userId: string) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.users.byId(userId)._ctx.tenants,
        queryFn: () => axios.get<UserTenantResponse[]>(`api/Users/${userId}/tenants`).then(response => response.data),
    });
}

export function useMutateUserTenantRole(userId: string, tenantId: string) {
    const axios = axiosInstance(),
        queryClient = useQueryClient();
    return useMutation({
        mutationFn: (role: TenantRole) =>
            axios
                .post<UserTenantResponse>(`api/Tenants/${tenantId}/UserMappings`, {
                    userId,
                    role,
                    expiresOn: undefined,
                })
                .then(response => response.data),
        onSuccess: data => {
            queryClient.setQueryData(queryKeys.users.byId(userId)._ctx.tenants.queryKey, (oldData: UserTenantResponse[] | undefined) => {
                console.log("oldData", oldData);
                if (oldData) {
                    return oldData.map(t => (t.tenantId === data.tenantId && t.userId === data.userId ? data : t));
                }

                return oldData;
            });
        },
    });
}
