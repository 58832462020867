import { FC } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { CandidateDetailsForm } from "./CandidateDetailsForm";
import { useCandidate } from "../../api/hooks/useCandidate";

interface PageWithModalProps {
    returnTo: string;
}

export const CandidateDetailsModal: FC<PageWithModalProps> = ({ returnTo }) => {
    const navigate = useNavigate();
    const { cId, eId, candidateId } = useParams();
    const candidateQuery = useCandidate(cId, eId, candidateId);
    const candidate = candidateQuery.data;

    // ---- Navigation Helper ----
    const goBackToAllRequests = () => {
        navigate(`/clients/${cId}/candidates/${eId}/${candidateId}/${returnTo}`);
    };

    return (
        <Modal
            isOpen={true}
            onClose={() => {
                goBackToAllRequests();
            }}
            size={"4xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{`${candidate?.id ? "Edit" : "Add"} Candidate`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={"1.5rem"}>
                    <CandidateDetailsForm returnTo={returnTo} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
