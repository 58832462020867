﻿import { useState } from "react";
import { useConst } from "./useConst";

export interface IUseBooleanCallbacks {
    setTrue: () => void;
    setFalse: () => void;
    toggle: () => void;
}

export function useBoolean(initialState: boolean): [boolean, IUseBooleanCallbacks] {
    const [value, setValue] = useState(initialState);

    const setTrue = useConst(() => () => {
            setValue(true);
        }),
        setFalse = useConst(() => () => {
            setValue(false);
        }),
        toggle = useConst(() => () => {
            setValue(currentValue => !currentValue);
        });

    return [value, { setTrue, setFalse, toggle }];
}
