﻿import { createContext, useContext } from "react";
import { defaultState } from "./defaultState";

export interface IUserContext {
    dateFormat: string;
    id: string;
    name: string;
    timeFormat: string;
}

export const UserContext = createContext<IUserContext>(defaultState);

export const useUserContext = () => useContext(UserContext);
