﻿import { Button, Table, TableCaption, TableContainer, Tbody, Td, Tr, useDisclosure, UseDisclosureProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { IconChevronsDown, IconChevronsUp } from "@tabler/icons-react";

type CollapsibleDetailsTableProps = {
    items: { key: string; value: string | undefined }[];
    caption: string;
} & Pick<UseDisclosureProps, "defaultIsOpen">;
export const CollapsibleDetailsTable: FunctionComponent<CollapsibleDetailsTableProps> = props => {
    const { items, caption, ...disclosureProps } = props;
    const accordion = useDisclosure(disclosureProps);

    return (
        <TableContainer>
            <Table variant="simple">
                <TableCaption>
                    <Button
                        variant="ghost"
                        rightIcon={accordion.isOpen ? <IconChevronsUp size="1rem" /> : <IconChevronsDown size="1rem" />}
                        onClick={accordion.onToggle}
                    >
                        {caption}
                    </Button>
                </TableCaption>
                <Tbody {...accordion.getDisclosureProps()}>
                    {items.map(kvp => (
                        <Tr key={kvp.key}>
                            <Td>{kvp.key}</Td>
                            <Td>{kvp.value}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};
