﻿import { queryKeys } from "../queryKeys";
import { useQuery } from "@tanstack/react-query";
import { CandidateActivity } from "../models/EntityCandidate";
import { axiosInstance } from "../../utils";

export function useCandidateTimeline(clientId: string | undefined, entityId: string | undefined, candidateId: string | undefined) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.candidates.timelineByCandidateId(candidateId),
        queryFn: () => {
            if (candidateId) {
                return axios
                    .get<CandidateActivity[]>(`api/clients/${clientId}/entities/${entityId}/candidates/${candidateId}/timeline`)
                    .then(response => response.data);
            } else {
                return null;
            }
        },
    });
}
