﻿import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { EntityResponse } from "../models";
import { axiosInstance } from "../../utils";

export function useClientEntities(clientId: string) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.clients.entities(clientId),
        queryFn: () => axios.get<EntityResponse[]>(`api/Clients/${clientId}/entities`).then(response => response.data),
    });
}
