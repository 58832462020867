﻿import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { axiosInstance } from "../../utils";

export function useCandidateQr(clientId: string, entityId: string) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.questionnaire.qr(clientId, entityId),
        queryFn: () =>
            axios.get<ArrayBuffer>(`api/clients/${clientId}/entities/${entityId}/candidateqr`, { responseType: "arraybuffer" }).then(response => response.data),
    });
}
