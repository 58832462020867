﻿import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { EntityResponse, useClientEntities } from "../../api";
import { Button, ButtonGroup, Card, CardHeader, Divider, Heading, HStack, Icon, IconButton, Spacer, Spinner, useDisclosure, VStack } from "@chakra-ui/react";
import { EntityTree } from "./components/EntityTree";
import { IconArrowsMoveVertical, IconEdit, IconFold, IconPlus } from "@tabler/icons-react";
import { EntityTreeContextProvider, useEntityTreeContext } from "./components/EntityTreeContext";
import { SetPageTitle } from "../../components/SetPageTitle";
import { EditEntityDrawer } from "./components/EntityEditorDrawer";
import { EntityAddDialog } from "./components/EntityAddDialog/EntityAddDialog";
import { QrWidget } from "./components/QrWidget";
import { useAreaPermissions } from "../../hooks";
import { EntitiesArea } from "../../auth";

export const ClientEntitiesPage: FunctionComponent = () => {
    const { cId } = useParams<keyof { cId: string }>();

    return (
        <>
            <SetPageTitle title={"Client Entities Page"} />
            {cId && <EntityManagerWrapper clientId={cId} />}
        </>
    );
};

const EntityManagerWrapper: FunctionComponent<{ clientId: string }> = ({ clientId }) => {
    const entities = useClientEntities(clientId),
        expanded = useDisclosure();

    return (
        <EntityTreeContextProvider
            entities={entities.data || []}
            isEditable={true}
            isLoading={entities.isInitialLoading}
            isExpanded={expanded.isOpen}
            onExpandToggle={expanded.onToggle}
            onRefetch={async () => {
                await entities.refetch();
            }}
        >
            {entities.data ? <EntityManager clientId={clientId} entities={entities.data} /> : <Spinner />}
        </EntityTreeContextProvider>
    );
};

const EntityManager: FunctionComponent<{ clientId: string; entities: EntityResponse[] }> = ({ clientId }) => {
    const { onAdd, onExpandAllToggle, isExpandAll } = useEntityTreeContext();
    const { selectedEntity, isEditing, onEdit, onEditClose, isAdding, onAddClose } = useEntityTreeContext();
    const areaPermissions = useAreaPermissions(EntitiesArea, clientId);

    return (
        <>
            <SetPageTitle title={"Manage Entities"} />

            <HStack spacing={"1rem"} align={"start"} divider={<Divider orientation={"vertical"} />}>
                <Card variant={"withShadow"} width={"100%"}>
                    <CardHeader bgGradient="linear(to-r, gray.500, gray.100)"></CardHeader>
                    <HStack p={"1rem"} align={"stretch"} spacing={"1rem"} height={"100%"}>
                        <VStack gap="2rem" align="start" borderRight={"solid 1px var(--chakra-colors-chakra-border-color)"} pr={"2rem"}>
                            <HStack spacing={"2rem"}>
                                <Heading m={0}>Entity Tree</Heading>
                                <ButtonGroup isAttached>
                                    <IconButton
                                        onClick={onExpandAllToggle}
                                        aria-label={"collapse all"}
                                        isDisabled={isExpandAll}
                                        icon={<Icon as={IconArrowsMoveVertical} />}
                                        size={"sm"}
                                    />
                                    <IconButton
                                        onClick={onExpandAllToggle}
                                        aria-label={"expand all"}
                                        isDisabled={!isExpandAll}
                                        icon={<Icon as={IconFold} />}
                                        size={"sm"}
                                    />
                                </ButtonGroup>
                            </HStack>
                            <VStack gap="0" align="start">
                                <Button variant={"ghost"} onClick={() => onAdd()} rightIcon={<Icon as={IconPlus} />} fontWeight={"normal"}>
                                    Add to Root
                                </Button>

                                <EntityTree />
                            </VStack>
                        </VStack>
                        {selectedEntity && (
                            <>
                                <Spacer />
                                <VStack spacing={"2rem"}>
                                    <HStack width={"100%"} align={"center"} spacing={"2rem"}>
                                        <Heading mb={0}>{selectedEntity?.name}</Heading>
                                        {areaPermissions.hasAny(EntitiesArea.update, EntitiesArea.delete) && (
                                            <IconButton icon={<Icon as={IconEdit} />} onClick={() => onEdit(selectedEntity?.id)} aria-label={"edit entity"} />
                                        )}
                                    </HStack>
                                    {selectedEntity?.entityType === "Leaf" && <QrWidget clientId={clientId} entityId={selectedEntity.id} />}
                                </VStack>
                                <Spacer />
                            </>
                        )}
                    </HStack>
                </Card>
            </HStack>
            {selectedEntity && <EditEntityDrawer isOpen={isEditing} clientId={clientId} entityId={selectedEntity.id} onClose={onEditClose} />}

            <EntityAddDialog isOpen={isAdding} clientId={clientId} onClose={onAddClose} selectedEntity={selectedEntity} />
        </>
    );
};
