﻿import { queryKeys } from "../queryKeys";
import { useQuery } from "@tanstack/react-query";
import { DashboardResponse } from "../models/DashboardResponse";
import { axiosInstance } from "../../utils";

export function useDashboard(clientId: string | undefined) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.dashboard.byClientId(clientId || ""),
        enabled: !!clientId,
        queryFn: () => {
            return axios.get<DashboardResponse>(`api/clients/${clientId}/dashboard`).then(response => response.data);
        },
    });
}
