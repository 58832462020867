﻿import { ICellRendererParams } from "@ag-grid-community/core";
import { HStack, Text } from "@chakra-ui/react";
import { CandidateStatus } from "../../../../api/models/EntityCandidate";
import { CandidateStatusIcon, getCandidateStatusTitle } from "../../../../components/CandidateStatus";

export const CandidateStatusCellRenderer = (props: ICellRendererParams<{ status: CandidateStatus }, CandidateStatus>): JSX.Element => {
    return props.value ? (
        <HStack>
            <CandidateStatusIcon status={props.value} />
            <Text>{getCandidateStatusTitle(props.value)}</Text>
        </HStack>
    ) : (
        <span />
    );
};
