﻿import { FunctionComponent, useEffect, useState } from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Checkbox,
    FormControl,
    FormLabel,
    Heading,
    Flex,
    Button,
    Input,
    Select,
} from "@chakra-ui/react";
import { CandidateQualification, DesignatedZoneType, displayQualification, displayZone, EntityCandidateQualification } from "../../api/models/EntityCandidate";
import { useCandidateQualifications } from "../../api/hooks/useCandidateQualifications";
import { Controller, useForm } from "react-hook-form";
import { CreateEntityCandidateQualificationRequest, useCandidateQualificationCreate } from "../../api/hooks/useCandidateQualificationCreate";
import { UpdateEntityCandidateQualificationRequest, useCandidateQualificationUpdate } from "../../api/hooks/useCandidateQualificationUpdate";
import { useCandidateQualificationDelete } from "../../api/hooks/useCandidateQualificationDelete";
import {
    CommentDictionary,
    DesignatedZoneNameDictionary,
    DesignatedZoneTypeDictionary,
    QualificationCheckboxArgs,
} from "../../api/models/EntityCandidateQualification";

export interface CandidateQualificationArgs {
    zoneType: string | undefined;
    zoneName: string | undefined;
    comment: string | undefined;
    id: string | undefined;
    qualification: string | undefined;
    passCommentsHandler: (data: CommentDictionary[]) => void;
    passZoneTypeHandler: (data: DesignatedZoneTypeDictionary) => void;
    passZoneNameHandler: (data: DesignatedZoneNameDictionary) => void;
}

export const QualificationCheckboxes: FunctionComponent<QualificationCheckboxArgs> = ({ ...QualificationCheckboxArgs }) => {
    const { control, register } = useForm();

    //Get all qualifications for a candidate
    const qualificationQuery = useCandidateQualifications(
        QualificationCheckboxArgs.candidateId,
        QualificationCheckboxArgs.tenantId,
        QualificationCheckboxArgs.entityId
    );

    const [selectedQualifications, setSelectedQualifications] = useState<EntityCandidateQualification[]>([]);
    const [comments, setComment] = useState<CommentDictionary[]>([]);
    const [designatedZoneType, setDesignatedZoneType] = useState<DesignatedZoneTypeDictionary>({ id: "", zoneType: "" });
    const [designatedZoneName, setDesignatedZoneName] = useState<DesignatedZoneNameDictionary>({ id: "", zoneName: "" });
    const [qualificationsToDelete, setQualificationsToDelete] = useState<EntityCandidateQualification[]>([]);
    const getCommentsFromChild = (commentDict: CommentDictionary[]) => {
        const commentsMap = Object.fromEntries(comments.map(dictionary => [dictionary.data.qualification, dictionary]));
        commentDict.map(dictionary => {
            commentsMap[dictionary.data.qualification ?? ""] = {
                id: dictionary.id,
                data: { comment: dictionary.data.comment, qualification: dictionary.data.qualification },
            };
        });
        const newCommentDictionary: CommentDictionary[] = Object.values(commentsMap);

        const commentsDictionaryResult = [...newCommentDictionary];
        setComment(commentsDictionaryResult);
    };
    const getZoneTypeFromChild = (zoneType: DesignatedZoneTypeDictionary) => {
        setDesignatedZoneType(zoneType);
    };
    const getZoneNameFromChild = (zoneName: DesignatedZoneNameDictionary) => {
        setDesignatedZoneName(zoneName);
    };

    useEffect(() => {
        if (qualificationQuery.isFetched) {
            setSelectedQualifications(qualificationQuery.data ?? []);
        }
    }, [qualificationQuery.isFetched]);

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        const qIx = selectedQualifications.findIndex(m => m.value === value),
            existingQualification = qIx >= 0 ? selectedQualifications[qIx] : null;

        if (existingQualification) {
            //Qualification marked for deletion
            const qualificationRemoved = qualificationQuery.data?.find(qualification => qualification.value === existingQualification.value);
            if (qualificationRemoved) {
                const map = Object.fromEntries(qualificationsToDelete.map(qualification => [qualification.value, qualification]));
                map[qualificationRemoved?.value] = qualificationRemoved;
                const newQualificationsToDelete: EntityCandidateQualification[] = Object.values(map);

                setQualificationsToDelete([...newQualificationsToDelete]);
            }

            /* Qualification to be removed from selected qualifications:
             * Note: Cannot perform splice on the original array; The new selectedQualifications[] must be different
             * from the original selectedQualifications[] in order to trigger a re-render:
             */
            const selectedQualificationsCopy = selectedQualifications.slice();
            selectedQualificationsCopy.splice(qIx, 1);
            setSelectedQualifications(selectedQualificationsCopy);
        } else {
            // add qualification
            setSelectedQualifications(p => {
                return [
                    ...p,
                    {
                        id: "",
                        value: value as CandidateQualification,
                        comment: "",
                        createdOn: "",
                        createdById: "",
                        deletedOn: undefined,
                        deletedById: undefined,
                        designatedZoneName: "",
                        designatedZoneType: "",
                        isManuallyAdded: true,
                    },
                ];
            });
        }
    };

    const saveCandidateQualification = useCandidateQualificationCreate();
    const updateCandidateQualification = useCandidateQualificationUpdate();
    const deleteCandidateQualification = useCandidateQualificationDelete();

    const updateCandidateQualifications = async () => {
        const qualificationsToCreate = selectedQualifications
            .filter(m => m.id === "")
            .map<CreateEntityCandidateQualificationRequest>(q => ({
                tenantId: QualificationCheckboxArgs.tenantId,
                entityId: QualificationCheckboxArgs.entityId,
                candidateId: QualificationCheckboxArgs.candidateId,
                comment: comments.find(commentDict => commentDict.id == q.id && commentDict.data.qualification == q.value)?.data.comment,
                designatedZoneName: designatedZoneName.id == q.id ? designatedZoneName.zoneName : undefined,
                designatedZoneType: designatedZoneType.id == q.id ? (designatedZoneType.zoneType as DesignatedZoneType) : undefined,
                isManuallyAdded: true,
                qualification: q.value,
                id: q.id,
            }));

        for (const qualification of qualificationsToCreate) {
            await saveCandidateQualification.mutateAsync(qualification, {});
        }

        const qualificationsToUpdate = selectedQualifications
            .filter(m => m.id !== "")
            .map<UpdateEntityCandidateQualificationRequest>(q => ({
                tenantId: QualificationCheckboxArgs.tenantId,
                entityId: QualificationCheckboxArgs.entityId,
                candidateId: QualificationCheckboxArgs.candidateId,
                comment: comments.find(dictionary => dictionary.id == q.id && dictionary.data.qualification == q.value)?.data.comment,
                designatedZoneName: designatedZoneName.id == q.id ? designatedZoneName.zoneName : undefined,
                designatedZoneType: designatedZoneType.id == q.id ? (designatedZoneType.zoneType as DesignatedZoneType) : undefined,
                isManuallyAdded: true,
                qualification: q.value,
                id: q.id,
            }));

        for (const qualification of qualificationsToUpdate) {
            await updateCandidateQualification.mutateAsync(qualification, {});
        }

        // Qualifications to delete
        for (const qualification of qualificationsToDelete) {
            await deleteCandidateQualification.mutateAsync(
                {
                    id: qualification.id,
                    tenantId: QualificationCheckboxArgs.tenantId,
                    entityId: QualificationCheckboxArgs.entityId,
                    candidateId: QualificationCheckboxArgs.candidateId,
                },
                {}
            );
        }
    };

    return (
        <>
            {Object.values(CandidateQualification).map((q: CandidateQualification, qIx) => {
                const qualificationMatch = selectedQualifications.find(m => m.value === q);
                const isChecked = selectedQualifications.map(m => m.value).includes(q);
                return (
                    <div key={qIx}>
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <FormControl {...register("qualificationCheckboxes")}>
                                    <FormLabel></FormLabel>
                                    <Checkbox mb={"1em"} id={qualificationMatch?.id} onChange={handleCheck} isChecked={isChecked} value={q}>
                                        {displayQualification(q)}
                                    </Checkbox>
                                </FormControl>
                            )}
                            name={"qualificationCheckboxes"}
                        />
                        {qualificationMatch && (
                            <Accordion key={qualificationMatch ? "open" : "closed"} defaultIndex={qualificationMatch ? [0] : undefined} allowToggle>
                                <AccordionItem>
                                    <Heading as="h5" size="sm">
                                        <AccordionButton>
                                            Qualification Details
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </Heading>
                                    <AccordionPanel>
                                        <CandidateQualificationChild
                                            id={qualificationMatch?.id}
                                            zoneType={qualificationMatch?.designatedZoneType}
                                            zoneName={qualificationMatch?.designatedZoneName}
                                            comment={qualificationMatch?.comment}
                                            qualification={q}
                                            passCommentsHandler={getCommentsFromChild}
                                            passZoneTypeHandler={getZoneTypeFromChild}
                                            passZoneNameHandler={getZoneNameFromChild}
                                        />
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        )}
                    </div>
                );
            })}
            <Flex justifyContent="flex-end" width={"full"}>
                <Button
                    variant={"primary"}
                    mr={3}
                    isLoading={saveCandidateQualification.isLoading}
                    isDisabled={saveCandidateQualification.isLoading}
                    loadingText={"Saving"}
                    onClick={updateCandidateQualifications}
                >
                    Save
                </Button>
                <Button variant="ghost">Cancel</Button>
            </Flex>
        </>
    );
};

export const CandidateQualificationChild: FunctionComponent<CandidateQualificationArgs> = ({ ...CandidateQualificationArgs }, qualification: string) => {
    const [commentDictionaries, setCommentDictionary] = useState<CommentDictionary[]>([
        {
            id: CandidateQualificationArgs.id ?? "",
            data: { comment: CandidateQualificationArgs.comment, qualification: CandidateQualificationArgs.qualification },
        },
    ]);
    const [designatedZoneType, setDesignatedZoneType] = useState<DesignatedZoneTypeDictionary>({
        id: CandidateQualificationArgs.id,
        zoneType: (CandidateQualificationArgs.zoneType as DesignatedZoneType) ?? undefined,
    });
    const [designatedZoneName, setDesignatedZoneName] = useState<DesignatedZoneNameDictionary>({
        id: CandidateQualificationArgs.id,
        zoneName: CandidateQualificationArgs.zoneName,
    });

    useEffect(() => {
        // ---- Pass data to the parent component  ---- //
        CandidateQualificationArgs.passCommentsHandler(commentDictionaries);
        CandidateQualificationArgs.passZoneNameHandler(designatedZoneName);
        CandidateQualificationArgs.passZoneTypeHandler(designatedZoneType);
    }, [commentDictionaries, designatedZoneName, designatedZoneType]);

    const onChangeCommentInput = (e: React.ChangeEvent<HTMLInputElement>, commentQualification: string) => {
        const map = Object.fromEntries(commentDictionaries.map(dict => [dict.data.qualification, dict]));
        map[commentQualification] = { id: e.target.id, data: { comment: e.target.value, qualification: commentQualification } };
        const newCommentDictionary: CommentDictionary[] = Object.values(map);

        setCommentDictionary([...newCommentDictionary]);
    };

    return (
        <FormControl>
            {CandidateQualificationArgs.qualification == "DECR" ? (
                <>
                    <Select
                        id={CandidateQualificationArgs.id}
                        mb={"0.5em"}
                        placeholder={"Choose a Designated Zone Type..."}
                        value={designatedZoneType.id === CandidateQualificationArgs.id ? designatedZoneType.zoneType : ""}
                        onChange={e => setDesignatedZoneType({ id: e.target.id, zoneType: e.target.value ?? undefined })}
                    >
                        {Object.values(DesignatedZoneType).map((zone: DesignatedZoneType) => (
                            <option value={zone} key={zone}>
                                {displayZone(zone)}
                            </option>
                        ))}
                    </Select>

                    <Input
                        id={CandidateQualificationArgs.id}
                        mb={"0.5em"}
                        value={designatedZoneName.id === CandidateQualificationArgs.id ? designatedZoneName.zoneName : ""}
                        onChange={e => setDesignatedZoneName({ id: e.target.id, zoneName: e.target.value })}
                        placeholder={"Designated Zone Name"}
                    ></Input>
                </>
            ) : (
                <></>
            )}
            <Input
                mb={"0.5em"}
                id={CandidateQualificationArgs.id}
                value={commentDictionaries?.find(x => x.id === CandidateQualificationArgs.id)?.data.comment ?? ""}
                onChange={e => onChangeCommentInput(e, CandidateQualificationArgs.qualification ?? "")}
                placeholder={"Enter your comment here"}
            ></Input>
        </FormControl>
    );
};
