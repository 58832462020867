﻿import { Fragment, FunctionComponent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCandidate } from "../../api/hooks/useCandidate";
import { Button, Card, CardBody, Flex, Heading, HStack, Icon, IconButton, Table, TableContainer, Tbody, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { IconEdit, IconEditOff, IconRestore } from "@tabler/icons-react";
import { ConfirmationDialog, ConfirmationDialogProps } from "../../components/ConfirmationDialog";
import { useQuestionnaireReset, useQuestionnaireDefinition, useQuestionnaireValuesQueryFn } from "../../api";
import { Question, Section, SyncableValue } from "../../common";
import { SectionContent } from "./SectionContent";
import { questionnaireValuesAtom } from "../../atoms";
import { useSetAtom } from "jotai";

type QuestionGroup = {
    section: Section;
    questions: Question[];
};

export const CandidateQuestionnaire: FunctionComponent = () => {
    const { cId, eId, candidateId } = useParams();
    const [editMode, setEditMode] = useState<boolean>(false);

    const candidateQuery = useCandidate(cId, eId, candidateId);
    const candidate = candidateQuery.data;

    const questionnaireReset = useQuestionnaireReset();

    const questionnaireDefinitionQuery = useQuestionnaireDefinition();
    const questionnaireDefinition = questionnaireDefinitionQuery.data;
    const getQuestionnaireValues = useQuestionnaireValuesQueryFn();

    const setQuestionnaireValues = useSetAtom(questionnaireValuesAtom);

    useEffect(() => {
        getQuestionnaireValues(cId, eId, candidateId).then(response => {
            const values: Record<string, SyncableValue> = {};
            for (const key in response) {
                values[key] = { value: response[key] };
            }
            setQuestionnaireValues(values);
        });
    }, [cId, candidateId, eId, editMode, getQuestionnaireValues, setQuestionnaireValues]); // I only want this to run when the edit mode changes

    const questionGroups: QuestionGroup[] = useMemo(() => {
        return (
            questionnaireDefinition?.sections.map(section => ({
                section,
                questions: questionnaireDefinition.questions.filter(question => question.sectionId === section.id),
            })) || []
        );
    }, [questionnaireDefinition]);

    const resetDialog = useDisclosure(),
        confirmationDialog: ConfirmationDialogProps = {
            isOpen: resetDialog.isOpen,
            onClose: resetDialog.onClose,
            title: "Reset Questionnaire",
            confirmLabel: "Reset",
            isDisabled: questionnaireReset.isLoading,
            onConfirm: () => {
                if (candidateId) {
                    resetDialog.onClose();
                    questionnaireReset.mutateAsync({ candidateId, cId, eId });
                }
            },
        };

    const resetQuestionnaire = async () => {
        resetDialog.onOpen();
    };

    return (
        <Card variant={"withShadow"}>
            <CardBody p={"2rem"}>
                <Flex direction={"column"} gap={"2rem"}>
                    <Flex justify={"space-between"}>
                        <Heading variant={"cardHeading"}>Questionnaire</Heading>
                        <HStack>
                            <Button
                                onClick={resetQuestionnaire}
                                fontWeight="regular"
                                isLoading={questionnaireReset.isLoading}
                                leftIcon={<Icon as={IconRestore} />}
                                isDisabled={!!candidate?.form8850Id}
                            >
                                Reset Questionnaire
                            </Button>
                            <IconButton
                                icon={<Icon as={editMode ? IconEditOff : IconEdit} />}
                                aria-label={"Edit Questionnaire"}
                                onClick={() => {
                                    setEditMode(!editMode);
                                }}
                                isDisabled={!!candidate?.form8850Id}
                            />
                        </HStack>
                    </Flex>

                    <form onSubmit={e => e.preventDefault()}>
                        <fieldset>
                            <TableContainer whiteSpace={"unset"}>
                                <Table variant={"unstyled"}>
                                    <Tbody>
                                        {questionGroups.map((questionGroup, i) => (
                                            <Fragment key={i}>
                                                <Tr>
                                                    <Td colSpan={3}>
                                                        <Heading variant={"info"} mt={"1rem"}>
                                                            {questionGroup.section.name}
                                                        </Heading>
                                                    </Td>
                                                </Tr>
                                                <SectionContent questions={questionGroup.questions} editMode={editMode} />
                                            </Fragment>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </fieldset>
                    </form>
                    <ConfirmationDialog {...confirmationDialog}>
                        <p>This will reset all the questionnaire responses.</p>
                        <br />
                        <p>Are you sure you want to continue?</p>
                    </ConfirmationDialog>

                    {/*<Grid templateColumns="0 auto auto" gap={"1.5rem"}>
                        {questionGroups.map((questionGroup, i) => (
                            <Fragment key={i}>
                                <GridItem colSpan={3}>
                                    <Heading variant={"info"} mt={"1rem"}>
                                        {questionGroup.section.name}
                                    </Heading>
                                </GridItem>
                                <SectionQuestions questions={questionGroup.questions} editMode={editMode} />
                            </Fragment>
                        ))}
                    </Grid>*/}
                </Flex>
            </CardBody>
        </Card>
    );
};
