﻿import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NotFound } from "../components/NotFound";
import { AuthorizationCheck } from "../components/AuthorizationCheck";
import { AdminDashboardPage } from "./AdminDashboardPage";
import { AdminUsersPage } from "./AdminUsersPage";
import { AdminClientsPage } from "./AdminClientsPage";
// import { AdminReportsPage } from "./AdminReportsPage";
// import { AdminSystemPage } from "./AdminSystemPage";
import { ClientSelectionPage } from "./ClientSelectionPage";
import { ProfilePage } from "./ProfilePage";
import { UnauthorizedPage } from "./UnauthorizedPage";
import { ClientDashboardPage } from "./ClientDashboardPage";
import { ClientCandidatesPage } from "./ClientCandidatesPage";
import { CandidatePage } from "./CandidatePage";
import { ClientEntitiesPage } from "./ClientEntitiesPage";
// import { ClientSettingsPage } from "./ClientSettingsPage";
// import { ClientReportsPage } from "./ClientReportsPage";
// import { ClientActivityPage } from "./ClientActivityPage";
import { RequestModal, RequestPage } from "./RequestPage";
import { PageWithModal } from "../components/PageWithModal";
import { CandidateDetailsModal } from "./CandidateDetailsPage";
import { RouteGuard } from "../components/RouteGuard";
import { CandidatesArea, DashboardArea, EntitiesArea } from "../auth";
import { ApplicationRole } from "../api";

export const PageRoutes: FunctionComponent = () => {
    return (
        <Routes>
            <Route path="" element={<AuthorizationCheck />}>
                <Route index element={<Navigate to="clients" replace />} />
                <Route path="clients">
                    <Route index element={<ClientSelectionPage />} />
                    <Route path=":cId">
                        <Route index element={<RouteGuard securityArea={DashboardArea} render={<ClientDashboardPage />} />} />
                        <Route path="candidates" element={<RouteGuard securityArea={CandidatesArea} render={<ClientCandidatesPage />} />} />
                        <Route
                            path="candidates/:eId/:candidateId"
                            element={<RouteGuard securityArea={CandidatesArea} render={<PageWithModal page={<CandidatePage />} />} />}
                        >
                            <Route path="details" element={<CandidateDetailsModal returnTo={""} />} />
                            <Route
                                path="request/:requestId"
                                element={<RouteGuard hasAny={[CandidatesArea.request_mgmt]} render={<RequestModal returnTo={""} />} />}
                            />
                        </Route>
                        <Route
                            path="candidates/:eId/:candidateId/request"
                            element={<RouteGuard hasAny={[CandidatesArea.request_mgmt]} render={<RequestPage />} />}
                        />
                        {/*<Route path="reports" element={<ClientReportsPage />} />*/}
                        <Route path="entities/:entityId?" element={<RouteGuard securityArea={EntitiesArea} render={<ClientEntitiesPage />} />} />
                        {/*<Route path="activity" element={<ClientActivityPage />} />*/}
                        {/*<Route path="settings" element={<ClientSettingsPage />} />*/}
                    </Route>
                </Route>
                <Route path="profile" element={<ProfilePage />} />
                <Route path="admin" element={<AuthorizationCheck requiredRole={ApplicationRole.admin} />}>
                    <Route index element={<AdminDashboardPage />} />
                    <Route path="clients" element={<AdminClientsPage />} />
                    <Route path="users" element={<AdminUsersPage />} />
                    {/*<Route path="reports" element={<AdminReportsPage />} />*/}
                    {/*<Route path="system" element={<AdminSystemPage />} />*/}
                </Route>
            </Route>
            <Route path="Unauthorized" element={<UnauthorizedPage />} />
            <Route path="404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
