import { FunctionComponent, useEffect } from "react";
import { RoleNames, useHasAccess, WotcIdentity } from "../../hooks/useHasAccess";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Select,
    Skeleton,
    Stack,
    StackDivider,
    Tag,
    Text,
    useClipboard,
} from "@chakra-ui/react";
import { IconCheck, IconClipboardCopy } from "@tabler/icons-react";
import { useApiContext } from "@am-tax/fe-core";
import { RequireRole } from "../../components/RequireRole";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { format } from "date-fns";
import { useAccount, useAccountUpdate, ApplicationRole } from "../../api";
import { SetPageTitle } from "../../components/SetPageTitle";

export const ProfilePage: FunctionComponent = () => {
    const { identity } = useHasAccess(),
        appInsights = useAppInsightsContext();

    useEffect(() => {
        appInsights.trackPageView({
            uri: "/profile",
        });
    }, [appInsights]);

    return (
        <Box sx={{ height: "100%" }}>
            <Flex justify="center" align="flex-start" height="100%">
                {identity ? <ProfileCard identity={identity} /> : <Text fontSize="lg">No identity found.</Text>}
            </Flex>
        </Box>
    );
};

const now = new Date();

const ProfileCard: FunctionComponent<{ identity: WotcIdentity }> = ({ identity }) => {
    const { data: account, isLoading: accountLoading } = useAccount();
    const { mutate: updateAccount, isLoading: accountSaving } = useAccountUpdate();
    const isLoading = accountLoading || accountSaving;
    const roles = (identity.roles ?? []).map(r => RoleNames[r]);

    const { accessToken, idToken } = useApiContext(),
        appInsights = useAppInsightsContext();

    const { onCopy: onApiTokenCopy, hasCopied: apiTokenHasCopied } = useClipboard(accessToken ?? ""),
        onApiTokenClick = () => {
            onApiTokenCopy();

            appInsights.trackEvent({
                name: "profile.apiTokenCopy",
            });
        };
    const { onCopy: onAppTokenCopy, hasCopied: appTokenHasCopied } = useClipboard(idToken ?? ""),
        onAppTokenClick = () => {
            onAppTokenCopy();

            appInsights.trackEvent({
                name: "profile.appTokenCopy",
            });
        };

    const idClipboard = useClipboard(identity.oid);

    const dateFormatOptions = dateFormats.map(f => (
            <option key={f} value={f}>
                {format(now, f)}
            </option>
        )),
        timeFormatOptions = timeFormats.map(f => (
            <option key={f} value={f}>
                {format(now, f)}
            </option>
        ));

    return (
        <>
            <SetPageTitle title={"Your Profile"} />
            <Card size="lg">
                <CardHeader>
                    <Heading size="md">
                        {identity.givenname} {identity.surname}
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Stack divider={<StackDivider />} spacing="4">
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                ID
                            </Heading>
                            <Text pt="2" fontSize="sm" onClick={idClipboard.onCopy} cursor="copy" title="Click to copy">
                                {identity.oid}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Email
                            </Heading>
                            <Text pt="2" fontSize="sm">
                                {identity.mail}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Roles
                            </Heading>
                            <HStack>
                                {roles.map(role => (
                                    <Tag key={role} size="lg" colorScheme="blue">
                                        {role}
                                    </Tag>
                                ))}
                            </HStack>
                        </Box>
                        <Box>
                            <Skeleton isLoaded={!accountLoading}>
                                <FormControl isDisabled={isLoading}>
                                    <FormLabel>Date format</FormLabel>
                                    <Select
                                        value={account?.dateFormat}
                                        onChange={ev => {
                                            const { selectedOptions } = ev.target;
                                            updateAccount([
                                                {
                                                    op: "replace",
                                                    path: "/dateFormat",
                                                    value: selectedOptions[0].value,
                                                },
                                            ]);
                                        }}
                                    >
                                        {dateFormatOptions}
                                    </Select>
                                </FormControl>
                                <FormControl isDisabled={isLoading}>
                                    <FormLabel>Time format</FormLabel>
                                    <Select
                                        value={account?.timeFormat}
                                        onChange={ev => {
                                            const { selectedOptions } = ev.target;
                                            updateAccount([
                                                {
                                                    op: "replace",
                                                    path: "/timeFormat",
                                                    value: selectedOptions[0].value,
                                                },
                                            ]);
                                        }}
                                    >
                                        {timeFormatOptions}
                                    </Select>
                                </FormControl>
                            </Skeleton>
                        </Box>
                        <RequireRole role={ApplicationRole.developer}>
                            <Box as={Flex} justify="center" direction="column" gap={4}>
                                <Button
                                    colorScheme={apiTokenHasCopied ? "green" : undefined}
                                    leftIcon={apiTokenHasCopied ? <IconCheck /> : <IconClipboardCopy />}
                                    onClick={onApiTokenClick}
                                >
                                    Copy API Token
                                </Button>
                                <Button
                                    colorScheme={appTokenHasCopied ? "green" : undefined}
                                    leftIcon={appTokenHasCopied ? <IconCheck /> : <IconClipboardCopy />}
                                    onClick={onAppTokenClick}
                                >
                                    Copy APP Token
                                </Button>
                            </Box>
                        </RequireRole>
                    </Stack>
                </CardBody>
            </Card>
        </>
    );
};

const dateFormats = ["MM/dd/yyyy", "MM-dd-yyyy", "M/d/yyyy", "M-d-yyyy", "yyyy/MM/dd", "yyyy-MM-dd", "MMM d, yyyy"];

const timeFormats = ["hh:mm a", "H:mm"];
