﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { queryKeys } from "../queryKeys";
import { AxiosError } from "axios";
import { createPatch } from "rfc6902";
import { Request, RequestArgs } from "../../common";
import { axiosInstance } from "../../utils";

export function useRequestSave() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();

    const toast = useToast();

    return useMutation({
        mutationFn: (request: RequestArgs) => {
            if (!request.old) {
                return axios
                    .post<Request>(`api/clients/${request.clientId}/entities/${request.entityId}/candidates/${request.candidateId}/requests`, request.new)
                    .then(response => response.data);
            } else {
                return axios
                    .patch<Request>(
                        `api/clients/${request.clientId}/entities/${request.entityId}/candidates/${request.candidateId}/requests/${request.new.id}`,
                        createPatch(request.old, request.new)
                    )
                    .then(response => response.data);
            }
        },
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries(queryKeys.requests.byCandidateId(variables.candidateId).queryKey);
            queryClient.setQueryData(queryKeys.requests.byCandidateAndRequest(variables.candidateId, data.id).queryKey, data);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Unable to save document request",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
