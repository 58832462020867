import { FC } from "react";
import { CandidateStatus } from "../../api/models/EntityCandidate";
import { Icon } from "@chakra-ui/react";
import {
    IconCertificate2,
    IconCircleCheck,
    IconCircleDotted,
    IconCircleX,
    IconCoin,
    IconDotsCircleHorizontal,
    IconForbid,
    IconGavel,
    IconHourglass,
    IconHourglassOff,
} from "@tabler/icons-react";
import { IconProps } from "@chakra-ui/icon/dist/icon";

type StatusDetails = { as: FC; color?: string; title: string };

export const CandidateStatusDetails: Record<CandidateStatus, StatusDetails> = {
    [CandidateStatus.NotScreened]: { as: IconCircleDotted, title: "Not Screened" },
    [CandidateStatus.Screening]: { as: IconDotsCircleHorizontal, title: "Screening" },
    [CandidateStatus.OptedOut]: { as: IconCircleX, title: "Opted Out" },
    [CandidateStatus.Eligible]: { as: IconCircleCheck, title: "Eligible" },
    [CandidateStatus.NotEligible]: { as: IconForbid, title: "Not Eligible" },
    [CandidateStatus.NotHired]: { as: IconForbid, title: "Not Hired" },
    [CandidateStatus.PendingDocuments]: { as: IconHourglass, title: "Pending Documents" },
    [CandidateStatus.UnableToSubstantiate]: { as: IconForbid, title: "Unable to Substantiate" },
    [CandidateStatus.ReadyForCertification]: { as: IconCertificate2, color: "yellow.500", title: "Ready for Certification" },
    [CandidateStatus.PendingCertification]: { as: IconCertificate2, color: "blue.500", title: "Pending Certification" },
    [CandidateStatus.Certified]: { as: IconCertificate2, title: "Certified" },
    [CandidateStatus.Claimed]: { as: IconCoin, color: "green.500", title: "Claimed" },
    [CandidateStatus.Denied]: { as: IconForbid, color: "red.500", title: "Denied" },
    [CandidateStatus.InAppeal]: { as: IconGavel, color: "yellow.500", title: "In Appeal" },
    [CandidateStatus.AppealDenied]: { as: IconForbid, color: "red.800", title: "Appeal Denied" },
    [CandidateStatus.Expired]: { as: IconHourglassOff, title: "Expired" },
};

export const CandidateStatusIcon: FC<{ status?: CandidateStatus } & IconProps> = ({ status, ...rest }) => {
    if (status) {
        return <Icon {...CandidateStatusDetails[status]} {...rest} />;
    } else {
        return null;
    }
};

export const getCandidateStatusTitle = (status: CandidateStatus | undefined) => {
    if (status) {
        return CandidateStatusDetails[status].title;
    } else {
        return null;
    }
};
