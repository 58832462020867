﻿import { axiosInstance } from "../../utils";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";

export function useCandidateBulkExport() {
    const axios = axiosInstance();

    return useMemo(
        () =>
            async (
                clientId: string | undefined,
                entityId: string | undefined,
                activeOnly: boolean,
                recurse: boolean,
                progressCallback?: (progress: number) => void
            ) => {
                const config: AxiosRequestConfig = {
                    responseType: "blob",
                };
                if (progressCallback) {
                    config.onDownloadProgress = progressEvent => {
                        const loaded = progressEvent.loaded;
                        const total = progressEvent.total;
                        if (total) {
                            const percentage = (loaded / total) * 100;
                            progressCallback(percentage);
                        }
                    };
                }
                const response = await axios.get<Blob>(
                    `api/clients/${clientId}/entities/${entityId ?? "all"}/candidates/Export?recurse=${recurse}&activeOnly=${activeOnly}`,
                    config
                );
                return response.data;
            },
        [axios]
    );
}
