﻿import { FunctionComponent, useRef } from "react";
import { useMutateUserTenantRole } from "../../../api/users";
import { TenantRole, UserTenantResponse } from "../../../api";
import {
    Badge,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    IconButtonProps,
    Select,
    SelectProps,
    Table,
    TableContainer,
    Tbody,
    Td,
    Tr,
    Text,
    useDisclosure,
    VStack,
    Button,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
    AlertDialogProps,
    Icon,
} from "@chakra-ui/react";
import { IconShieldQuestion, IconTrash } from "@tabler/icons-react";
import { useDeleteUserTenantRole } from "../../../api";
import { formatTimestampStr } from "@am-tax/fe-core";

export const TenantRoleControl: FunctionComponent<UserTenantResponse> = props => {
    const { tenantName, role } = props;
    const { isOpen: isDetailsOpen, onToggle: toggleDetails } = useDisclosure();
    const deleteConfirmationDialog = useDisclosure();
    const { mutate, isLoading } = useMutateUserTenantRole(props.userId, props.tenantId);
    const deleteUserTenantRole = useDeleteUserTenantRole();

    const roleSelect: SelectProps = {
        value: role,
        onChange: event => {
            const { selectedOptions } = event.target,
                newRole = selectedOptions[0].value as TenantRole;
            mutate(newRole);
        },
    };

    const deleteButton: IconButtonProps = {
            "aria-label": "delete role",
            icon: <Icon as={IconTrash} />,
            onClick: deleteConfirmationDialog.onOpen,
        },
        securityDetailsButton: IconButtonProps = {
            "aria-label": "expand security details",
            icon: <Icon as={IconShieldQuestion} />,
            onClick: toggleDetails,
            isDisabled: isLoading,
        };

    return (
        <>
            <VStack>
                <FormControl isDisabled={isLoading}>
                    <FormLabel>{tenantName}</FormLabel>
                    <HStack>
                        <Select {...roleSelect}>
                            <option value={TenantRole.Owner}>Owner</option>
                            <option value={TenantRole.Contributor}>Contributor</option>
                            <option value={TenantRole.Reader}>Reader</option>
                        </Select>
                        <IconButton {...deleteButton} />
                        <IconButton {...securityDetailsButton} />
                    </HStack>
                </FormControl>
                {isDetailsOpen ? (
                    <TableContainer>
                        <Table size="sm">
                            <Tbody>
                                <Tr>
                                    <Td>Created by</Td>
                                    <Td>
                                        <HStack gap={1}>
                                            <Text>{props.createdByDisplayName}</Text>
                                            {props.createdById === props.userId && <Badge colorScheme="blue">Self</Badge>}
                                        </HStack>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Valid from</Td>
                                    <Td>{formatTimestampStr(props.from)}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Valid to</Td>
                                    <Td>{props.to ? formatTimestampStr(props.to) : "indefinite"}</Td>
                                </Tr>
                                {props.deletedById && (
                                    <Tr>
                                        <Td>Deleted by</Td>
                                        <Td>{props.deletedByDisplayName}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                ) : undefined}
                <Divider />
            </VStack>
            <DeleteConfirmationDialog
                isOpen={deleteConfirmationDialog.isOpen}
                onClose={deleteConfirmationDialog.onClose}
                onDelete={() => {
                    deleteUserTenantRole.mutate(props);
                }}
                tenantName={props.tenantName}
                userDisplayName={props.userDisplayName}
                isDisabled={deleteUserTenantRole.isLoading}
            />
        </>
    );
};

type DeleteConfirmationDialogProps = Pick<AlertDialogProps, "isOpen" | "onClose"> &
    Pick<UserTenantResponse, "tenantName" | "userDisplayName"> & {
        onDelete: () => void;
        isDisabled: boolean;
    };
const DeleteConfirmationDialog: FunctionComponent<DeleteConfirmationDialogProps> = props => {
    const { isOpen, onClose, onDelete, userDisplayName, tenantName, isDisabled } = props;
    const cancelRef = useRef(null);

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete Client Role
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        This will remove <strong>{userDisplayName}</strong>'s access to <strong>{tenantName}</strong>.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose} isDisabled={isDisabled}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={onDelete} ml={3} isDisabled={isDisabled}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
