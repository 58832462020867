﻿import { useMemo } from "react";
import { axiosInstance } from "../../utils";

export function useRequestDownloadFileFn() {
    const axios = axiosInstance();

    return useMemo(
        () =>
            (
                clientId: string | undefined,
                entityId: string | undefined,
                candidateId: string,
                requestId: string,
                documentId: string,
                progressCallback: (progress: number) => void
            ) =>
                axios
                    .get<Blob>(
                        `api/clients/${clientId}/entities/${entityId}/candidates/${candidateId}/requests/${requestId}/documents/${documentId}/download`,
                        {
                            responseType: "blob",
                            onDownloadProgress: progressEvent => {
                                const loaded = progressEvent.loaded;
                                const total = progressEvent.total;
                                if (total) {
                                    const percentage = (loaded / total) * 100;
                                    progressCallback(percentage);
                                }
                            },
                        }
                    )
                    .then(response => response.data),
        [axios]
    );
}
