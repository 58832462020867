﻿import { allAreas, Permission, PermissionsByAreaType, SecurityArea, AreaPermissions, PermissionsByRole } from "./Permissions";

function getPermissionsByAreaName() {
    const permissionsByAreaName: Record<string, Set<Permission>> = {};
    for (const area of allAreas) {
        permissionsByAreaName[area._name] = generatePermissionSet(area);
    }
    return permissionsByAreaName;
}
function generatePermissionSet(area: SecurityArea): Set<Permission> {
    return new Set(
        Object.entries(area)
            .filter(([key]) => key !== "_name")
            .map(([, value]) => value)
    );
}
const permissionsByAreaName = getPermissionsByAreaName();

function getPermissionSet(area: SecurityArea): Set<string> {
    return permissionsByAreaName[area._name];
}

export function roleCanSeeArea(permissionsByRole: PermissionsByRole, area: SecurityArea, roleId?: string) {
    if (roleId) {
        const areaPermissions = getPermissionSet(area);
        for (const perm of permissionsByRole[roleId] || []) {
            if (areaPermissions.has(perm)) {
                return true;
            }
        }
    }
    return false;
}

export function roleHasPermission(permissionsByRole: PermissionsByRole, permission: Permission | null, roleId?: string) {
    if (permission) {
        return roleHasAnyPermission(permissionsByRole, [permission], roleId);
    } else {
        return false;
    }
}

export function roleHasAnyPermission(permissionsByRole: PermissionsByRole, permissions: Permission[], roleId?: string) {
    if (!roleId) {
        return false;
    }

    for (const permission of permissions) {
        if (permissionsByRole[roleId]?.has(permission)) {
            return true;
        }
    }
    return false;
}

export function canCreateOrUpdate<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    createPermission: PermissionsByAreaType<A> | null,
    updatePermission: PermissionsByAreaType<A> | null,
    entityId: number | false
): boolean {
    if (entityId) {
        return areaPermissions.has(updatePermission);
    } else {
        return areaPermissions.has(createPermission);
    }
}

export function canDelete<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    deletePermission: PermissionsByAreaType<A>,
    entityId: number | false
): boolean {
    if (entityId) {
        return areaPermissions.has(deletePermission);
    } else {
        return false;
    }
}

/**
 * Use this function to check if the user has the permission to perform the action they are trying to perform when accessing an add/edit/delete page.
 * @param areaPermissions
 * @param createPermission
 * @param readPermission
 * @param updatePermission
 * @param deletePermission
 * @param entityId
 */
export function accessMatchesIntent<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    createPermission: PermissionsByAreaType<A> | null,
    readPermission: PermissionsByAreaType<A> | null,
    updatePermission: PermissionsByAreaType<A> | null,
    deletePermission: PermissionsByAreaType<A> | null,
    entityId: number
): boolean {
    if (entityId) {
        return areaPermissions.has(readPermission) || areaPermissions.has(updatePermission) || areaPermissions.has(deletePermission);
    } else {
        return areaPermissions.has(createPermission);
    }
}
