﻿import { Component, ErrorInfo, FC, ReactNode } from "react";
import { ErrorFallback } from "../ErrorFallback";
import { useLocation } from "react-router-dom";
import { Location } from "history";

type ErrorBoundaryProps = {
    children?: ReactNode;
    fallback?: ReactNode;
};

type ErrorBoundaryCatcherProps = ErrorBoundaryProps & { location: Location };

type ErrorBoundaryState = {
    hasError: boolean;
};
class ErrorBoundaryCatcher extends Component<ErrorBoundaryCatcherProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryCatcherProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
        console.error(error, info.componentStack);
    }

    componentDidUpdate(prevProps: ErrorBoundaryCatcherProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            if (this.props.fallback) {
                return this.props.fallback;
            } else {
                return <ErrorFallback />;
            }
        }

        return this.props.children;
    }
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = props => {
    const location = useLocation();
    return <ErrorBoundaryCatcher location={location} {...props} />;
};
