﻿import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
    Box,
    Button,
    ButtonGroup,
    ButtonProps,
    Flex,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Spacer,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import { TenantResponse, useTenants } from "../../api";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { ICellRendererParams } from "@ag-grid-community/core";
import { IconCircleOff, IconFileSpreadsheet, IconMenu, IconPencil, IconPlus, IconRefresh, IconTrashX } from "@tabler/icons-react";
import "@ag-grid-community/styles/ag-grid.min.css";
import "@ag-grid-community/styles/ag-theme-material.min.css";
import { useUserContext } from "../../context/UserContext";
import { TenantEditorDrawer } from "./TenantEditorDrawer";
import { TenantCreatorDialog } from "./TenantCreatorDialog";
import { TenantRestoreDrawer } from "./TenantRestoreDrawer";
import { SetPageTitle } from "../../components/SetPageTitle";
import { formatTimestampStr } from "@am-tax/fe-core";

export const AdminClientsPage: FunctionComponent = () => {
    const appInsights = useAppInsightsContext();
    const user = useUserContext();

    const tenantsQuery = useTenants();

    const editDrawer = useDisclosure(),
        [selectedTenant, setSelectedTenant] = useState<TenantResponse>();

    const createDialog = useDisclosure();
    const restoreDrawer = useDisclosure();

    useEffect(() => {
        appInsights.trackPageView({
            uri: "/admin/clients",
        });
    }, [appInsights]);

    const tenantActionsCellRenderer = useMemo(
        () => (props: ICellRendererParams<TenantResponse>) => {
            return (
                <Flex height="100%" align="center">
                    <ButtonGroup>
                        <IconButton
                            aria-label={"edit item"}
                            icon={<Icon as={IconPencil} />}
                            onClick={() => {
                                setSelectedTenant(props.data);
                                editDrawer.onOpen();
                            }}
                        />
                    </ButtonGroup>
                </Flex>
            );
        },
        [editDrawer, setSelectedTenant]
    );

    const tenantStatusCellRenderer = useMemo(
        () => (props: ICellRendererParams<TenantResponse>) => {
            return props.data?.isEnabled ? undefined : (
                <Tooltip label="Disabled">
                    <Icon as={IconCircleOff} />
                </Tooltip>
            );
        },
        []
    );

    const { data: rowData } = useTenants();

    const gridRef = useRef<AgGridReact<TenantResponse>>(null);
    const tenantsGrid: AgGridReactProps<TenantResponse> = {
        rowData,
        columnDefs: [
            {
                field: "isEnabled",
                headerName: "",
                cellRenderer: tenantStatusCellRenderer,
                maxWidth: 40,
            },
            {
                field: "name",
                filter: "text",
            },
            { field: "externalId", filter: "text" },
            {
                field: "createdOn",
                filter: false,
                valueFormatter: params => formatTimestampStr(params.value, user.dateFormat),
            },
            {
                field: undefined,
                headerName: "Actions",
                filter: false,
                sortable: false,
                cellRenderer: tenantActionsCellRenderer,
            },
        ],
    };

    const createButton: ButtonProps = {
        children: "Create",
        leftIcon: <Icon as={IconPlus} />,
        onClick: () => {
            setSelectedTenant(undefined);
            editDrawer.onClose();
            createDialog.onOpen();
        },
    };

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            <SetPageTitle title={"System Settings"} />
            <HStack p={1}>
                <Button {...createButton} />
                <Spacer />
                <Menu>
                    <MenuButton as={IconButton} aria-label="expand menu" icon={<Icon as={IconMenu} />}></MenuButton>
                    <MenuList>
                        <MenuItem icon={<Icon as={IconFileSpreadsheet} />}>Export all</MenuItem>
                        <MenuItem icon={<Icon as={IconTrashX} />} onClick={restoreDrawer.onOpen}>
                            Deleted clients
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                            icon={<Icon as={IconRefresh} />}
                            onClick={async () => {
                                await tenantsQuery.refetch();
                            }}
                        >
                            Refresh
                        </MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
            <Box height="100%" width="100%" className="ag-theme-material">
                <AgGridReact {...tenantsGrid} ref={gridRef} />
            </Box>
            <TenantEditorDrawer tenantId={selectedTenant?.id} isOpen={editDrawer.isOpen} onClose={editDrawer.onClose} />
            <TenantCreatorDialog {...createDialog} />
            <TenantRestoreDrawer {...restoreDrawer} />
        </Box>
    );
};
