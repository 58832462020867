﻿import { ICellRendererParams } from "@ag-grid-community/core";
import { Text } from "@chakra-ui/react";

export const EmailCellRenderer = (props: ICellRendererParams): JSX.Element => {
    if (props.data?.email) {
        const parts = props.data.email.split("@"),
            local = parts[0],
            domain = parts.length > 1 ? parts.slice(1).join("") : undefined;

        return (
            <Text>
                {local}
                <span className="email-domain">@{domain}</span>
            </Text>
        );
    }

    return <span />;
};
