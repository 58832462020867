import { FC, useMemo } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useRequests } from "../../api";
import { RequestForm } from "./RequestForm";
import { Request } from "../../common";

interface PageWithModalProps {
    returnTo: string;
}

export const RequestModal: FC<PageWithModalProps> = ({ returnTo }) => {
    const navigate = useNavigate();
    const { cId, eId, candidateId, requestId } = useParams();
    const engagementRequestsQuery = useRequests(cId, eId, candidateId);
    const request: Request | undefined = useMemo(
        () => engagementRequestsQuery.data?.find(request => request.id === requestId),
        [engagementRequestsQuery.data, requestId]
    );

    // ---- Navigation Helper ----
    const goBackToAllRequests = () => {
        navigate(`/clients/${cId}/candidates/${eId}/${candidateId}/${returnTo}`);
    };

    return (
        <Modal
            isOpen={true}
            onClose={() => {
                goBackToAllRequests();
            }}
            size={"xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{`${request?.id ? "Edit" : "Add"} Request`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={"1.5rem"}>
                    <RequestForm returnTo={returnTo} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
