﻿import { axiosInstance } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { CandidateQualification, DesignatedZoneType } from "../models/EntityCandidate";

export type UpdateEntityCandidateQualificationRequest = {
    candidateId: string | undefined;
    entityId: string | undefined;
    tenantId: string | undefined;
    comment: string | undefined;
    designatedZoneName: string | undefined;
    designatedZoneType: DesignatedZoneType | undefined;
    isManuallyAdded: boolean;
    qualification: CandidateQualification;
    id: string | undefined;
};
export function useCandidateQualificationUpdate() {
    const axios = axiosInstance();
    const toast = useToast();

    return useMutation({
        mutationFn: (request: UpdateEntityCandidateQualificationRequest) => {
            const data = {
                id: request.id,
                isManuallyAdded: request.isManuallyAdded,
                comment: request.comment,
                qualification: request.qualification,
                designatedZoneName: request.designatedZoneName,
                designatedZoneType: request.designatedZoneType,
            };
            return axios
                .put(`/api/clients/${request.tenantId}/entities/${request.entityId}/candidates/${request.candidateId}/CandidateQualifications/update`, {
                    ...data,
                })
                .then(response => response.data);
        },
        onSuccess: async (data, variables) => {
            toast({
                title: "Update Successful",
                description: "Candidate Qualification was updated.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Unable to update Candidate Qualification details.",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
