﻿import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { IDoesFilterPassParams, IFilterParams } from "@ag-grid-community/core";
import { Box, HStack, Select, Text } from "@chakra-ui/react";
import { CandidateStatus } from "../../../../api/models/EntityCandidate";
import { CandidateStatusIcon, getCandidateStatusTitle } from "../../../../components/CandidateStatus";

export default forwardRef((props: IFilterParams<{ status: CandidateStatus }>, ref) => {
    const [filterValue, setFilterValue] = useState<CandidateStatus>();

    const filterOptions = useMemo(
        () =>
            Object.keys(CandidateStatus)
                .map(value => value as CandidateStatus)
                .map(status => (
                    <option key={status} value={status}>
                        <HStack>
                            <CandidateStatusIcon status={status} />
                            <Text>{getCandidateStatusTitle(status)}</Text>
                        </HStack>
                    </option>
                )),
        []
    );

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params: IDoesFilterPassParams<{ status: CandidateStatus }>) {
                if (filterValue === undefined) {
                    return true;
                }

                return params.data.status === filterValue;
            },

            isFilterActive() {
                return filterValue !== undefined;
            },

            // this example isn't using getModel() and setModel(),
            // so safe to just leave these empty. don't do this in your code!!!
            getModel() {
                if (!this.isFilterActive()) {
                    return null;
                }

                return { filterType: "text", type: "equals", filter: filterValue };
            },

            setModel(model: any) {
                setFilterValue(model == null ? undefined : model.value);
            },
        };
    });

    useEffect(() => {
        props.filterChangedCallback();
    }, [filterValue]);

    return (
        <Box>
            <Select
                value={filterValue}
                onChange={event => {
                    const { selectedOptions } = event.target;
                    if (selectedOptions[0].value) {
                        setFilterValue(selectedOptions[0].value as CandidateStatus);
                    }
                }}
            >
                {filterOptions}
            </Select>
        </Box>
    );
});
