﻿import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../../utils";
import { queryKeys } from "../queryKeys";
import { CandidateComment } from "../models/CandidateComment";

export function useCandidateComments(tenantId: string | undefined, entityId: string | undefined, candidateId: string | undefined) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.candidateComments.byCandidateId(candidateId),
        queryFn: () =>
            axios.get<CandidateComment[]>(`api/Clients/${tenantId}/entities/${entityId}/candidates/${candidateId}/comments`).then(response => response.data),
    });
}
