﻿import { queryKeys } from "../queryKeys";
import { useQuery } from "@tanstack/react-query";
import { EntityCandidate } from "../models/EntityCandidate";
import { axiosInstance } from "../../utils";

export function useCandidate(clientId: string | undefined, entityId: string | undefined, candidateId: string | undefined) {
    const axios = axiosInstance();

    return useQuery({
        ...queryKeys.candidates.byCandidateId(candidateId),
        queryFn: () => {
            if (candidateId) {
                return axios.get<EntityCandidate>(`api/clients/${clientId}/entities/${entityId}/candidates/${candidateId}`).then(response => response.data);
            } else {
                return null;
            }
        },
    });
}
