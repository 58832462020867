﻿import { FunctionComponent, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { EntityTreeItem } from "./EntityTreeItem";
import { sortBy } from "lodash";
import { useEntityTreeContext } from "../EntityTreeContext";

export const EntityTree: FunctionComponent = () => {
    const { entities } = useEntityTreeContext();

    const sortedItems = useMemo(() => sortBy(entities, i => (i.entityType === "Directory" ? `A${i.name}` : i.name)), [entities]);

    return (
        <Box>
            {sortedItems.map(entity => (
                <EntityTreeItem entity={entity} key={entity.id} />
            ))}
        </Box>
    );
};
