﻿import { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { EntityCandidate } from "../../../../api/models/EntityCandidate";
import { useClientEntityCandidates } from "../../../../api";
import { Badge, Box, Text, useDisclosure } from "@chakra-ui/react";
import { EmailCellRenderer } from "../../../../grid";
import { CandidateStatusCellRenderer } from "./CandidateStatusCellRenderer";
import CandidateStatusFilter from "./CandidateStatusFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CandidateGridMenuBar } from "./CandidateGridMenuBar";
import { ImportCandidatesModal } from "../ImportCandidatesModal";
import { ColDef, ICellRendererParams, RowClickedEvent, RowSelectedEvent } from "@ag-grid-community/core";
import { AgGridContainer, formatDateStr } from "@am-tax/fe-core";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "../../../../assets/css/ag-theme.css";
import { useAreaPermissions } from "../../../../hooks";
import { CandidatesArea } from "../../../../auth";
import { useUserContext } from "../../../../context/UserContext";

export interface CandidateGridProps {
    before?: ReactNode;
    clientId: string;
    entityId: string | undefined;
}

export const CandidateGrid: FunctionComponent<CandidateGridProps> = ({ before, clientId, entityId }) => {
    const navigate = useNavigate();
    const { dateFormat } = useUserContext();

    const areaPermissions = useAreaPermissions(CandidatesArea, clientId);

    const [searchParams] = useSearchParams(),
        pFilterText = searchParams.get("filter") ?? "",
        pActiveOnly = (searchParams.get("activeOnly")?.toLowerCase() ?? "false") === "true";

    const importModal = useDisclosure();

    const candidatesQuery = useClientEntityCandidates(clientId, entityId, true, pActiveOnly);

    const columnDefs: Array<ColDef<EntityCandidate>> = [];

    columnDefs.push({
        headerName: "Name",
        colId: "name",
        filterValueGetter: params => `${params.data?.firstName} ${params.data?.lastName}`,
        valueGetter: params => {
            if (params.data) {
                return `${params.data.firstName} ${params.data.lastName}`;
            } else {
                return "";
            }
        },
        minWidth: 140,
        filter: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
    });
    columnDefs.push({
        field: "email",
        cellRenderer: EmailCellRenderer,
        minWidth: 140,
        filter: true,
    });
    columnDefs.push({
        field: "phone",
        filter: true,
    });
    columnDefs.push({
        headerName: "Location",
        cellRenderer: ({ data }: ICellRendererParams) => (
            <>
                <Text>
                    {data?.city}, {data?.state} {data?.zipCode}
                </Text>
            </>
        ),
    });

    columnDefs.push({
        field: "designatedZoneType",
        cellRenderer: ({ data }: ICellRendererParams) => {
            return data.designatedZoneType ? "YES" : "NO";
        },
    });

    columnDefs.push({
        field: "status",
        cellRenderer: CandidateStatusCellRenderer,
        filter: CandidateStatusFilter,
    });

    if (areaPermissions.has(CandidatesArea.read_full)) {
        columnDefs.push({
            headerName: "Target Qualification",
            cellRenderer: ({ data }: ICellRendererParams) => {
                if (data?.selectedQualification) {
                    return data.selectedQualification;
                } else {
                    return (
                        <Box>
                            <Badge colorScheme={"red"} lineHeight={"var(--chakra-lineHeights-base)"}>
                                Action Needed
                            </Badge>
                        </Box>
                    );
                }
            },
            minWidth: 120,
            filter: false,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                if (nodeA.data?.selectedQualification === nodeB.data?.selectedQualification) {
                    return 0;
                }

                if (!nodeA.data?.selectedQualification) {
                    return 1;
                }

                return ("" + nodeA.data?.selectedQualification).localeCompare("" + nodeB.data?.selectedQualification);
            },
        });

        columnDefs.push({
            headerName: "Follow-up",
            cellRenderer: ({ data }: ICellRendererParams) =>
                !!data.openRequestCount && (
                    <Box>
                        <Badge colorScheme={"red"} lineHeight={"var(--chakra-lineHeights-base)"}>
                            {data.openRequestCount} Open Requests
                        </Badge>
                    </Box>
                ),
            minWidth: 180,
            filter: false,
            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                const vA = nodeA.data?.openRequestCount ?? 0,
                    vB = nodeB.data?.openRequestCount ?? 0;

                if (vA === vB) {
                    return 0;
                }
                return vA > vB ? 1 : -1;
            },
        });
    }

    columnDefs.push({
        field: "employmentStatus",
        cellRenderer: ({ data }: ICellRendererParams) => {
            return data.employmentStatus;
        },
    });

    columnDefs.push({
        field: "daysRemaining",
        cellRenderer: ({ data }: ICellRendererParams) =>
            data.daysRemaining >= 0 &&
            data.daysRemaining !== null && (
                <Box>
                    <Badge
                        colorScheme={data?.daysRemaining > 21 ? "green" : data?.daysRemaining > 14 ? "yellow" : "red"}
                        variant={"solid"}
                        lineHeight={"var(--chakra-lineHeights-base)"}
                    >
                        {data.daysRemaining}
                    </Badge>
                </Box>
            ),
        filter: true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            const vA = nodeA.data?.daysRemaining ?? 0,
                vB = nodeB.data?.daysRemaining ?? 0;

            if (vA === vB) {
                return 0;
            }
            return vA > vB ? 1 : -1;
        },
    });

    columnDefs.push({
        headerName: "Completed On",
        cellRenderer: ({ data }: ICellRendererParams) => (
            <>
                <Text>{formatDateStr(data?.questionnaireCompletedOn, dateFormat)}</Text>
            </>
        ),
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            const vA = formatDateStr(nodeA.data?.questionnaireCompletedOn ?? "0001-01-01", "yyyy-MM-dd"),
                vB = formatDateStr(nodeB.data?.questionnaireCompletedOn ?? "0001-01-01", "yyyy-MM-dd");

            if (vA === vB) {
                return 0;
            }

            return vA > vB ? 1 : -1;
        },
    });

    const gridRef = useRef<AgGridReact<EntityCandidate>>(null),
        candidatesGrid: AgGridReactProps<EntityCandidate> = {
            rowData: candidatesQuery.data,
            defaultColDef: {
                flex: 1,
                sortable: true,
                resizable: true,
            },
            columnDefs,
            onRowClicked(event: RowClickedEvent<EntityCandidate>) {
                navigate(`/clients/${clientId}/candidates/${event.data?.entityId}/${event.data?.id}`);
            },
            getRowStyle: params => ({
                cursor: "pointer",
            }),
        };

    useEffect(() => {
        gridRef.current?.api?.setQuickFilter(pFilterText);
    }, [pFilterText, gridRef.current]);

    //Checks for the number of selected checkbox
    const [deleteIsDisabled, setDeleteIsDisabled] = useState(true);
    const onRowSelected = useCallback((event: RowSelectedEvent) => {
        const selectedNodesCount = event.api.getSelectedNodes().length;
        if (selectedNodesCount > 0) {
            setDeleteIsDisabled(false);
        } else {
            setDeleteIsDisabled(true);
        }
    }, []);

    return (
        <AgGridContainer
            gap={0}
            borderTop={"none"}
            borderRadius={0}
            before={
                <Box>
                    {before}
                    <CandidateGridMenuBar
                        grid={gridRef.current}
                        query={candidatesQuery}
                        clientId={clientId}
                        entityId={entityId}
                        onImportModalOpen={importModal.onOpen}
                        deleteIsDisabled={deleteIsDisabled}
                    />
                </Box>
            }
        >
            <AgGridReact {...candidatesGrid} ref={gridRef} rowSelection={"multiple"} onSelectionChanged={onRowSelected} />
            {entityId && <ImportCandidatesModal {...importModal} clientId={clientId} entityId={entityId} />}
        </AgGridContainer>
    );
};
