﻿import { FunctionComponent, useEffect, useMemo } from "react";
import { useSetAtom } from "jotai";
import { pageTitleAtom } from "../../atoms";

/**
 * This component injects the page title into the TopNav and the document title into browser window.
 * It can be called from anywhere and will not itself be put into the DOM.
 * @param title
 * @constructor
 */
export const SetPageTitle: FunctionComponent<{ title: string | null | undefined }> = ({ title }) => {
    const setTitle = useSetAtom(pageTitleAtom);
    const titleMemo = useMemo(() => title, [title]);
    useEffect(() => {
        document.title = titleMemo ? `${titleMemo} | WOTC | A&M Tax` : `WOTC | A&M Tax`;
        setTitle(titleMemo);
    }, [setTitle, titleMemo]);
    return <></>;
};
