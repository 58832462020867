﻿import { ICellRendererParams } from "@ag-grid-community/core";
import { UserResponse } from "../../../api";
import { Flex, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { IconUserBolt, IconUserShield } from "@tabler/icons-react";

export const SelfRegisteredCellRenderer = (props: ICellRendererParams<UserResponse, boolean>) => {
    return (
        <Flex height="100%" align="center">
            <Tag variant="outline">
                <TagLeftIcon as={props.value ? IconUserBolt : IconUserShield} />
                <TagLabel>{props.value ? "Self" : "Admin"}</TagLabel>
            </Tag>
        </Flex>
    );
};
