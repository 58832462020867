﻿import { FunctionComponent, PropsWithChildren, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useEnvironmentContext, triggerDownloadPrompt } from "@am-tax/fe-core";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogProps,
    Button,
    HStack,
    Icon,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Spacer,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { IconCaretDown, IconFileDownload, IconFileImport, IconFileTypeXls, IconFilterX, IconMenu, IconSquare, IconSquareCheck } from "@tabler/icons-react";
import { AgGridReact } from "@ag-grid-community/react";
import { EntityCandidate } from "../../../../api/models/EntityCandidate";
import { UseQueryResult } from "@tanstack/react-query";
import { useClientEntity } from "../../../../api";
import { useCandidateBulkExport } from "../../../../api/hooks/useCandidateBulkExport";
import { useCandidateDelete } from "../../../../api/hooks/useCandidateDelete";

export interface CandidateGridMenuBarProps {
    clientId: string;
    entityId: string | undefined;
    grid: AgGridReact<EntityCandidate> | null;
    query: UseQueryResult<EntityCandidate[]>;
    onImportModalOpen: () => void;
    deleteIsDisabled: boolean;
}

export type DeleteConfirmationDialogProps = Pick<AlertDialogProps, "isOpen" | "onClose"> &
    PropsWithChildren<{
        title: string;
        isDisabled: boolean;
        confirmLabel: string;
        onConfirm: () => void;
        isLoading: boolean;
        description: string;
    }>;

const MenuIcon = <Icon as={IconMenu} />,
    ImportIcon = <Icon as={IconFileImport} />,
    ExportIcon = <Icon as={IconFileTypeXls} />,
    CaretDownIcon = <Icon as={IconCaretDown} />,
    FileDownloadIcon = <Icon as={IconFileDownload} />,
    FilterClearIcon = <Icon as={IconFilterX} />,
    CheckOnIcon = <Icon as={IconSquareCheck} />,
    CheckOffIcon = <Icon as={IconSquare} />;

export const CandidateGridMenuBar: FunctionComponent<CandidateGridMenuBarProps> = ({
    grid,
    query,
    clientId,
    entityId,
    onImportModalOpen,
    deleteIsDisabled,
}) => {
    const [searchParams, setSearchParams] = useSearchParams(),
        pFilterText = searchParams.get("filter") ?? "",
        pActiveOnly = (searchParams.get("activeOnly")?.toLowerCase() ?? "false") === "true";
    const { apiUrl } = useEnvironmentContext();
    const entityQuery = useClientEntity(clientId, entityId ?? "", {
        enabled: entityId != undefined,
    });
    const toast = useToast();
    const candidatesExported = useCandidateBulkExport();
    const deleteCandidate = useCandidateDelete();

    const setFilterText = useMemo(
        () => (text: string) => {
            setSearchParams(
                prev => {
                    if (text.length > 0) {
                        prev.set("filter", text);
                    } else {
                        prev.delete("filter");
                    }

                    return prev;
                },
                { replace: true }
            );
        },
        [setSearchParams]
    );
    const deleteConfirmationDialog = useDisclosure(),
        DeleteConfirmationDialogProps = {
            isOpen: deleteConfirmationDialog.isOpen,
            onClose: deleteConfirmationDialog.onClose,
            title: "Delete Candidates",
            description: "Are you sure you want to delete the selected candidates?",
            confirmLabel: "Delete",
            isDisabled: false,
            isLoading: deleteCandidate.isLoading,
            onConfirm: async () => {
                const selectedRows =
                    grid?.api
                        .getSelectedNodes()
                        .filter(m => m.data && m.data?.entityId && m.data?.id)
                        .map(m => ({
                            clientId: clientId,
                            entityId: m.data!.entityId,
                            candidateId: m.data!.id,
                        })) ?? [];

                await deleteCandidate.mutateAsync(selectedRows);

                deleteConfirmationDialog.onClose();
            },
        };

    const deleteCandidates = async () => {
        deleteConfirmationDialog.onOpen();
    };

    return (
        <HStack sx={{ backgroundColor: "blue.50" }} align="center">
            <Input
                borderLeft={"none"}
                borderRight={"none"}
                borderTop={"none"}
                borderRadius={0}
                {...{
                    placeholder: `Filter ${entityQuery.data?.name || ""}`,
                    value: pFilterText,
                    onChange: ev => {
                        const newValue = ev.currentTarget.value ?? "";
                        setFilterText(newValue);
                    },
                    onKeyUp: event => {
                        if (event.key === "Escape") {
                            setFilterText("");
                        }
                    },
                }}
                maxWidth={"300px"}
            />
            <IconButton
                variant={"ghost"}
                {...{
                    "aria-label": "clear all filters",
                    icon: FilterClearIcon,
                    onClick: async () => {
                        grid?.api.setQuickFilter("");
                        grid?.api.setFilterModel({});
                        setFilterText("");
                    },
                }}
            />
            <Menu>
                <MenuButton
                    as={IconButton}
                    variant={"ghost"}
                    {...{
                        "aria-label": "open menu",
                        icon: MenuIcon,
                    }}
                />
                <MenuList>
                    <MenuItem
                        {...{
                            icon: pActiveOnly ? CheckOnIcon : CheckOffIcon,
                            onClick: () => {
                                setSearchParams(prev => {
                                    prev.set("activeOnly", !pActiveOnly ? "true" : "false");
                                    return prev;
                                });
                            },
                            isDisabled: false,
                            children: "Show active only",
                        }}
                    />
                </MenuList>
            </Menu>

            <Spacer />
            <Menu>
                <MenuButton as={Button} variant="ghost" isDisabled={deleteIsDisabled} onClick={deleteCandidates}>
                    Delete
                </MenuButton>
            </Menu>

            <Menu>
                <MenuButton as={Button} variant="ghost" rightIcon={CaretDownIcon}>
                    Import / Export
                </MenuButton>
                <MenuList>
                    <MenuGroup title="Import">
                        <MenuItem icon={FileDownloadIcon} as={"a"} href={`${apiUrl}api/CandidateTemplate`} isDisabled={entityQuery.data?.entityType !== "Leaf"}>
                            Download Template
                        </MenuItem>
                        <MenuItem icon={ImportIcon} onClick={onImportModalOpen} isDisabled={entityQuery.data?.entityType !== "Leaf"}>
                            Upload Candidates
                        </MenuItem>
                    </MenuGroup>
                    <MenuGroup title="Export">
                        <MenuItem
                            icon={ExportIcon}
                            as={"div"}
                            onClick={async () => {
                                try {
                                    const blob = await candidatesExported(clientId, entityId, pActiveOnly, true);
                                    triggerDownloadPrompt(blob, `Candidates.xlsx`);
                                } catch (ex) {
                                    toast({
                                        title: "Failure",
                                        description: "Candidate information could not be exported.",
                                        status: "error",
                                        duration: 5000,
                                        isClosable: true,
                                    });
                                    throw ex;
                                }
                            }}
                        >
                            Export Candidates
                        </MenuItem>
                    </MenuGroup>
                </MenuList>
            </Menu>
            <DeleteConfirmationDialog {...DeleteConfirmationDialogProps}></DeleteConfirmationDialog>
        </HStack>
    );
};

/*
 *  Delete Dialog component for Delete Functionality
 */
export const DeleteConfirmationDialog: FunctionComponent<DeleteConfirmationDialogProps> = props => {
    const { isOpen, onClose, onConfirm, isDisabled, title, confirmLabel, isLoading, description } = props;
    const cancelRef = useRef(null);

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{description}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose} isDisabled={isDisabled}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={onConfirm} ml={3} isDisabled={isDisabled} isLoading={isLoading}>
                            {confirmLabel}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
