﻿import { FC } from "react";
import { Card, CardBody, Heading } from "@chakra-ui/react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { DashboardResponse } from "../../api/models/DashboardResponse";
import { Shimmer } from "@am-tax/fe-core";

type ApplicationStatusWidgetProps = {
    isLoading: boolean;
    submissions: DashboardResponse["submissions"] | undefined;
};

export const ApplicationStatusWidget: FC<ApplicationStatusWidgetProps> = ({ isLoading, submissions }) => {
    const rawData: Record<string, { value: number; fillColor: string }> = submissions
        ? {
              "Applications Submitted": { value: submissions.appsSubmitted, fillColor: "var(--chakra-colors-blue-900)" },
              "Certifications Pending": { value: submissions.certificationsPending, fillColor: "var(--chakra-colors-cyan-500)" },
              "Certifications Received": { value: submissions.certificationsReceived, fillColor: "var(--chakra-colors-green-500)" },
              "Appeal Pending": { value: submissions.appealPending, fillColor: "var(--chakra-colors-purple-500)" },
              "Appeal Approved": { value: submissions.appealApproved, fillColor: "var(--chakra-colors-aqua-500)" },
              "Appeal Denied": { value: submissions.appealDenied, fillColor: "var(--chakra-colors-orange-500)" },
          }
        : {};

    const entry: Record<string, number | string> = {
        name: "Status",
    };

    Object.keys(rawData).forEach(key => {
        entry[key] = rawData[key].value;
    });

    const data = [entry];

    return (
        <Card flexGrow={1} overflow={"hidden"} variant={"withShadow"} width={"100%"} height={"100%"}>
            <CardBody display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Heading as={"h2"} mb={"2rem"} color={"blackAlpha.800"} fontWeight={"bold"} fontSize={"lg"}>
                    Application Status (YTD)
                </Heading>
                <Shimmer isLoading={isLoading} width={600} height={400}>
                    <BarChart
                        width={600}
                        height={400}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {Object.keys(rawData).map(title => {
                            return <Bar key={title} dataKey={title} fill={rawData[title].fillColor} />;
                        })}
                    </BarChart>
                </Shimmer>
            </CardBody>
        </Card>
    );
};
