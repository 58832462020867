﻿import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { IDoesFilterPassParams, IFilterParams } from "@ag-grid-community/core";
import { useStates } from "../../api";
import { Box, Select } from "@chakra-ui/react";

export default forwardRef((props: IFilterParams, ref) => {
    const { data } = useStates();
    const [filterValue, setFilterValue] = useState<string>();

    const filterOptions = useMemo(
        () =>
            (data ?? []).map(state => (
                <option key={state.code} value={state.code}>
                    {state.name}
                </option>
            )),
        [data]
    );

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params: IDoesFilterPassParams<{ state: string | undefined }>) {
                if (filterValue === undefined) {
                    return true;
                }

                if (filterValue === "") {
                    return params.data.state === "" || params.data.state === undefined;
                }

                return params.data.state === filterValue;
            },

            isFilterActive() {
                return filterValue !== undefined;
            },

            // this example isn't using getModel() and setModel(),
            // so safe to just leave these empty. don't do this in your code!!!
            getModel() {
                if (!this.isFilterActive()) {
                    return null;
                }

                return { filterType: "text", type: "equals", filter: filterValue };
            },

            setModel(model: any) {
                setFilterValue(model == null ? undefined : model.value);
            },
        };
    });

    useEffect(() => {
        props.filterChangedCallback();
    }, [filterValue]);

    return (
        <Box>
            <Select
                value={filterValue}
                onChange={event => {
                    const { selectedOptions } = event.target;
                    setFilterValue(selectedOptions[0].value);
                }}
            >
                <option value={""}>(none)</option>
                {filterOptions}
            </Select>
        </Box>
    );
});
