import { axiosInstance } from "../../utils";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios/index";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";

export function useCandidateDelete() {
    const axios = axiosInstance(),
        queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: async (requests: { clientId: string; entityId: string; candidateId: string }[]) => {
            for (const request of requests) {
                await axios.delete(`api/clients/${request.clientId}/entities/${request.entityId}/candidates/${request.candidateId}/DeleteEntityCandidate`);
            }
        },
        onSuccess: async () => {
            toast({
                title: "Successful Deletion",
                description: `Successfully deleted selected candidates.`,
                status: "success",
                isClosable: true,
                duration: 2000,
            });

            await queryClient.invalidateQueries(queryKeys.candidates.byEntity._def);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Deletion Error",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: 2000,
            });

            queryClient.invalidateQueries(queryKeys.candidates.byEntity._def);
        },
    });
}
