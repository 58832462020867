﻿import { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";

interface PageWithModalProps {
    page?: ReactNode;
}

export const PageWithModal: FC<PageWithModalProps> = ({ page }) => {
    return (
        <>
            {page}
            <Outlet />
        </>
    );
};
