﻿import { Question, QuestionnaireValues, SyncableValue } from "../api/models";
import memoize from "lodash.memoize";

const evaluateExpression = (expression: string, questionnaireValues: QuestionnaireValues): boolean => {
    // Define a regular expression pattern to match the keys
    const stateKeyPattern = /\w+\[(\w+|\*)\]/g;

    // Replace each key with its corresponding value from questionnaireValues
    const evaluatedExpression = expression.replace(stateKeyPattern, match => {
        // Extract the state key from the match
        const key = match.slice(0, match.indexOf("["));

        // Extract the state value from the match
        const expressionValue = match.slice(match.indexOf("[") + 1, match.indexOf("]"));

        // Return the corresponding value from the state object
        // The '*' is a wildcard which indicates ANY value will satisfy the depenency.
        const isWildcard: boolean = expressionValue === "*";
        const savedValue = questionnaireValues[key]?.value;
        const isArray: boolean = Array.isArray(savedValue);
        const hasValue: boolean = isWildcard
            ? savedValue?.length > 0 // wildcard checks for any value
            : isArray // otherwise check for match
            ? savedValue.includes(expressionValue)
            : savedValue === expressionValue;

        return `${hasValue}`;
    });

    // Evaluate the expression and return the result
    return eval(evaluatedExpression);
};

export function showQuestion(question: Question, questionnaireValues: QuestionnaireValues) {
    if (question.showIf && question.showIf.length > 0) {
        return evaluateExpression(question.showIf, questionnaireValues);
    } else {
        return true;
    }
}

export function getSectionStatus(sectionQuestions: Question[], questionnaireValues: QuestionnaireValues) {
    return {
        // It is considered complete if everything that is shown has a value and if it is modified it is also saved.
        complete: sectionQuestions.every(question => {
            const shown = showQuestion(question, questionnaireValues);
            const value = questionnaireValues[question.id]?.value;
            const isModified = questionnaireValues[question.id]?.isModified;
            const isSaved = questionnaireValues[question.id]?.isSaved;
            return !shown || (shown && value && value.length > 0 && (!isModified || isSaved));
        }),
    };
}

export function showCheckmark(value: SyncableValue) {
    return value?.isModified && value?.value && value?.isSaved;
}

export function showXmark(value: SyncableValue) {
    return value?.errorMessage || (value?.isModified && !value?.isSyncing && (!value?.value || !value.value.length));
}

/**
 * @param minDate - The format of the date should be yyyy-MM-dd
 * @param disableFuture
 */
function getYearsArray(minDate?: string, disableFuture?: boolean) {
    const latestYear = disableFuture ? new Date().getFullYear() : new Date().getFullYear() + 50;
    const earliestYear = minDate ? new Date(minDate).getFullYear() : latestYear - 50;
    return Array.from({ length: latestYear - earliestYear }, (_, i) => latestYear - i);
}
export const getYears = memoize(getYearsArray);
