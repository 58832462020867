﻿import { FunctionComponent, useCallback, useMemo, useState } from "react";
import {
    Box,
    Button,
    ButtonProps,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    useBoolean,
    useSteps,
    VStack,
} from "@chakra-ui/react";
import { UploadStepBody, UploadStepBodyProps } from "./UploadStepBody";
import { ValidationStepBody, ValidationStepBodyProps } from "./ValidationStepBody";
import { CompleteStepBody, CompleteStepBodyProps } from "./CompleteStepBody";
import { CommonStepProps } from "./CommonStepProps";
import { CandidateImportResponse, ExcelImportError } from "../../../../api/models/ExcelImportError";
import { AxiosError } from "axios";
import { axiosInstance } from "../../../../utils";
// import { useClientEntityCandidateBulkUpload } from "../../../../api";

export type ImportCandidatesModalProps = Pick<ModalProps, "isOpen" | "onClose"> & {
    clientId: string;
    entityId: string;
};

export const ImportCandidatesModal: FunctionComponent<ImportCandidatesModalProps> = props => {
    const { clientId, entityId, ...modalProps } = props;

    const axios = axiosInstance();

    const [selectedFile, setSelectedFile] = useState<File>();
    const [validationErrors, setValidationErrors] = useState<ExcelImportError[]>([]);
    const [, setIsUploading] = useBoolean(false),
        [, setIsUploaded] = useBoolean(false),
        [, setIsValid] = useBoolean(false);

    // const bulkUpload = useClientEntityCandidateBulkUpload(clientId, entityId);

    const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
        index: 0,
        count: importSteps.length,
    });

    const resetForm = useCallback(() => {
        setSelectedFile(undefined);
        setIsValid.off();
        setIsUploading.off();
        setIsUploaded.off();
        setValidationErrors([]);
        setActiveStep(0);
    }, [setActiveStep, setIsUploaded, setIsUploading, setIsValid]);

    const modal = {
        ...modalProps,
        size: "4xl",
        onClose: () => {
            resetForm();
            modalProps.onClose();
        },
    };

    const stepProps: CommonStepProps = {
        activeStep,
        goToNext,
        goToPrevious,
        entityId,
        clientId,
    };

    const stepBody = useMemo(() => {
            switch (activeStep) {
                case 0: {
                    const uploadProps: UploadStepBodyProps = {
                        ...stepProps,
                        selectedFile,
                        setSelectedFile,
                    };

                    return <UploadStepBody {...uploadProps} />;
                }
                case 1: {
                    const validationProps: ValidationStepBodyProps = {
                        ...stepProps,
                        validationErrors,
                    };
                    return <ValidationStepBody {...validationProps} />;
                }
                case 2: {
                    const completeProps: CompleteStepBodyProps = {
                        ...stepProps,
                    };
                    return <CompleteStepBody {...completeProps} />;
                }
            }
        }, [activeStep, stepProps, selectedFile, setSelectedFile]),
        stepActionButton = useMemo((): ButtonProps => {
            switch (activeStep) {
                case 0:
                    return {
                        onClick: async () => {
                            if (selectedFile) {
                                goToNext();

                                const formData = new FormData();
                                formData.append("file", selectedFile);

                                axios
                                    .post<CandidateImportResponse>(`/api/clients/${clientId}/entities/${entityId}/candidates/Bulk`, formData, {
                                        headers: {
                                            "Content-Type": "multipart/form-data",
                                        },
                                    })
                                    .then((): CandidateImportResponse => {
                                        setIsUploaded.on();
                                        return {
                                            errors: [],
                                            rowCount: 0,
                                        };
                                    })
                                    .catch(err => {
                                        setIsUploaded.on();

                                        const error = err as AxiosError<{ message: string; errors: ExcelImportError[] }, any>;

                                        if (error.response && error.response.data) {
                                            const errors = error.response.data.errors;
                                            return {
                                                errors,
                                                rowCount: 0,
                                            };
                                        }

                                        return {
                                            errors: [
                                                {
                                                    message: "Unknown issue occurred",
                                                    row: -1,
                                                    column: "",
                                                },
                                            ],
                                            rowCount: 0,
                                        };
                                    })
                                    .then(response => {
                                        setValidationErrors(response.errors);
                                        if (response.errors.length > 0) {
                                            setIsValid.on();
                                        } else {
                                            setIsValid.off();
                                            goToNext();
                                        }
                                    });
                            }
                        },
                        children: "Upload",
                        isDisabled: selectedFile === undefined,
                    };
                case 1:
                    return {
                        onClick: resetForm,
                        children: "Reset",
                    };
                case 2:
                    return {
                        onClick: modal.onClose,
                        children: "Finish",
                    };
                default:
                    return {
                        onClick: goToNext,
                        children: "Next",
                    };
            }
        }, [activeStep, selectedFile, resetForm, modal.onClose, goToNext, axios, clientId, entityId, setIsUploaded, setIsValid]);

    const closeModalButton: ButtonProps = {
        children: "Cancel",
        onClick: modal.onClose,
    };

    const showCancelButton = activeStep <= 1;

    return (
        <Modal {...modal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Import candidates</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={8}>
                        <Stepper index={activeStep} colorScheme={"blue"} width={"100%"} paddingX={4}>
                            {importSteps.map((step, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                                    </StepIndicator>

                                    <Box flexShrink="0">
                                        <StepTitle>{step.title}</StepTitle>
                                        <StepDescription>{step.description}</StepDescription>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>

                        {stepBody}
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    {showCancelButton && <Button variant="ghost" {...closeModalButton} />}
                    <Button colorScheme="blue" {...stepActionButton} />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const importSteps = [
    { title: "Upload", description: "Select file" },
    { title: "Validation", description: "Review errors" },
    { title: "Complete", description: "Options and next steps" },
];
