﻿import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../../utils";
import { queryKeys } from "../queryKeys";
import { EntityCandidateQualification } from "../models/EntityCandidate";

export function useCandidateQualifications(candidateId: string | undefined, tenantId: string | undefined, entityId: string | undefined) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.candidateQualification.byCandidateId(candidateId),
        queryFn: () =>
            axios
                .get<EntityCandidateQualification[]>(`api/clients/${tenantId}/entities/${entityId}/candidates/${candidateId}/CandidateQualifications`)
                .then(response => response.data),
    });
}
