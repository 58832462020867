﻿import { FunctionComponent, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Flex,
    Spinner,
    Stack,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { TenantResponse, useDeletedTenants, useRestoreTenant } from "../../api";
import { ConfirmationDialog, ConfirmationDialogProps } from "../../components/ConfirmationDialog";

export type TenantRestoreDrawerProps = Pick<DrawerProps, "isOpen" | "onClose">;
export const TenantRestoreDrawer: FunctionComponent<TenantRestoreDrawerProps> = props => {
    const { ...drawerProps } = props;

    const tenantQuery = useDeletedTenants({ enabled: drawerProps.isOpen }),
        restoreTenant = useRestoreTenant();

    const [selectedTenant, setSelectedTenant] = useState<TenantResponse>();

    const restoreDialog = useDisclosure(),
        confirmationDialog: ConfirmationDialogProps = {
            isOpen: restoreDialog.isOpen,
            onClose: restoreDialog.onClose,
            title: "Restore Client",
            confirmLabel: "Restore",
            isDisabled: restoreTenant.isLoading,
            onConfirm: () => {
                if (selectedTenant?.id) {
                    restoreTenant.mutate(selectedTenant?.id);
                    restoreDialog.onClose();
                    setSelectedTenant(undefined);
                }
            },
        };

    useEffect(() => {
        restoreDialog.onClose();
    }, [drawerProps.isOpen]);

    return (
        <Drawer {...drawerProps} placement="right" size="lg">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <Stack direction="row" align="center">
                        <Text>Deleted Clients</Text>
                    </Stack>
                </DrawerHeader>

                <DrawerBody>
                    {tenantQuery.isLoading ? (
                        <Spinner />
                    ) : (
                        <Stack gap="2">
                            {(tenantQuery.data ?? []).map(tenant => (
                                <Card key={tenant.id} variant="outline">
                                    <CardBody>
                                        <Flex alignItems="center" justify="space-between">
                                            <VStack align="start">
                                                <span>
                                                    <Text as="b">{tenant.name}</Text>
                                                    <Text as="samp">{tenant.externalId ? ` (${tenant.externalId})` : undefined}</Text>
                                                </span>
                                                {tenant.description ? <Text>{tenant.description}</Text> : <Text as="em">no description</Text>}
                                            </VStack>
                                            <Button
                                                variant="outline"
                                                onClick={() => {
                                                    setSelectedTenant(tenant);
                                                    restoreDialog.onOpen();
                                                }}
                                            >
                                                Restore
                                            </Button>
                                        </Flex>
                                    </CardBody>
                                </Card>
                            ))}
                        </Stack>
                    )}
                </DrawerBody>
            </DrawerContent>
            <ConfirmationDialog {...confirmationDialog}>
                <p>
                    This will re-enable all access to <strong>{selectedTenant?.name}</strong>.
                </p>
                <br />
                <p>
                    Are you <em>sure</em>?
                </p>
            </ConfirmationDialog>
        </Drawer>
    );
};
