﻿import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { Operation } from "rfc6902/diff";
import { axiosInstance } from "../../utils";

export function useQuestionnaireSaveValues(clientId: string | undefined, entityId: string | undefined, candidateId: string | undefined) {
    const axios = axiosInstance();
    const queryClient = useQueryClient();
    return useMutation(
        async (operations: Operation[]) => {
            if (!clientId || !entityId || !candidateId) {
                throw new Error("Missing required parameters");
            }
            const response = await axios.patch<Record<string, string>>(
                `api/clients/${clientId}/entities/${entityId}/candidates/${candidateId}/questionnaire`,
                operations
            );
            return response.data;
        },
        {
            onSuccess: async data => {
                queryClient.setQueryData(queryKeys.questionnaire.values(candidateId, entityId, candidateId).queryKey, data);
            },
            meta: {
                errorMessage: "Unable to save selection",
            },
        }
    );
}
