﻿import { FunctionComponent } from "react";
import { FormControl, FormHelperText, FormLabel, Input, VStack } from "@chakra-ui/react";
import { CommonStepProps } from "./CommonStepProps";

export type UploadStepBodyProps = CommonStepProps & {
    selectedFile: File | undefined;
    setSelectedFile: (file: File) => void;
};
export const UploadStepBody: FunctionComponent<UploadStepBodyProps> = props => {
    const { setSelectedFile } = props;
    return (
        <VStack>
            <FormControl>
                <FormLabel>Select file</FormLabel>
                <Input
                    type="file"
                    accept={".xlsx"}
                    onChange={event => {
                        if (event && event.target && event.target.files && event.target.files.length > 0) {
                            setSelectedFile(event.target.files[0]);
                        }
                    }}
                />
                <FormHelperText>Import candidates using the supplied template.</FormHelperText>
            </FormControl>
        </VStack>
    );
};
