﻿import { EntityResponse } from "../../../../api";
import { FunctionComponent } from "react";
import { Badge, Box, HStack, Icon, IconButton, Text, Tooltip, useDisclosure, VStack } from "@chakra-ui/react";
import { IconBuilding, IconChevronDown, IconChevronRight, IconFolder, IconPlus } from "@tabler/icons-react";
import { useEntityTreeContext } from "../EntityTreeContext";
import { sortBy } from "lodash";

export type EntityTreeItemProps = {
    entity: EntityResponse;
};

export const EntityTreeItem: FunctionComponent<EntityTreeItemProps> = props => {
    const { entity } = props;
    const { onAdd, hoveringEntityId, onMouseEnter, onMouseLeave, isEditable, selectedEntity, pathToSelected, setSelectedEntityId, isExpandAll } =
        useEntityTreeContext();
    const isSelected = selectedEntity?.id === entity.id;
    const isHovering = hoveringEntityId === entity.id;

    const isDirectory = entity.entityType === "Directory",
        hasChildren = isDirectory ? entity.children.length > 0 : false;

    const expander = useDisclosure({ defaultIsOpen: isExpandAll || pathToSelected.includes(entity) });

    const toggleExpansion = isDirectory ? expander.onToggle : undefined;

    const childEntities = sortBy(entity.children, i => i.name).map(child => ({
        key: child.id,
        ...props,
        entity: child,
    }));

    return (
        <HStack align="start">
            {/* Directory Icon */}
            <Box cursor={toggleExpansion ? "pointer" : undefined}>
                {isDirectory && (
                    <IconButton
                        variant={"ghost"}
                        aria-label={`${expander.isOpen ? "expand" : "collapse"} tree item`}
                        icon={<Icon as={expander.isOpen ? IconChevronDown : IconChevronRight} />}
                        onClick={toggleExpansion}
                    />
                )}
            </Box>

            {/* Entity Entry and Children */}
            <VStack align="start" spacing={0}>
                {/* Wrapper around Entity and add button */}
                <HStack align="stretch">
                    {/* Entity Entry */}
                    <HStack
                        onMouseEnter={() => onMouseEnter(entity.id)}
                        onMouseLeave={() => onMouseLeave()}
                        p={".5rem"}
                        sx={{
                            cursor: "pointer",
                            ...(isSelected && {
                                backgroundColor: "gray.100",
                                borderRadius: "md",
                                fontWeight: "bold",
                            }),
                            ...(isHovering && {
                                backgroundColor: "gray.100",
                                borderRadius: "md",
                                fontWeight: "bold",
                                transitionDuration: "var(--chakra-transition-duration-normal)",
                            }),
                        }}
                        onClick={() => {
                            setSelectedEntityId(entity.id);
                        }}
                    >
                        {isDirectory ? <Icon as={IconFolder} /> : <Icon as={IconBuilding} />}
                        <Text style={{ userSelect: "none" }}>{entity.name}</Text>
                        {!isDirectory && entity.candidateCount > 0 && (
                            <Tooltip label={`${entity.candidateCount} candidates`}>
                                <Badge>{entity.candidateCount}</Badge>
                            </Tooltip>
                        )}
                    </HStack>

                    {/* The Add Button */}
                    {isEditable && isDirectory && (
                        <IconButton
                            variant={"ghost"}
                            aria-label="add item as child"
                            icon={<Icon as={IconPlus} />}
                            onClick={e => {
                                expander.onOpen();
                                onAdd(entity.id);
                            }}
                        />
                    )}
                </HStack>

                {/* Entity Children */}
                {hasChildren && isDirectory && <>{expander.isOpen && childEntities.map(child => <EntityTreeItem {...child} />)}</>}
                {!hasChildren && isDirectory && expander.isOpen && <Box color={"blackAlpha.500"}>Empty</Box>}
                {isDirectory && expander.isOpen && <Box boxSize={".5rem"}></Box>}
            </VStack>
        </HStack>
    );
};
