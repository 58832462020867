﻿import { FunctionComponent } from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { CommonStepProps } from "./CommonStepProps";
import { ExcelImportError } from "../../../../api/models/ExcelImportError";

export type ValidationStepBodyProps = CommonStepProps & {
    validationErrors: ExcelImportError[];
};
export const ValidationStepBody: FunctionComponent<ValidationStepBodyProps> = props => {
    const { validationErrors } = props;
    return (
        <VStack>
            <Text>Validation issues:</Text>
            {validationErrors?.map((err, ix) => (
                <HStack key={ix}>
                    <Text>
                        {err.column}:{err.row}
                    </Text>
                    <Text color="red">{err.message}</Text>
                </HStack>
            ))}
        </VStack>
    );
};
