﻿import { Fragment, FunctionComponent, PropsWithChildren } from "react";
import { useHasAccess } from "../../hooks/useHasAccess";
import { ApplicationRole } from "../../api";

export interface RequireRoleProps extends PropsWithChildren {
    role: ApplicationRole;
}

export const RequireRole: FunctionComponent<RequireRoleProps> = ({ role, children }) => {
    const { hasRole } = useHasAccess();
    const hasRequiredRole = hasRole(role);

    return hasRequiredRole ? <Fragment>{children}</Fragment> : null;
};
