﻿import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { Questionnaire } from "../../common";
import { axiosInstance } from "../../utils";

export function useQuestionnaireDefinition() {
    const axios = axiosInstance();

    return useQuery(queryKeys.questionnaire.definition.queryKey, () =>
        axios.get<Questionnaire>(`/api/questionnaire/definition`).then(response => response.data)
    );
}
