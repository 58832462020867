import { AxiosInstance } from "axios";

let instance: AxiosInstance;
export function setAxiosInstance(axiosInstance: AxiosInstance) {
    instance = axiosInstance;
}

export function axiosInstance(): AxiosInstance {
    return instance;
}
