﻿import { EntityResponse } from "../../../../api";

export function findEntityById(entities: EntityResponse[], id: string | undefined): [EntityResponse | undefined, string[]] {
    if (id === undefined || !entities || entities.length === 0) {
        return [undefined, []];
    }

    for (const entity of entities) {
        if (entity.id === id) {
            return [entity, [entity.id]];
        }

        const child = findEntityById(entity.children, id);
        if (child[0]) {
            return [child[0], [entity.id, ...child[1]]];
        }
    }

    return [undefined, []];
}
