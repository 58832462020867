﻿import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryKeys } from "../queryKeys";
import { EntityResponse } from "../models";
import { axiosInstance } from "../../utils";

export function useClientEntity(clientId: string, entityId: string, options: Partial<UseQueryOptions<EntityResponse>>) {
    const axios = axiosInstance();
    return useQuery({
        ...queryKeys.clients.entityById(clientId, entityId),
        queryFn: () => axios.get<EntityResponse>(`api/Clients/${clientId}/entities/${entityId}`).then(response => response.data),
        ...options,
    });
}
