﻿import BgImage from "../../assets/images/C03C17CF-9B64-4EBC-A446-3AF2DA16AC84.jpg";
import { FC, useState } from "react";
import {
    Box,
    ButtonProps,
    Card,
    CardBody,
    CardHeader,
    Center,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    Link,
    StackDivider,
    Text,
} from "@chakra-ui/react";
import { AMTaxandLogoWhite } from "../../components/Brand";
import { SignInButton, SignInButtonProps } from "../../components/SignInButton";
import { IconAlertTriangle } from "@tabler/icons-react";

export const LandingPage = () => {
    return (
        <Flex bgImage={`url(${BgImage})`} bgSize="cover" height="100vh" justify="stretch" direction="column" data-id="1">
            <Grid templateColumns="repeat(3,1fr)" w="100%" h="100%" data-id="2">
                <GridItem colSpan={2} data-id="4">
                    <Grid templateColumns="repeat(4,1fr)" w="100%" h="100%" data-id="5" backgroundImage="linear(90deg,rgba(0,0,0,0.5),transparent)">
                        <GridItem /> {/* SPACER */}
                        <GridItem colSpan={2} data-id="7">
                            <Flex align="flex-start" justify="center" direction="column" height="100%" px="8" gap="8" data-id="8">
                                <Box>
                                    <AMTaxandLogoWhite width="128px" />
                                </Box>
                                <Box
                                    sx={{
                                        color: "white",
                                        fontFamily: "DIN Condensed",
                                        fontSize: "48px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    {import.meta.env.VITE_BRAND_NAME}
                                </Box>

                                <Divider width="50px" style={{ borderWidth: "2px", color: "white", opacity: 0.8 }} />

                                <Box
                                    sx={{
                                        color: "white",
                                    }}
                                >
                                    <p>The one-stop platform for all your WOTC needs.</p>
                                    <p>
                                        <strong>
                                            {import.meta.env.VITE_BRAND_NAME_SHORT} by {import.meta.env.VITE_BRAND_COMPANY}
                                        </strong>
                                        .
                                    </p>
                                </Box>
                            </Flex>
                        </GridItem>
                        <GridItem />
                    </Grid>
                </GridItem>
                <GridItem bg="white">
                    <Flex align="center" justify="center" direction="column" height="100%" px="8" gap="8">
                        <SignIn />
                        <Text>All information securely saved as per the Terms of Service & Privacy Policy</Text>
                    </Flex>
                </GridItem>
            </Grid>
            <Grid templateColumns="repeat(3,1fr)" w="100%">
                <GridItem
                    colSpan={2}
                    sx={{
                        borderTop: "2px solid",
                        borderTopColor: "white",
                        paddingY: 4,
                        paddingX: 4,
                        fontSize: "sm",
                    }}
                    backgroundImage="linear(90deg,rgba(0,0,0,0.5),transparent)"
                >
                    <HStack direction="row">
                        <Text color="whiteAlpha.800">© Alvarez & Marsal Holdings, LLC. All Rights Reserved.</Text>
                    </HStack>
                </GridItem>

                <GridItem
                    bg="white"
                    sx={{
                        borderTop: "2px solid",
                        borderTopColor: "blue.500",
                        paddingY: 4,
                        paddingX: 4,
                        fontSize: "sm",
                    }}
                >
                    <HStack
                        divider={<StackDivider />}
                        justify="flex-end"
                        sx={{
                            textTransform: "uppercase",
                        }}
                    >
                        <Link color="blue.500">Privacy Notice</Link>
                        <Link color="blue.500">Privacy Shield Notice</Link>
                        <Link color="blue.500">California Privacy NOtice</Link>
                        <Link color="blue.500">Cookie Notice</Link>
                        <Link color="blue.500">Terms of Use</Link>
                    </HStack>
                </GridItem>
            </Grid>
        </Flex>
    );
};

const SignIn: FC = () => {
    const [errorMessage, setErrorMessage] = useState<string>();

    const baseButton: ButtonProps = {
            size: "lg",
            width: "200px",
            fontFamily: "DIN Condensed",
            fontWeight: 50,
            textTransform: "uppercase",
            borderRadius: 0,
        },
        signInButton: SignInButtonProps = {
            ...baseButton,
            onError: setErrorMessage,
            variant: "solid",
        };

    return (
        <Flex direction="column" gap="2" align="stretch" justify="center">
            <SignInButton {...signInButton} />
            <AuthErrorMessage text={errorMessage} />
        </Flex>
    );
};

const AuthErrorMessage: FC<{ text?: string }> = ({ text }) => {
    return (
        <>
            {text && text.length > 0 && (
                <Card>
                    <CardHeader>
                        <Heading size="sm" color="red">
                            <HStack>
                                <IconAlertTriangle />
                                <span>Authentication Error</span>
                            </HStack>
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <Center>
                            <Text color="red">&nbsp;{text}</Text>
                        </Center>
                    </CardBody>
                </Card>
            )}
        </>
    );
};
