﻿export type Candidate = {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    email: string;
    socialSecurityNumber: string;
    dateOfBirth: string;
    street1: string;
    street2?: string;
    city: string;
    county: string;
    state: string;
    zipCode: string;
    optOut: boolean;
};

export interface CandidateArgs extends Candidate {
    forceAddress?: boolean;
}

export interface Questionnaire {
    sections: Section[];
    questions: Question[];
}

export interface Section {
    id: string;
    name: string;
    number: number;
    description: string;
}

export interface Option {
    value: string;
    label: string;
}

export interface Question {
    id: string;
    sectionId: string;
    order: number;
    showIf?: string | null;
    label?: string;
    prompt?: string;
    helpText?: string;
    input: InputText | InputRadio | InputSelect | InputDate | InputYear;
}

export enum InputType {
    text = "Text",
    radio = "Radio",
    select = "Select",
    date = "Date",
    year = "Year",
}

export interface InputBase {
    type: InputType;
}

export interface InputText extends InputBase {
    type: InputType.text;
}

export interface InputRadio extends InputBase {
    type: InputType.radio;
    options: Option[];
}

export interface InputSelect extends InputBase {
    type: InputType.select;
    options: Option[];
}

export interface InputDate extends InputBase {
    type: InputType.date;
    inputOptions?: { minDate?: string | null ; disableFuture?: boolean | null };
}

export interface InputYear extends InputBase {
    type: InputType.year;
    inputOptions?: { minDate?: string; disableFuture?: boolean };
}

export const sections: Section[] = [
    {
        "id": "employmentHistory",
        "name": "Employment History",
        "number": 0,
        "description": "Employment History"
    },
    {
        "id": "veteran",
        "name": "Veteran Details",
        "number": 1,
        "description": "Veteran Details"
    },
    {
        "id": "snap",
        "name": "SNAP Details",
        "number": 2,
        "description": "Supplemental Nutrition Assistance Program (SNAP) details"
    },
    {
        "id": "tanf",
        "name": "TANF Details",
        "number": 3,
        "description": "Temporary Assistance for Needy Families (TANF) details"
    },
    {
        "id": "felonyConvictions",
        "name": "Felony Convictions",
        "number": 4,
        "description": "Federal or State Convictions"
    },
    {
        "id": "references",
        "name": "References",
        "number": 5,
        "description": "References"
    },
    {
        "id": "ssi",
        "name": "SSI Details",
        "number": 6,
        "description": "Supplemental Security Income (SSI) details"
    },
    {
        "id": "unemploymentRecipient",
        "name": "Unemployment Recipient",
        "number": 7,
        "description": "Long-Term Unemployment Recipient Details"
    }
];

export const questions: Question[] = [
    {
        "id": "employmentHistoryIsRehire",
        "sectionId": "employmentHistory",
        "order": 1,
        "showIf": null,
        "label": "",
        "prompt": "Have you worked for this employer before?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "employmentHistoryDateOfEmployment",
        "sectionId": "employmentHistory",
        "order": 2,
        "showIf": "employmentHistoryIsRehire[yes]",
        "label": "Last date of employment",
        "prompt": "Enter date of last day of employment.",
        "helpText": "",
        "input": {
            "type": InputType.date,
            inputOptions: { minDate: "2022-01-01", disableFuture: true }
        }
    },
    {
        "id": "veteranIsVeteran",
        "sectionId": "veteran",
        "order": 1,
        "showIf": "employmentHistoryIsRehire[no] || employmentHistoryDateOfEmployment[*]",
        "label": "",
        "prompt": "Are you a Veteran of the U.S. Armed Forces?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "veteranIsSnap3Mo",
        "sectionId": "veteran",
        "order": 2,
        "showIf": "veteranIsVeteran[yes]",
        "label": "",
        "prompt": "Are you a member of a family that received Supplemental Nutrition Assistance Program (SNAP) benefits (Food Stamps) for at least 3 months during the 15 months before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "veteranSnap3MoPrimary",
        "sectionId": "veteran",
        "order": 3,
        "showIf": "veteranIsSnap3Mo[yes]",
        "label": "Primary Recipient",
        "prompt": "Provide the primary recipient name, city and state where the benefits were received.",
        "helpText": "",
        "input": {
            "type": InputType.text
        }
    },
    {
        "id": "veteranSnap3MoCity",
        "sectionId": "veteran",
        "order": 4,
        "showIf": "veteranIsSnap3Mo[yes]",
        "label": "City",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.text
        }
    },
    {
        "id": "veteranSnap3MoState",
        "sectionId": "veteran",
        "order": 5,
        "showIf": "veteranIsSnap3Mo[yes]",
        "label": "State",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.select,
            "options": [
                {
                    "value": "AL",
                    "label": "Alabama"
                },
                {
                    "value": "AK",
                    "label": "Alaska"
                },
                {
                    "value": "AZ",
                    "label": "Arizona"
                },
                {
                    "value": "AR",
                    "label": "Arkansas"
                },
                {
                    "value": "CA",
                    "label": "California"
                },
                {
                    "value": "CO",
                    "label": "Colorado"
                },
                {
                    "value": "CT",
                    "label": "Connecticut"
                },
                {
                    "value": "DC",
                    "label": "DistrictofColumbia"
                },
                {
                    "value": "DE",
                    "label": "Delaware"
                },
                {
                    "value": "FL",
                    "label": "Florida"
                },
                {
                    "value": "GA",
                    "label": "Georgia"
                },
                {
                    "value": "HI",
                    "label": "Hawaii"
                },
                {
                    "value": "ID",
                    "label": "Idaho"
                },
                {
                    "value": "IL",
                    "label": "Illinois"
                },
                {
                    "value": "IN",
                    "label": "Indiana"
                },
                {
                    "value": "IA",
                    "label": "Iowa"
                },
                {
                    "value": "KS",
                    "label": "Kansas"
                },
                {
                    "value": "KY",
                    "label": "Kentucky"
                },
                {
                    "value": "LA",
                    "label": "Louisiana"
                },
                {
                    "value": "ME",
                    "label": "Maine"
                },
                {
                    "value": "MD",
                    "label": "Maryland"
                },
                {
                    "value": "MA",
                    "label": "Massachusetts"
                },
                {
                    "value": "MI",
                    "label": "Michigan"
                },
                {
                    "value": "MN",
                    "label": "Minnesota"
                },
                {
                    "value": "MS",
                    "label": "Mississippi"
                },
                {
                    "value": "MO",
                    "label": "Missouri"
                },
                {
                    "value": "MT",
                    "label": "Montana"
                },
                {
                    "value": "NE",
                    "label": "Nebraska"
                },
                {
                    "value": "NV",
                    "label": "Nevada"
                },
                {
                    "value": "NH",
                    "label": "NewHampshire"
                },
                {
                    "value": "NJ",
                    "label": "NewJersey"
                },
                {
                    "value": "NM",
                    "label": "NewMexico"
                },
                {
                    "value": "NY",
                    "label": "NewYork"
                },
                {
                    "value": "NC",
                    "label": "NorthCarolina"
                },
                {
                    "value": "ND",
                    "label": "NorthDakota"
                },
                {
                    "value": "OH",
                    "label": "Ohio"
                },
                {
                    "value": "OK",
                    "label": "Oklahoma"
                },
                {
                    "value": "OR",
                    "label": "Oregon"
                },
                {
                    "value": "PA",
                    "label": "Pennsylvania"
                },
                {
                    "value": "RI",
                    "label": "RhodeIsland"
                },
                {
                    "value": "SC",
                    "label": "SouthCarolina"
                },
                {
                    "value": "SD",
                    "label": "SouthDakota"
                },
                {
                    "value": "TN",
                    "label": "Tennessee"
                },
                {
                    "value": "TX",
                    "label": "Texas"
                },
                {
                    "value": "UT",
                    "label": "Utah"
                },
                {
                    "value": "VT",
                    "label": "Vermont"
                },
                {
                    "value": "VA",
                    "label": "Virginia"
                },
                {
                    "value": "WA",
                    "label": "Washington"
                },
                {
                    "value": "WV",
                    "label": "WestVirginia"
                },
                {
                    "value": "WI",
                    "label": "Wisconsin"
                },
                {
                    "value": "WY",
                    "label": "Wyoming"
                }
            ],
        }
    },
    {
        "id": "veteranIsDisabled",
        "sectionId": "veteran",
        "order": 6,
        "showIf": "veteranIsSnap3Mo[no] || (veteranSnap3MoPrimary[*] && veteranSnap3MoCity[*] && veteranSnap3MoState[*])",
        "label": "",
        "prompt": "Are you a veteran entitled to compensation for a service-connected disability?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "veteranIsDisabledDischarged",
        "sectionId": "veteran",
        "order": 7,
        "showIf": "veteranIsDisabled[yes]",
        "label": "",
        "prompt": "Were you discharged or released from active duty within a year before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "veteranIsUnemployedLong",
        "sectionId": "veteran",
        "order": 8,
        "showIf": "veteranIsDisabled[no] || veteranIsDisabledDischarged[*]",
        "label": "",
        "prompt": "Were you unemployed for a combined period of at least 6 months (whether or not consecutive) during the year before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "veteranIsUnemployedShort",
        "sectionId": "veteran",
        "order": 9,
        "showIf": "veteranIsUnemployedLong[*]",
        "label": "",
        "prompt": "Were you unemployed for a combined period of at least 4 weeks but less than 6 months (whether or not consecutive) during the year before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "snapIs6Mo",
        "sectionId": "snap",
        "order": 1,
        "showIf": "veteranIsUnemployedShort[*] || veteranIsVeteran[no]",
        "label": "",
        "prompt": "Are you a member of a family that received Supplemental Nutrition Assistance Program (SNAP) (formerly Food Stamps) benefits for the 6 months before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "snapIs3Mo5Mo",
        "sectionId": "snap",
        "order": 2,
        "showIf": "snapIs6Mo[*]",
        "label": "",
        "prompt": "Have you received SNAP benefits for at least a 3-month period within the last 5 months, but you are no longer receiving them?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "snap3Mo5MoPrimary",
        "sectionId": "snap",
        "order": 3,
        "showIf": "snapIs3Mo5Mo[yes]",
        "label": "Primary Recipient",
        "prompt": "Provide the primary recipient name, city and state where the benefits were received.",
        "helpText": "",
        "input": {
            "type": InputType.text
        }
    },
    {
        "id": "snap3Mo5MoCity",
        "sectionId": "snap",
        "order": 4,
        "showIf": "snapIs3Mo5Mo[yes]",
        "label": "City",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.text
        }
    },
    {
        "id": "snap3Mo5MoState",
        "sectionId": "snap",
        "order": 5,
        "showIf": "snapIs3Mo5Mo[yes]",
        "label": "State",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.select,
            "options": [
                {
                    "value": "AL",
                    "label": "Alabama"
                },
                {
                    "value": "AK",
                    "label": "Alaska"
                },
                {
                    "value": "AZ",
                    "label": "Arizona"
                },
                {
                    "value": "AR",
                    "label": "Arkansas"
                },
                {
                    "value": "CA",
                    "label": "California"
                },
                {
                    "value": "CO",
                    "label": "Colorado"
                },
                {
                    "value": "CT",
                    "label": "Connecticut"
                },
                {
                    "value": "DC",
                    "label": "DistrictofColumbia"
                },
                {
                    "value": "DE",
                    "label": "Delaware"
                },
                {
                    "value": "FL",
                    "label": "Florida"
                },
                {
                    "value": "GA",
                    "label": "Georgia"
                },
                {
                    "value": "HI",
                    "label": "Hawaii"
                },
                {
                    "value": "ID",
                    "label": "Idaho"
                },
                {
                    "value": "IL",
                    "label": "Illinois"
                },
                {
                    "value": "IN",
                    "label": "Indiana"
                },
                {
                    "value": "IA",
                    "label": "Iowa"
                },
                {
                    "value": "KS",
                    "label": "Kansas"
                },
                {
                    "value": "KY",
                    "label": "Kentucky"
                },
                {
                    "value": "LA",
                    "label": "Louisiana"
                },
                {
                    "value": "ME",
                    "label": "Maine"
                },
                {
                    "value": "MD",
                    "label": "Maryland"
                },
                {
                    "value": "MA",
                    "label": "Massachusetts"
                },
                {
                    "value": "MI",
                    "label": "Michigan"
                },
                {
                    "value": "MN",
                    "label": "Minnesota"
                },
                {
                    "value": "MS",
                    "label": "Mississippi"
                },
                {
                    "value": "MO",
                    "label": "Missouri"
                },
                {
                    "value": "MT",
                    "label": "Montana"
                },
                {
                    "value": "NE",
                    "label": "Nebraska"
                },
                {
                    "value": "NV",
                    "label": "Nevada"
                },
                {
                    "value": "NH",
                    "label": "NewHampshire"
                },
                {
                    "value": "NJ",
                    "label": "NewJersey"
                },
                {
                    "value": "NM",
                    "label": "NewMexico"
                },
                {
                    "value": "NY",
                    "label": "NewYork"
                },
                {
                    "value": "NC",
                    "label": "NorthCarolina"
                },
                {
                    "value": "ND",
                    "label": "NorthDakota"
                },
                {
                    "value": "OH",
                    "label": "Ohio"
                },
                {
                    "value": "OK",
                    "label": "Oklahoma"
                },
                {
                    "value": "OR",
                    "label": "Oregon"
                },
                {
                    "value": "PA",
                    "label": "Pennsylvania"
                },
                {
                    "value": "RI",
                    "label": "RhodeIsland"
                },
                {
                    "value": "SC",
                    "label": "SouthCarolina"
                },
                {
                    "value": "SD",
                    "label": "SouthDakota"
                },
                {
                    "value": "TN",
                    "label": "Tennessee"
                },
                {
                    "value": "TX",
                    "label": "Texas"
                },
                {
                    "value": "UT",
                    "label": "Utah"
                },
                {
                    "value": "VT",
                    "label": "Vermont"
                },
                {
                    "value": "VA",
                    "label": "Virginia"
                },
                {
                    "value": "WA",
                    "label": "Washington"
                },
                {
                    "value": "WV",
                    "label": "WestVirginia"
                },
                {
                    "value": "WI",
                    "label": "Wisconsin"
                },
                {
                    "value": "WY",
                    "label": "Wyoming"
                }
            ],
        }
    },
    {
        "id": "tanfIsTanf",
        "sectionId": "tanf",
        "order": 1,
        "showIf": "snapIs3Mo5Mo[no] || (snap3Mo5MoPrimary[*] && snap3Mo5MoCity[*] && snap3Mo5MoState[*])",
        "label": "",
        "prompt": "Are you a member of a family that has received TANF benefits in the past?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "tanfIs18Mo",
        "sectionId": "tanf",
        "order": 2,
        "showIf": "tanfIsTanf[yes]",
        "label": "",
        "prompt": "Did your family receive benefits for a period totaling 18 months (1 ½ years)?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "tanfYear",
        "sectionId": "tanf",
        "order": 3,
        "showIf": "tanfIs18Mo[yes]",
        "label": "",
        "prompt": "Please enter the last year your family received TANF benefits",
        "helpText": "",
        "input": {
            "type": InputType.year,
            inputOptions: { minDate: "1997-01-01", disableFuture: true },
        }
    },
    {
        "id": "tanfIs2YrMax",
        "sectionId": "tanf",
        "order": 4,
        "showIf": "tanfYear[*]",
        "label": "",
        "prompt": "Did your family stop being eligible for TANF assistance within 2 years before you were hired because a Federal or state law limited the maximum time those payments could be made?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "tanfIs9Mo",
        "sectionId": "tanf",
        "order": 5,
        "showIf": "tanfIs2YrMax[no]",
        "label": "",
        "prompt": "Are you a member of a family that received TANF assistance for any 9 months during the 18-month period before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "tanfPrimary",
        "sectionId": "tanf",
        "order": 6,
        "showIf": "tanfIs9Mo[yes] || tanfIs2YrMax[yes]",
        "label": "Primary Recipient",
        "prompt": "Provide the primary recipient name, city and state where the benefits were received.",
        "helpText": "",
        "input": {
            "type": InputType.text
        }
    },
    {
        "id": "tanfCity",
        "sectionId": "tanf",
        "order": 7,
        "showIf": "tanfIs9Mo[yes] || tanfIs2YrMax[yes]",
        "label": "City",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.text
        }
    },
    {
        "id": "tanfState",
        "sectionId": "tanf",
        "order": 8,
        "showIf": "tanfIs9Mo[yes] || tanfIs2YrMax[yes]",
        "label": "State",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.select,
            "options": [
                {
                    "value": "AL",
                    "label": "Alabama"
                },
                {
                    "value": "AK",
                    "label": "Alaska"
                },
                {
                    "value": "AZ",
                    "label": "Arizona"
                },
                {
                    "value": "AR",
                    "label": "Arkansas"
                },
                {
                    "value": "CA",
                    "label": "California"
                },
                {
                    "value": "CO",
                    "label": "Colorado"
                },
                {
                    "value": "CT",
                    "label": "Connecticut"
                },
                {
                    "value": "DC",
                    "label": "DistrictofColumbia"
                },
                {
                    "value": "DE",
                    "label": "Delaware"
                },
                {
                    "value": "FL",
                    "label": "Florida"
                },
                {
                    "value": "GA",
                    "label": "Georgia"
                },
                {
                    "value": "HI",
                    "label": "Hawaii"
                },
                {
                    "value": "ID",
                    "label": "Idaho"
                },
                {
                    "value": "IL",
                    "label": "Illinois"
                },
                {
                    "value": "IN",
                    "label": "Indiana"
                },
                {
                    "value": "IA",
                    "label": "Iowa"
                },
                {
                    "value": "KS",
                    "label": "Kansas"
                },
                {
                    "value": "KY",
                    "label": "Kentucky"
                },
                {
                    "value": "LA",
                    "label": "Louisiana"
                },
                {
                    "value": "ME",
                    "label": "Maine"
                },
                {
                    "value": "MD",
                    "label": "Maryland"
                },
                {
                    "value": "MA",
                    "label": "Massachusetts"
                },
                {
                    "value": "MI",
                    "label": "Michigan"
                },
                {
                    "value": "MN",
                    "label": "Minnesota"
                },
                {
                    "value": "MS",
                    "label": "Mississippi"
                },
                {
                    "value": "MO",
                    "label": "Missouri"
                },
                {
                    "value": "MT",
                    "label": "Montana"
                },
                {
                    "value": "NE",
                    "label": "Nebraska"
                },
                {
                    "value": "NV",
                    "label": "Nevada"
                },
                {
                    "value": "NH",
                    "label": "NewHampshire"
                },
                {
                    "value": "NJ",
                    "label": "NewJersey"
                },
                {
                    "value": "NM",
                    "label": "NewMexico"
                },
                {
                    "value": "NY",
                    "label": "NewYork"
                },
                {
                    "value": "NC",
                    "label": "NorthCarolina"
                },
                {
                    "value": "ND",
                    "label": "NorthDakota"
                },
                {
                    "value": "OH",
                    "label": "Ohio"
                },
                {
                    "value": "OK",
                    "label": "Oklahoma"
                },
                {
                    "value": "OR",
                    "label": "Oregon"
                },
                {
                    "value": "PA",
                    "label": "Pennsylvania"
                },
                {
                    "value": "RI",
                    "label": "RhodeIsland"
                },
                {
                    "value": "SC",
                    "label": "SouthCarolina"
                },
                {
                    "value": "SD",
                    "label": "SouthDakota"
                },
                {
                    "value": "TN",
                    "label": "Tennessee"
                },
                {
                    "value": "TX",
                    "label": "Texas"
                },
                {
                    "value": "UT",
                    "label": "Utah"
                },
                {
                    "value": "VT",
                    "label": "Vermont"
                },
                {
                    "value": "VA",
                    "label": "Virginia"
                },
                {
                    "value": "WA",
                    "label": "Washington"
                },
                {
                    "value": "WV",
                    "label": "WestVirginia"
                },
                {
                    "value": "WI",
                    "label": "Wisconsin"
                },
                {
                    "value": "WY",
                    "label": "Wyoming"
                }
            ],
        }
    },
    {
        "id": "felonyConvictionsIsFelon",
        "sectionId": "felonyConvictions",
        "order": 1,
        "showIf": "tanfIsTanf[no] || tanfIs18Mo[no] || tanfIs9Mo[no] || (tanfPrimary[*] && tanfCity[*] && tanfState[*])",
        "label": "",
        "prompt": "Were you convicted of a felony or released from prison after a felony conviction during the year before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ],
        }
    },
    {
        "id": "felonyConvictionsDateOfConviction",
        "sectionId": "felonyConvictions",
        "order": 2,
        "showIf": "felonyConvictionsIsFelon[yes]",
        "label": "Date of conviction",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.date,
            inputOptions: { minDate: "2022-01-01", disableFuture: true }
        }
    },
    {
        "id": "felonyConvictionsDateOfRelease",
        "sectionId": "felonyConvictions",
        "order": 3,
        "showIf": "felonyConvictionsIsFelon[yes]",
        "label": "Date of release",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.date,
            inputOptions: { minDate: "2022-01-01", disableFuture: true }
        }
    },
    {
        "id": "felonyConvictionsType",
        "sectionId": "felonyConvictions",
        "order": 4,
        "showIf": "felonyConvictionsIsFelon[yes]",
        "label": "Federal or State Conviction",
        "prompt": "",
        "helpText": "",
        "input": {
            "type": InputType.select,
            "options": [
                {
                    "value": "federal",
                    "label": "Federal"
                },
                {
                    "value": "state",
                    "label": "State"
                }
            ]
        }
    },
    {
        "id": "referencesIsVra",
        "sectionId": "references",
        "order": 1,
        "showIf": "felonyConvictionsIsFelon[no] || (felonyConvictionsDateOfConviction[*] && felonyConvictionsDateOfRelease[*] && felonyConvictionsType[*])",
        "label": "",
        "prompt": "Were you referred to an employer by a Vocational Rehabilitation Agency approved by a State?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ]
        }
    },
    {
        "id": "referencesIsTtw",
        "sectionId": "references",
        "order": 2,
        "showIf": "referencesIsVra[*]",
        "label": "",
        "prompt": "Were you referred to an employer by an Employment Network under the Ticket to Work Program?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ]
        }
    },
    {
        "id": "referencesIsDva",
        "sectionId": "references",
        "order": 3,
        "showIf": "referencesIsTtw[*]",
        "label": "",
        "prompt": "Were you referred to an employer by the Department of Veterans Affairs?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ]
        }
    },
    {
        "id": "ssiIsSsi",
        "sectionId": "ssi",
        "order": 1,
        "showIf": "referencesIsDva[*]",
        "label": "",
        "prompt": "Did you receive Supplemental Security Income (SSI) benefits for any month ending within 60 days before you were hired?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ]
        }
    },
    {
        "id": "unemploymentRecipientIsUnemploymentRecipient",
        "sectionId": "unemploymentRecipient",
        "order": 1,
        "showIf": "ssiIsSsi[*]",
        "label": "",
        "prompt": "Are you an individual who is or was in a period of unemployment that is at least 27 consecutive weeks and for all or part of that period you received unemployment compensation?",
        "helpText": "",
        "input": {
            "type": InputType.radio,
            "options": [
                {
                    "value": "yes",
                    "label": "Yes"
                },
                {
                    "value": "no",
                    "label": "No"
                }
            ]
        }
    },
    {
        "id": "unemploymentRecipientState",
        "sectionId": "unemploymentRecipient",
        "order": 2,
        "showIf": "unemploymentRecipientIsUnemploymentRecipient[yes]",
        "label": "State",
        "prompt": "What state did you receive unemployment compensation in?",
        "helpText": "",
        "input": {
            "type": InputType.select,
            "options": [
                {
                    "value": "AL",
                    "label": "Alabama"
                },
                {
                    "value": "AK",
                    "label": "Alaska"
                },
                {
                    "value": "AZ",
                    "label": "Arizona"
                },
                {
                    "value": "AR",
                    "label": "Arkansas"
                },
                {
                    "value": "CA",
                    "label": "California"
                },
                {
                    "value": "CO",
                    "label": "Colorado"
                },
                {
                    "value": "CT",
                    "label": "Connecticut"
                },
                {
                    "value": "DC",
                    "label": "DistrictofColumbia"
                },
                {
                    "value": "DE",
                    "label": "Delaware"
                },
                {
                    "value": "FL",
                    "label": "Florida"
                },
                {
                    "value": "GA",
                    "label": "Georgia"
                },
                {
                    "value": "HI",
                    "label": "Hawaii"
                },
                {
                    "value": "ID",
                    "label": "Idaho"
                },
                {
                    "value": "IL",
                    "label": "Illinois"
                },
                {
                    "value": "IN",
                    "label": "Indiana"
                },
                {
                    "value": "IA",
                    "label": "Iowa"
                },
                {
                    "value": "KS",
                    "label": "Kansas"
                },
                {
                    "value": "KY",
                    "label": "Kentucky"
                },
                {
                    "value": "LA",
                    "label": "Louisiana"
                },
                {
                    "value": "ME",
                    "label": "Maine"
                },
                {
                    "value": "MD",
                    "label": "Maryland"
                },
                {
                    "value": "MA",
                    "label": "Massachusetts"
                },
                {
                    "value": "MI",
                    "label": "Michigan"
                },
                {
                    "value": "MN",
                    "label": "Minnesota"
                },
                {
                    "value": "MS",
                    "label": "Mississippi"
                },
                {
                    "value": "MO",
                    "label": "Missouri"
                },
                {
                    "value": "MT",
                    "label": "Montana"
                },
                {
                    "value": "NE",
                    "label": "Nebraska"
                },
                {
                    "value": "NV",
                    "label": "Nevada"
                },
                {
                    "value": "NH",
                    "label": "NewHampshire"
                },
                {
                    "value": "NJ",
                    "label": "NewJersey"
                },
                {
                    "value": "NM",
                    "label": "NewMexico"
                },
                {
                    "value": "NY",
                    "label": "NewYork"
                },
                {
                    "value": "NC",
                    "label": "NorthCarolina"
                },
                {
                    "value": "ND",
                    "label": "NorthDakota"
                },
                {
                    "value": "OH",
                    "label": "Ohio"
                },
                {
                    "value": "OK",
                    "label": "Oklahoma"
                },
                {
                    "value": "OR",
                    "label": "Oregon"
                },
                {
                    "value": "PA",
                    "label": "Pennsylvania"
                },
                {
                    "value": "RI",
                    "label": "RhodeIsland"
                },
                {
                    "value": "SC",
                    "label": "SouthCarolina"
                },
                {
                    "value": "SD",
                    "label": "SouthDakota"
                },
                {
                    "value": "TN",
                    "label": "Tennessee"
                },
                {
                    "value": "TX",
                    "label": "Texas"
                },
                {
                    "value": "UT",
                    "label": "Utah"
                },
                {
                    "value": "VT",
                    "label": "Vermont"
                },
                {
                    "value": "VA",
                    "label": "Virginia"
                },
                {
                    "value": "WA",
                    "label": "Washington"
                },
                {
                    "value": "WV",
                    "label": "WestVirginia"
                },
                {
                    "value": "WI",
                    "label": "Wisconsin"
                },
                {
                    "value": "WY",
                    "label": "Wyoming"
                }
            ]
        }
    }
];

export interface SyncableValue {
    value: string;
    isSyncing?: boolean;
    isModified?: boolean;
    isSaved?: boolean;
    errorMessage?: string;
}

export type QuestionnaireValues = Record<string, SyncableValue>;
