﻿import { axiosInstance } from "../../utils";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";

export function useCandidateQualificationDelete() {
    const axios = axiosInstance();
    const toast = useToast();
    return useMutation({
        mutationFn: (request: { id: string; tenantId: string | undefined; entityId: string | undefined; candidateId: string | undefined }) => {
            const data = { id: request.id, deletedById: undefined };
            return axios
                .delete(`/api/clients/${request.tenantId}/entities/${request.entityId}/candidates/${request.candidateId}/CandidateQualifications/delete`, {
                    data,
                })
                .then(response => response.data);
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Unable to delete Candidate Qualification details.",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
