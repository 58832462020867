﻿import { FunctionComponent } from "react";
import { useUser, useUserTenants } from "../../../api/users";
import { TenantRole, useCreateUserTenantRole, useTenants } from "../../../api";
import {
    Button,
    ButtonProps,
    Card,
    CardBody,
    CardFooter,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Input,
    Radio,
    RadioGroup,
    Select,
    Skeleton,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { TenantRoleControl } from "./TenantRoleControl";
import { IconShieldPlus } from "@tabler/icons-react";
import { Controller, useForm } from "react-hook-form";
import { formatTimestampStr } from "@am-tax/fe-core";

export interface UserEditDrawerProps {
    userId: string;
    isOpen: boolean;
    onClose: () => void;
}

export const UserEditDrawer: FunctionComponent<UserEditDrawerProps> = props => {
    const { userId, isOpen, onClose } = props;

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Edit user</DrawerHeader>

                <DrawerBody as={Flex} gap={2} direction="column">
                    <Tabs>
                        <TabList>
                            <Tab>General</Tab>
                            <Tab>Access</Tab>
                        </TabList>

                        <TabPanels>
                            <UserEditGeneralTabPanel userId={userId} />
                            <UserEditAccessTabPanel userId={userId} />
                        </TabPanels>
                    </Tabs>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

const UserEditGeneralTabPanel: FunctionComponent<Pick<UserEditDrawerProps, "userId">> = ({ userId }) => {
    const { isLoading, data } = useUser(userId);

    return (
        <TabPanel>
            <Skeleton isLoaded={!isLoading}>
                <FormControl>
                    <FormLabel>Display name</FormLabel>
                    <Input value={data?.displayName ?? ""} isReadOnly />
                </FormControl>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input value={data?.email ?? ""} isReadOnly />
                </FormControl>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
                <FormControl>
                    <FormLabel>Created on</FormLabel>
                    <Input value={data ? formatTimestampStr(data.createdOn) : "--"} isReadOnly />
                </FormControl>
            </Skeleton>
        </TabPanel>
    );
};

const UserEditAccessTabPanel: FunctionComponent<Pick<UserEditDrawerProps, "userId">> = ({ userId }) => {
    const { isLoading, data } = useUserTenants(userId);

    return (
        <TabPanel>
            <Skeleton isLoaded={!isLoading} height={"20px"} />
            <Flex direction="column" gap={4}>
                <AddAccessFormCard userId={userId} />
                <Flex direction="column" gap={2}>
                    {(data || []).map(m => (
                        <TenantRoleControl key={m.tenantId} {...m} />
                    ))}
                </Flex>
            </Flex>
        </TabPanel>
    );
};

const AddAccessFormCard: FunctionComponent<{ userId: string }> = props => {
    const { userId } = props;
    const addAccessSection = useDisclosure();

    const tenants = useTenants(),
        userTenants = useUserTenants(userId),
        createUserTenantRole = useCreateUserTenantRole();

    const form = useForm({
        defaultValues: {
            tenantId: "",
            role: TenantRole.Contributor,
        },
    });

    const expandAccessSectionButton: ButtonProps = {
        onClick: () => {
            form.reset();
            addAccessSection.onToggle();
        },
        children: addAccessSection.isOpen ? "Cancel" : "Add",
        leftIcon: <Icon as={IconShieldPlus} />,
    };

    const saveAccessButton: ButtonProps = {
        type: "submit",
        leftIcon: <Icon as={IconShieldPlus} />,
        children: "Add",
        colorScheme: "blue",
    };

    const tenantOptions =
        tenants.isLoading || userTenants.isLoading
            ? []
            : (tenants.data ?? [])
                  .filter(tenant => !(userTenants.data ?? []).find(m => m.tenantId === tenant.id))
                  .map(tenant => (
                      <option key={tenant.id} value={tenant.id}>
                          {tenant.name}
                      </option>
                  ));
    // @ts-ignore
    return (
        <>
            <Button {...expandAccessSectionButton} />
            {addAccessSection.isOpen && (
                <Card>
                    <form
                        onSubmit={form.handleSubmit(data => {
                            createUserTenantRole.mutate({
                                tenantId: data.tenantId,
                                userId: userId,
                                role: data.role,
                                expiresOn: undefined,
                            });
                        })}
                    >
                        <CardBody>
                            <VStack gap="4">
                                <FormControl>
                                    <FormLabel>Client</FormLabel>
                                    <Select placeholder="Select client" {...form.register("tenantId")}>
                                        {tenantOptions}
                                    </Select>
                                </FormControl>
                                <Controller
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormControl as="fieldset" {...form.register("role")}>
                                            <FormLabel as="legend">Role</FormLabel>
                                            {/*@ts-ignore*/}
                                            <RadioGroup value={field.value} onChange={field.onChange}>
                                                <Stack spacing={5} direction="row">
                                                    {TenantRoleRadioOptions}
                                                </Stack>
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                    name={"role"}
                                />
                            </VStack>
                        </CardBody>
                        <CardFooter justifyContent={"flex-end"}>
                            <Button {...saveAccessButton} />
                        </CardFooter>
                    </form>
                </Card>
            )}
        </>
    );
};

const TenantRoleRadioOptions = [
    <Radio key={TenantRole.Owner} value={TenantRole.Owner}>
        Owner
    </Radio>,
    <Radio key={TenantRole.Contributor} value={TenantRole.Contributor}>
        Contributor
    </Radio>,
    <Radio key={TenantRole.Reader} value={TenantRole.Reader}>
        Reader
    </Radio>,
];
