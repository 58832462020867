﻿import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../utils";
import { CandidateQualification, DesignatedZoneType, EntityCandidateQualification } from "../models/EntityCandidate";
import { AxiosError } from "axios";
import { useToast } from "@chakra-ui/react";

export type CreateEntityCandidateQualificationRequest = {
    comment: string | undefined;
    designatedZoneName: string | undefined;
    designatedZoneType: DesignatedZoneType | undefined;
    isManuallyAdded: boolean;
    candidateId: string | undefined;
    entityId: string | undefined;
    tenantId: string | undefined;
    qualification: CandidateQualification;
};
export function useCandidateQualificationCreate() {
    const axios = axiosInstance();
    const toast = useToast();

    return useMutation({
        mutationFn: (request: CreateEntityCandidateQualificationRequest) => {
            return axios
                .post<EntityCandidateQualification>(
                    `api/clients/${request.tenantId}/entities/${request.entityId}/candidates/${request.candidateId}/CandidateQualifications/create`,
                    {
                        ...request,
                    }
                )
                .then(response => response.data);
        },
        onSuccess: async (data, variables) => {
            toast({
                title: "Saved",
                description: "Candidate Qualification was saved successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        onError: error => {
            const axiosError = error as AxiosError;
            toast({
                title: "Unable to save Candidate Qualification details.",
                description: `[${axiosError.request.status ?? "ERR"}]: ${axiosError.message}`,
                status: "error",
                isClosable: true,
                duration: null,
            });
        },
    });
}
