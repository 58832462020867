﻿import { FunctionComponent, PropsWithChildren, useMemo, useState } from "react";
import { EntityTreeContext, EntityTreeContextState } from "./EntityTreeContext";
import { useDisclosure } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EntityResponse } from "../../../../api";

export type EntityTreeContextProviderProps = PropsWithChildren<{
    entities: EntityResponse[];
    isEditable: boolean;
    isLoading: boolean;
    isExpanded: boolean;
    onRefetch: () => Promise<void>;
    onExpandToggle: () => void;
}>;

const buildPathToSelected = (entities: EntityResponse[], entityId: string | undefined): EntityResponse[] => {
    for (const entity of entities) {
        if (entity.id === entityId) {
            return [entity];
        }
        if (entity.entityType === "Directory") {
            const pathToSelected = buildPathToSelected(entity.children, entityId);
            if (pathToSelected.length) {
                return [entity, ...pathToSelected];
            }
        }
    }
    return [];
};

export const EntityTreeContextProvider: FunctionComponent<EntityTreeContextProviderProps> = ({ children, ...entityTreeProps }) => {
    const { entities, isEditable, isLoading, isExpanded, onRefetch, onExpandToggle } = entityTreeProps;
    const [hovering, setHovering] = useState<string>();
    const editing = useDisclosure(),
        adding = useDisclosure();

    const location = useLocation();
    const navigate = useNavigate();
    const setSelected = (entityId: string | undefined) => {
        const basePath = location.pathname.split("/entities")[0];
        navigate(`${basePath}/entities/${entityId}`);
    };

    const { entityId } = useParams<{ entityId: string }>();
    const pathToSelected = useMemo(() => buildPathToSelected(entities, entityId), [entities, entityId]);
    const entity = pathToSelected[pathToSelected.length - 1];

    const onAdd = (parent?: string) => {
            setSelected(parent);
            editing.onClose();
            adding.onOpen();
        },
        onAddClose = () => {
            adding.onClose();
            onRefetch();
        },
        onEdit = (entity: string) => {
            setSelected(entity);
            editing.onOpen();
            adding.onClose();
        },
        onEditClose = () => {
            editing.onClose();
        };

    const contextValue: EntityTreeContextState = {
        entities,
        selectedEntity: entity,
        pathToSelected,
        isEditable,
        isLoading,
        hoveringEntityId: hovering,
        onMouseEnter: setHovering,
        onMouseLeave: () => setHovering(undefined),
        setSelectedEntityId: setSelected,
        onAdd,
        onAddClose,
        isAdding: adding.isOpen,
        onEdit,
        onEditClose,
        isEditing: editing.isOpen,
        isExpandAll: isExpanded,
        onExpandAllToggle: onExpandToggle,
        onRefetch,
    };

    return <EntityTreeContext.Provider value={contextValue}>{children}</EntityTreeContext.Provider>;
};
