﻿export enum DashboardArea {
    _name = "DashboardArea",
    read = "urn:am:tax:wotc:dashboard:read",
}
export enum EntitiesArea {
    _name = "EntitiesArea",
    create = "urn:am:tax:wotc:entity:create",
    read = "urn:am:tax:wotc:entity:read",
    update = "urn:am:tax:wotc:entity:update",
    delete = "urn:am:tax:wotc:entity:delete",
}
export enum CandidatesArea {
    _name = "CandidatesArea",
    edit = "urn:am:tax:wotc:candidate:edit",
    read_basic = "urn:am:tax:wotc:candidate:read:basic", // basic access includes: Address, county, birth, ssn, phone, email, days remaining, waiting for documents, and timeline
    read_full = "urn:am:tax:wotc:candidate:read:full",
    request_mgmt = "urn:am:tax:wotc:candidate:request:mgmt",
    questionnaire = "urn:am:tax:wotc:candidate:questionnaire",
}
export const allAreas = [DashboardArea, EntitiesArea, CandidatesArea] as const;

type AllAreasType = typeof allAreas;

export type SecurityArea = AllAreasType[number]; // this is equivalent to: typeof UserArea | typeof RequestArea | typeof DocumentArea;

export type PermissionsByAreaType<A extends SecurityArea> = A extends typeof DashboardArea
    ? DashboardArea
    : A extends typeof EntitiesArea
    ? EntitiesArea
    : A extends typeof CandidatesArea
    ? CandidatesArea
    : "PermissionsByAreaTypeError: if you see this then search for PermissionsByAreaTypeError in the code";

export type Permission = PermissionsByAreaType<AllAreasType[number]>; // this is equivalent to: UserArea | RequestArea | DocumentArea;

export type AppPermissions = {
    loading: boolean;
    isInternal: boolean;
    showArea: (area: SecurityArea) => boolean;
    has: (permission: Permission | null) => boolean;
    hasAny: (...permission: Permission[]) => boolean;
};

export type AreaPermissions<A extends SecurityArea> = {
    loading: boolean;
    isInternal: boolean;
    showArea: boolean;
    has: (permission: PermissionsByAreaType<A> | null) => boolean;
    hasAny: (...permission: PermissionsByAreaType<A>[]) => boolean;
};

export type PermissionsByRole = Record<string, Set<string> | undefined>;
